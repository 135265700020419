import { useCallback } from 'react'
import { toast, ToastOptions, cssTransition } from 'react-toastify'
import { styled } from 'styles/stitches.config'

const Container = styled('div', {
  color: '$grey900',
  fontSize: '$body2',
  lineHeight: '$body2',
})

const Title = styled('div', {
  fontWeight: '$bold',
  marginBottom: '$xt',
})

const commonOptions: ToastOptions = {
  containerId: 'global-toast',
  autoClose: 2000,
  position: 'top-center',
  /**
   * Custom animation. Have fun!
   * @see https://fkhadra.github.io/react-toastify/custom-animation
   */
  transition: cssTransition({
    enter: 'slide-down',
    exit: 'scale-out'
  }),
}

const useNotification = () => {
  const notifyError = useCallback((content: string, extraOptions: ToastOptions & { title?: string } = {}) => {
    return toast.error((
      <Container>
        {!!extraOptions.title && (
          <Title>{extraOptions.title}</Title>
        )}
        {content}
      </Container>
    ), {
      ...commonOptions,
      ...extraOptions
    })
  }, [])

  const notifySuccess = useCallback((content: string, extraOptions: ToastOptions & { title?: string } = {}) => {
    return toast.success((
      <Container>
        {!!extraOptions.title && (
          <Title>{extraOptions.title}</Title>
        )}
        {content}
      </Container>
    ), {
      ...commonOptions,
      ...extraOptions
    })
  }, [])

  return {
    notifyError,
    notifySuccess
  }
}

export default useNotification
