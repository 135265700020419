import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useBookmark from './useBookmark'

export const useSaveToBookMark = () => {
  const [, setBookmark] = useBookmark()
  const router = useRouter()

  useEffect(() => {
    if (router.query?.bookmark) {
      setBookmark({ url: router.query?.bookmark as string, name: router.query?.bookmark as string })
    }
  }, [router.query?.bookmark, setBookmark])
}
