import { SVGAttributes } from 'react'

export const RemoveCard = ({
  size = 24,
}: SVGAttributes<SVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clipPath="url(#clip0_9065_168806)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52549 3.47983V6.47983H21.7255V3.47983H2.52549ZM0.125488 3.35983C0.125488 2.10062 1.14628 1.07983 2.40549 1.07983H21.8455C23.1047 1.07983 24.1255 2.10062 24.1255 3.35983V6.59983C24.1255 7.85904 23.1047 8.87983 21.8455 8.87983H2.40549C1.14628 8.87983 0.125488 7.85904 0.125488 6.59983V3.35983Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48564 6.47998C4.14839 6.47998 4.68564 7.01724 4.68564 7.67998V19.56C4.68564 19.8146 4.78679 20.0588 4.96682 20.2388C5.14686 20.4188 5.39104 20.52 5.64564 20.52H18.6056C18.8603 20.52 19.1044 20.4188 19.2845 20.2388C19.4645 20.0588 19.5656 19.8146 19.5656 19.56V7.67998C19.5656 7.01724 20.1029 6.47998 20.7656 6.47998C21.4284 6.47998 21.9656 7.01724 21.9656 7.67998V19.56C21.9656 20.4511 21.6116 21.3057 20.9815 21.9359C20.3514 22.566 19.4968 22.92 18.6056 22.92H5.64564C4.75452 22.92 3.89989 22.566 3.26977 21.9359C2.63964 21.3057 2.28564 20.4511 2.28564 19.56V7.67998C2.28564 7.01724 2.8229 6.47998 3.48564 6.47998ZM8.57712 11.1515C9.04575 10.6828 9.80554 10.6828 10.2742 11.1515L12.1256 13.0029L13.9771 11.1515C14.4457 10.6828 15.2055 10.6828 15.6742 11.1515C16.1428 11.6201 16.1428 12.3799 15.6742 12.8485L13.8227 14.7L15.6742 16.5515C16.1428 17.0201 16.1428 17.7799 15.6742 18.2485C15.2055 18.7171 14.4457 18.7171 13.9771 18.2485L12.1256 16.397L10.2742 18.2485C9.80554 18.7171 9.04575 18.7171 8.57712 18.2485C8.10849 17.7799 8.10849 17.0201 8.57712 16.5515L10.4286 14.7L8.57712 12.8485C8.10849 12.3799 8.10849 11.6201 8.57712 11.1515Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9065_168806">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.125488)"
        />
      </clipPath>
    </defs>
  </svg>
)
