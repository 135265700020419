import { useEffect } from 'react'

import { animated, CSS } from '@react-spring/web'
import { styled } from 'styles/stitches.config'
import { useModal } from '../hooks/useModal'

const RootElement = styled('aside', {
  backfaceVisibility: 'hidden',
  bottom: 0,
  boxSizing: 'border-box',
  fontSize: 0,
  fontFamily: '$sansSerif',
  left: 0,
  margin: 0,
  outline: 'none',
  overflow: 'hidden',
  overflowY: 'auto',
  position: 'fixed',
  right: 0,
  textAlign: 'center',
  top: 0,
  '-webkit-tap-highlight-color': 'transparent',
  touchAction: 'manipulation',

  '&::before': {
    content: '',
    display: 'inline-block',
    fontSize: 0,
    height: '100%',
    verticalAlign: 'middle',
    width: 0,
  }
})

export type Props = {
  children?: React.ReactNode
  modalId: string
  transitionStyles?: CSS.Properties
}

export const Root = ({ children, modalId, transitionStyles }: Props) => {
  const { closeModal, isTopModal } = useModal()

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()

        if (isTopModal(modalId)) {
          closeModal(modalId)
        }
      }
    }

    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [closeModal, modalId, isTopModal])

  return (
    <animated.div style={transitionStyles}>
      <RootElement>
        {children}
      </RootElement>
    </animated.div>
  )
}
