import { styled } from 'styles/stitches.config'
import { Body2 } from './typography'

export const Input = styled('input', {
  backgroundColor: '$white',
  border: '1px solid $grey300',
  borderRadius: '$xxxs',
  color: '$grey1000',
  lineHeight: '$body1',
  padding: '0 $s',
  paddingBlock: '$s',
  textAlign: 'left',
  width: '100%',

  '&:disabled': {
    backgroundColor: '$grey200',
    color: '$grey700',

    '&::placeholder': {
      color: '$grey600',
    },
  },

  '&:focus': {
    borderColor: '$blue700',
  },

  '&::placeholder': {
    color: '$grey600',
  },

  variants: {
    alignRight: {
      true: {
        textAlign: 'right',
      },
    },
    error: {
      true: {
        borderColor: '$red600',
      },
    },
  },
})

export const ErrorMessage = styled(Body2, {
  color: '$red600',
  marginBlock: '$xxxs 0',
})
