import { SVGAttributes } from 'react'

export const RewardPoints = ({ size = 16 }: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.24967 14C11.1952 14 13.583 11.6122 13.583 8.66667C13.583 5.72115 11.1952 3.33333 8.24967 3.33333C5.30416 3.33333 2.91634 5.72115 2.91634 8.66667C2.91634 11.6122 5.30416 14 8.24967 14ZM8.24967 15.3333C11.9316 15.3333 14.9163 12.3486 14.9163 8.66667C14.9163 4.98477 11.9316 2 8.24967 2C4.56778 2 1.58301 4.98477 1.58301 8.66667C1.58301 12.3486 4.56778 15.3333 8.24967 15.3333Z" fill="#B9B9B9" />
    <g clipPath="url(#clip0_3565_11505)">
      <path d="M6.34508 12.5716H9.20222C9.99091 12.5716 10.6308 11.9317 10.6308 11.143H11.107C12.1576 11.143 13.0117 10.2889 13.0117 9.23826C13.0117 8.18766 12.1576 7.3335 11.107 7.3335H5.27365C5.07573 7.3335 4.9165 7.49272 4.9165 7.69064V11.143C4.9165 11.9317 5.55638 12.5716 6.34508 12.5716ZM11.107 8.28588C11.6323 8.28588 12.0594 8.71296 12.0594 9.23826C12.0594 9.76356 11.6323 10.1906 11.107 10.1906H10.6308V8.28588H11.107Z" fill="#B9B9B9" />
      <rect x="5.58301" y="4.6665" width="0.666667" height="2" rx="0.333333" fill="#B9B9B9" />
      <rect x="7.58301" y="4.6665" width="0.666667" height="2" rx="0.333333" fill="#B9B9B9" />
      <rect x="9.58301" y="4.6665" width="0.666666" height="2" rx="0.333333" fill="#B9B9B9" />
    </g>
    <defs>
      <clipPath id="clip0_3565_11505">
        <rect width="9.33333" height="9.33333" fill="white" transform="translate(4.24976 4)" />
      </clipPath>
    </defs>
  </svg>

)
