import { FC, SVGAttributes } from 'react'
import { styled } from 'styles/stitches.config'

export type TagProps = {
  variant: 'success' | 'info' | 'warning' | 'error' | 'dark'
  size: 'small' | 'large'
  label: string
  icon?: FC<SVGAttributes<SVGSVGElement> & { size?: number | undefined }>
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$xxs',
  fontWeight: '$bold',
  lineHeight: '1.6',
  padding: '$xt $xs',
  variants: {
    variant: {
      success: {
        backgroundColor: '$grey100',
        color: '$green800',
      },
      info: {
        backgroundColor: '$blue100',
        color: '$blue700',
      },
      warning: {
        backgroundColor: '$yellow100',
        color: '$yellow700',
      },
      error: {
        backgroundColor: '$red100',
        color: '$red600',
      },
      dark: {
        backgroundColor: '$grey100',
        color: '$grey1000',
      },
    },
    size: {
      small: {
        fontSize: '$tagLabel',
        gap: '$xxxs'
      },
      large: {
        fontSize: '$body3',
        gap: '$xxs'
      },
    },
  },
  },
)

const Tag = ({ variant, size, label, icon: Icon }: TagProps) => (
  <Container variant={variant} size={size}>
    {Icon && <Icon size={size === 'small' ? 10 : 12} />}
    {label}
  </Container>
)

export default Tag
