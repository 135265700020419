import { SVGAttributes } from 'react'

export const Star = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 0, ...props
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg aria-label="star-filled-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="currentColor" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} {...props}>
    <path d="M6.26364 22.8678C6.55593 23.0041 6.87429 23.0753 7.19677 23.0766C7.57486 23.0762 7.94655 22.9791 8.27659 22.7947L12.6286 20.3813L16.9752 22.7947C17.3406 22.9971 17.7558 23.0925 18.173 23.0699C18.5902 23.0474 18.9926 22.9077 19.3341 22.667C19.6756 22.4262 19.9424 22.0942 20.1039 21.7088C20.2654 21.3234 20.315 20.9004 20.2472 20.4881L19.3941 15.1964L23.0651 11.4169C23.35 11.1235 23.5488 10.7574 23.6397 10.3587C23.7305 9.95993 23.71 9.54387 23.5802 9.15601C23.4505 8.76816 23.2166 8.42345 22.9042 8.1596C22.5917 7.89576 22.2127 7.72293 21.8085 7.66003L16.8245 6.88946L14.6388 2.21122C14.4551 1.83372 14.1689 1.51548 13.8129 1.29284C13.457 1.07021 13.0456 0.952148 12.6258 0.952148C12.2059 0.952148 11.7945 1.07021 11.4386 1.29284C11.0826 1.51548 10.7965 1.83372 10.6128 2.21122L8.41974 6.89616L3.44287 7.66003C3.03876 7.72293 2.65974 7.89576 2.34726 8.1596C2.03478 8.42345 1.80089 8.76816 1.67117 9.15601C1.54145 9.54387 1.5209 9.95993 1.61176 10.3587C1.70263 10.7574 1.90141 11.1235 2.18636 11.4169L5.86106 15.2116L5.00481 20.4873C4.95287 20.8056 4.97059 21.1314 5.05672 21.4421C5.14285 21.7529 5.29536 22.0413 5.50372 22.2874C5.71208 22.5336 5.97135 22.7316 6.26364 22.8678Z" fill="currentColor" />
  </svg>
)

export const starSvgString = encodeURIComponent(
  '<svg aria-label="star-filled-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="#ffffff" stroke="#ffffff"><path d="M6.26364 22.8678C6.55593 23.0041 6.87429 23.0753 7.19677 23.0766C7.57486 23.0762 7.94655 22.9791 8.27659 22.7947L12.6286 20.3813L16.9752 22.7947C17.3406 22.9971 17.7558 23.0925 18.173 23.0699C18.5902 23.0474 18.9926 22.9077 19.3341 22.667C19.6756 22.4262 19.9424 22.0942 20.1039 21.7088C20.2654 21.3234 20.315 20.9004 20.2472 20.4881L19.3941 15.1964L23.0651 11.4169C23.35 11.1235 23.5488 10.7574 23.6397 10.3587C23.7305 9.95993 23.71 9.54387 23.5802 9.15601C23.4505 8.76816 23.2166 8.42345 22.9042 8.1596C22.5917 7.89576 22.2127 7.72293 21.8085 7.66003L16.8245 6.88946L14.6388 2.21122C14.4551 1.83372 14.1689 1.51548 13.8129 1.29284C13.457 1.07021 13.0456 0.952148 12.6258 0.952148C12.2059 0.952148 11.7945 1.07021 11.4386 1.29284C11.0826 1.51548 10.7965 1.83372 10.6128 2.21122L8.41974 6.89616L3.44287 7.66003C3.03876 7.72293 2.65974 7.89576 2.34726 8.1596C2.03478 8.42345 1.80089 8.76816 1.67117 9.15601C1.54145 9.54387 1.5209 9.95993 1.61176 10.3587C1.70263 10.7574 1.90141 11.1235 2.18636 11.4169L5.86106 15.2116L5.00481 20.4873C4.95287 20.8056 4.97059 21.1314 5.05672 21.4421C5.14285 21.7529 5.29536 22.0413 5.50372 22.2874C5.71208 22.5336 5.97135 22.7316 6.26364 22.8678Z" fill="#ffffff" /></svg>'
)
