import { uniqueId } from 'lodash'
import { SVGAttributes, useState } from 'react'

export const UploadHistory = ({
  size = 20
}: SVGAttributes<SVGSVGElement> & { size?: number }) => {
  const [id] = useState(uniqueId('clip0_7048_2833_'))

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5432 6.91601C3.80321 3.46501 7.11621 1 11.0002 1C15.9672 1 20.0002 5.03301 20.0002 10C20.0002 14.967 15.9672 19 11.0002 19C7.67221 19 4.76322 17.19 3.20621 14.501C2.93021 14.023 3.0932 13.411 3.5712 13.134C4.04821 12.858 4.66022 13.021 4.93722 13.499C6.14822 15.591 8.41122 17 11.0002 17C14.8632 17 18.0002 13.863 18.0002 10C18.0002 6.13601 14.8632 3 11.0002 3C7.78322 3 5.07021 5.17401 4.25221 8.13202L6.00422 7.13202C6.48323 6.85802 7.09422 7.02401 7.36823 7.50401C7.64223 7.98301 7.47522 8.59402 6.99622 8.86802L3.49621 10.868C3.25921 11.004 2.97722 11.036 2.71522 10.958C2.45321 10.88 2.2352 10.699 2.1102 10.456L0.110199 6.55301C-0.141802 6.06201 0.0532044 5.45901 0.544205 5.20701C1.03521 4.95501 1.6382 5.15001 1.8902 5.64101L2.5432 6.91601Z" fill="#2E2E2E" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1457 6.64089C11.6059 6.64089 11.979 7.01399 11.979 7.47422V9.55756H14.0624C14.5226 9.55756 14.8957 9.93065 14.8957 10.3909C14.8957 10.8511 14.5226 11.2242 14.0624 11.2242H11.979V13.3076C11.979 13.7678 11.6059 14.1409 11.1457 14.1409C10.6855 14.1409 10.3124 13.7678 10.3124 13.3076V11.2242H8.22903C7.76879 11.2242 7.39569 10.8511 7.39569 10.3909C7.39569 9.93065 7.76879 9.55756 8.22903 9.55756H10.3124V7.47422C10.3124 7.01399 10.6855 6.64089 11.1457 6.64089Z" fill="#2E2E2E" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
