import { SVGAttributes } from 'react'

export const Discover = ({
  size=24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1255 1.03101C9.9499 1.03101 7.82316 1.67614 6.01422 2.88484C4.20528 4.09354 2.79538 5.8115 1.96282 7.82149C1.13025 9.83148 0.912418 12.0432 1.33686 14.177C1.76129 16.3108 2.80894 18.2708 4.34732 19.8092C5.8857 21.3476 7.84571 22.3952 9.9795 22.8196C12.1133 23.2441 14.325 23.0262 16.335 22.1937C18.345 21.3611 20.063 19.9512 21.2717 18.1423C22.4804 16.3333 23.1255 14.2066 23.1255 12.031C23.1255 9.11362 21.9666 6.31573 19.9037 4.25283C17.8408 2.18993 15.0429 1.03101 12.1255 1.03101ZM12.1255 21.031C10.3455 21.031 8.6054 20.5032 7.12536 19.5142C5.64532 18.5253 4.49177 17.1197 3.81058 15.4752C3.12939 13.8306 2.95116 12.021 3.29843 10.2752C3.64569 8.52936 4.50286 6.92572 5.76153 5.66704C7.0202 4.40837 8.62385 3.55121 10.3697 3.20394C12.1155 2.85667 13.9251 3.0349 15.5696 3.71609C17.2142 4.39728 18.6198 5.55083 19.6087 7.03087C20.5977 8.51092 21.1255 10.251 21.1255 12.031C21.1255 14.418 20.1773 16.7071 18.4895 18.395C16.8016 20.0828 14.5124 21.031 12.1255 21.031Z" fill="currentColor"/>
    <path d="M14.4955 7.2011L10.1755 9.2011C9.77061 9.39065 9.44505 9.71622 9.2555 10.1211L7.2555 14.4411C7.1368 14.7256 7.09028 15.0351 7.12009 15.3419C7.14989 15.6487 7.25509 15.9434 7.42632 16.1998C7.59755 16.4561 7.8295 16.6662 8.10153 16.8112C8.37355 16.9563 8.67722 17.0318 8.9855 17.0311C9.25119 17.0286 9.51345 16.9707 9.7555 16.8611L14.0755 14.8611C14.4804 14.6716 14.8059 14.346 14.9955 13.9411L16.9955 9.6211C17.1507 9.27683 17.1974 8.89349 17.1294 8.52204C17.0614 8.15059 16.882 7.80863 16.615 7.54161C16.348 7.27459 16.006 7.09517 15.6346 7.0272C15.2631 6.95924 14.8798 7.00594 14.5355 7.1611L14.4955 7.2011ZM13.2455 13.0811L9.2455 14.8911L11.0055 11.0311L15.0055 9.2211L13.2455 13.0811Z" fill="currentColor"/>
  </svg>
)
