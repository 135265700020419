import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { getBuildId } from 'utils/build'

export const useReloadOnNewBuild = (currentBuildId: string) => {
  const router = useRouter()
  const [buildId] = useState(currentBuildId)
  const firstRenderRef = useRef(true)

  useEffect(() => {
    // useEffect will run once when the component is first rendered
    // since we already have the latest build number from the server-side when the App is first rendered, we don't want to trigger the fetch again
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }

    getBuildId().then((newBuildId) => {
      if (newBuildId != buildId) {
        router.reload()
      }
    })
    // the use effect will only run when there's a change in route
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])
}
