import Head from 'next/head'

type Props = {
  description?: string
  imgHeight?: string | number
  imgSrc?: string
  imgWidth?: string | number
  pageTitle: string
  siteTitle?: string
  url: string
}

const Metadata = ({
  description,
  imgHeight,
  imgSrc,
  imgWidth,
  pageTitle,
  siteTitle = 'Joe Coffee',
  url,
}: Props) => {
  return (
    <Head>
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:site_name" property="og:site_name" content={siteTitle} />
      <meta key="og:title" property="og:title" content={pageTitle} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:image" property="og:image" content={imgSrc} />
      <meta key="og:image:width" property="og:image:width" content={imgWidth?.toString()} />
      <meta key="og:image:height" property="og:image:height" content={imgHeight?.toString()} />

      <meta key="twitter:url" name="twitter:url" content={url} />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" name="twitter:site" content={siteTitle} />
      <meta key="twitter:title" name="twitter:title" content={pageTitle} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta key="twitter:image" name="twitter:image" content={imgSrc} />
      <meta key="twitter:image:src" name="twitter:image:src" content={imgSrc} />
      <meta key="twitter:creator" name="twitter:creator" content="" />

      <link key="image_src" rel="image_src" href={imgSrc} />
      <meta key="description" name="description" content={description} />
      <title key="title">{pageTitle}{siteTitle && ` - ${siteTitle}`}</title>
    </Head>
  )
}

export default Metadata
