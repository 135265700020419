import { styled } from 'styles/stitches.config'

export const Span = styled('span', {
  display: 'inline',
  lineHeight: '1em',

  variants: {
    tag: {
      true: {
        alignItems: 'center',
        backgroundColor: '$grey100',
        borderRadius: '$xxxs',
        color: '$grey1000',
        display: 'inline-flex',
        fontSize: '$tagLabel',
        lineHeight: '$tagLabel',
        margin: '0 $xxxs',
        padding: '$xt $xs',
        verticalAlign: 'middle',
      },
    },

    color: {
      red: {
        backgroundColor: '$red200',
        color: '$red700',
      },
      green: {
        color: '$green800',
      },
      blue: {
        backgroundColor: '$blue100',
        color: '$blue700',
      },
      yellow: {
        backgroundColor: '$yellow100',
        color: '$yellow700',
      },
    },
  },
})

export const Spinner = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  inset: 0,
})
