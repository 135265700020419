import type * as Stitches from '@stitches/react'
import { styled } from 'styles/stitches.config'
import * as Icon from 'components/DesignSystemIcons'
import React from 'react'

export const Header = styled('header', {
  border: '0 solid transparent',
  borderWidth: '0 1px',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 $s',
  padding: '0',

  variants: {
    isString: {
      true: {
        fontSize: '$heading3',
        fontWeight: '$bold',
        lineHeight: '$heading3',
      },
    },
  }
})

export const Main = styled('div', {
  display: 'flex',
  padding: '0 $s',
})

export const Border = styled('div', {
  backgroundColor: '$white',
  border: '1px solid $grey200',
  borderRadius: '$xs',
  display: 'flex',
  flex: 1,
  marginBottom: '$s',
  width: '100%'
})

export const Children = styled('div', {
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  width: '100%',

  '&:empty': {
    padding: '$s',
  },
})

export const Chevron = styled('div', {
  display: 'none',
  fontSize: 0,
  height: '100%',
  paddingLeft: '$s',
})

export const Article = styled('article', {
  color: '$grey1000',
  margin: '$s auto',
  width: '100%',

  variants: {
    banner: {
      true: {
        marginBottom: '$xxl',

        [`& ${Main}`]: {
          backgroundColor: '$white',
          margin: 0,
          paddingTop: '$s 0 0 $s',
        },
        [`& ${Border}`]: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          margin: 0,
          padding: '$s 0 $s $s',
        },
      },
    },
    chevron: {
      true: {
        [`& ${Chevron}`]: {
          alignItems: 'center',
          color: '$grey500',
          display: 'flex',
          flex: '0 0 auto',
        },
      },
    },
    blueChevron: {
      true: {
        [`& ${Chevron}`]: {
          color: '$blue900',
        },
      }
    },
    redBanner: {
      true: {
        marginBottom: '$xxl',

        [`& ${Main}`]: {
          backgroundColor: '$red200',
          color: '$red800',
          margin: 0,
          paddingTop: '$s 0 0 $s',
        },
        [`& ${Border}`]: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          margin: 0,
          padding: '$s 0 $s $s',
        },
      },
    },
    blueBanner: {
      true: {
        backgroundColor: '$blue100',
        color: '$blue900',
      }
    },
    noMargin: {
      true: {
        [`& ${Border}`]: {
          margin: 0
        },
        [`& ${Chevron}`]: {
          marginRight: '$s'
        },
      }
    },
    unstyled: {
      true: {
        [`& ${Main}`]: {
          margin: 0,
          padding: 0,
        },
        [`& ${Border}`]: {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 'none',
          padding: 0,
        },
      },
    },
  },
})

export const Section = styled('div', {
  width: '100%',
})

type ContainerProps = {
  children?: React.ReactNode
  css?: Stitches.CSS
  label?: React.ReactNode | string
}
  & React.HTMLAttributes<HTMLDivElement>
  & Stitches.VariantProps<typeof Article>

export const Container = ({ children, css, label, ...props }: ContainerProps) => (
  <Article css={css} {...props}>
    {label && (
      <Header isString={typeof label === 'string'}>{label}</Header>
    )}
    {children}
  </Article>
)

type ContentProps = {
  children?: React.ReactNode
}

export const Content = ({ children }: ContentProps) => (
  <Main>
    <Border>
      <Children>{children}</Children>
      <Chevron>
        <Icon.ChevronRight />
      </Chevron>
    </Border>
  </Main>
)

export const Composed = ({ children, ...props }: ContainerProps) => (
  <Container {...props}>
    <Content>
      {children}
    </Content>
  </Container>
)
