import { createContext } from 'react'

export type ACHFailedContextValues = {
  openModal: () => void
  closeModal: () => void
}

export const ACHFailedContext = createContext<ACHFailedContextValues>({
  openModal: () => null,
  closeModal: () => null
})
