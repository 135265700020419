import { SVGAttributes } from 'react'

export const Reload = ({ size = 25 }: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5780_6981)">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.8232 8.10133C19.3112 3.96017 15.3356 1.0022 10.6749 1.0022C4.71455 1.0022 -0.125 5.84175 -0.125 11.8021C-0.125 17.7624 4.71455 22.602 10.6749 22.602C14.6685 22.602 18.1592 20.43 20.0276 17.2032C20.3588 16.6296 20.1632 15.8953 19.5896 15.5629C19.0172 15.2317 18.2828 15.4273 17.9504 16.0008C16.4972 18.5112 13.7817 20.202 10.6749 20.202C6.03934 20.202 2.27497 16.4376 2.27497 11.8021C2.27497 7.16534 6.03934 3.40217 10.6749 3.40217C14.5353 3.40217 17.7908 6.01095 18.7724 9.56052L16.67 8.36053C16.0952 8.03173 15.362 8.23092 15.0332 8.80692C14.7044 9.38171 14.9048 10.1149 15.4796 10.4437L19.6796 12.8437C19.964 13.0069 20.3024 13.0453 20.6168 12.9517C20.9312 12.8581 21.1928 12.6409 21.3428 12.3493L23.7428 7.66573C24.0452 7.07654 23.8112 6.35294 23.222 6.05055C22.6328 5.74815 21.9092 5.98215 21.6068 6.57134L20.8232 8.10133Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_5780_6981">
        <rect width="24" height="24" fill="white" transform="translate(0 0.00219727)" />
      </clipPath>
    </defs>
  </svg>
)
