import { SVGAttributes } from 'react'

export const ChevronDown = ({
  size = 24,
  ...props
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.03 6.45C20.63 5.85 21.6 5.85 22.2 6.45C22.8 7.05 22.8 8.02 22.2 8.62L13.41 17.37C12.81 17.97 11.84 17.97 11.24 17.37L2.45 8.62C1.85 8.02 1.85 7.05 2.45 6.45C3.05 5.85 4.02 5.85 4.63 6.45L12.33 14.13L20.03 6.45Z"
      fill="currentColor"
    />
  </svg>
)
