import { PaymentRequest } from '@stripe/stripe-js'
import PaymentMethodSelector from 'components/PaymentMethodSelector'
import * as Widget from 'components/Widget'
import { styled } from 'styles/stitches.config'
import { UserPaymentMethod } from 'types/user'

const Header = styled('span', {
  fontSize: '$larger',
  fontWeight: '$bold',
})

const Wrapper = styled('div', {
  flex: 1,
  margin: '$m $s 0',
})

interface Props {
  paymentMethods: UserPaymentMethod[]
  defaultPaymentMethodId?: string | null
  nativePayOnBrowser?: string
  setPaymentRequest?: (paymentRequest: PaymentRequest) => void
  onAddingNewPaymentMethod?: () => void
  onlyCreditCard?: boolean
}

const PaymentMethodWidget = ({
  paymentMethods,
  defaultPaymentMethodId,
  setPaymentRequest,
  onAddingNewPaymentMethod,
  onlyCreditCard,
}: Props) => {
  return (
    <Widget.Composed label={<Header>Payment Method</Header>} unstyled>
      <Wrapper>
        <PaymentMethodSelector
          options={{
            required: {
              value: true,
              message: 'Payment method is required.',
            },
          }}
          paymentMethods={paymentMethods}
          defaultPaymentMethodId={defaultPaymentMethodId}
          setPaymentRequest={setPaymentRequest}
          onAddingNewPaymentMethod={onAddingNewPaymentMethod}
          onlyCreditCard={onlyCreditCard}
        />
      </Wrapper>
    </Widget.Composed>
  )
}

export default PaymentMethodWidget
