import { useState } from 'react'
import { getLocalStorage, setLocalStorage } from 'utils/browserStorage'

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      let item: string | null = null

      // Get from local storage by key
      /* istanbul ignore next */
      if (typeof window !== 'undefined') {
        item = getLocalStorage(key)
      }

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore =
      value instanceof Function ? value(storedValue) : value

    // Save state
    setStoredValue(valueToStore)

    // Save to local storage
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      setLocalStorage(key, JSON.stringify(valueToStore))
    }
  }

  return [storedValue, setValue] as const
}
