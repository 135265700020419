import { SVGAttributes } from 'react'

export const ActiveOrders = ({ size = 24, hasActiveOrders }: SVGAttributes<SVGSVGElement> & { size?: number, hasActiveOrders: boolean }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
    {
      hasActiveOrders ? (
        <>
          <g clipPath="url(#clip0_11642_6620)">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V11C4 11.5523 3.55228 12 3 12C2.44772 12 2 11.5523 2 11V3C2 2.20435 2.31607 1.44129 2.87868 0.87868C3.44129 0.31607 4.20435 0 5 0H17C17.7957 0 18.5587 0.316071 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V16C20 16.66 19.8102 17.6278 19.2115 18.4593C18.5761 19.3418 17.5259 20 16 20H4C3.33998 20 2.3722 19.8102 1.5407 19.2115C0.658199 18.5761 0 17.5259 0 16V14C0 13.4477 0.447715 13 1 13H13C13.5523 13 14 13.4477 14 14V16C14 16.8741 14.3418 17.3239 14.7093 17.5885C15.1278 17.8898 15.66 18 16 18C16.8741 18 17.3239 17.6582 17.5885 17.2907C17.8898 16.8722 18 16.34 18 16V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2H5ZM12.4712 18C12.1773 17.4558 12 16.792 12 16V15H2V16C2 16.8741 2.3418 17.3239 2.7093 17.5885C3.1278 17.8898 3.66002 18 4 18H12.4712Z" fill="#2E2E2E" />
            <circle cx="15.8745" cy="4" r="5" fill="#E21410" stroke="#EEEEEE" strokeWidth="2" />
          </g>
          <defs>
            <clipPath id="clip0_11642_6620">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_11642_2529)">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V11C4 11.5523 3.55228 12 3 12C2.44772 12 2 11.5523 2 11V3C2 2.20435 2.31607 1.44129 2.87868 0.87868C3.44129 0.31607 4.20435 0 5 0H17C17.7957 0 18.5587 0.31607 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V16C20 16.66 19.8102 17.6278 19.2115 18.4593C18.5761 19.3418 17.5259 20 16 20H4C3.33998 20 2.3722 19.8102 1.5407 19.2115C0.658199 18.5761 0 17.5259 0 16V14C0 13.4477 0.447715 13 1 13H13C13.5523 13 14 13.4477 14 14V16C14 16.8741 14.3418 17.3239 14.7093 17.5885C15.1278 17.8898 15.66 18 16 18C16.8741 18 17.3239 17.6582 17.5885 17.2907C17.8898 16.8722 18 16.34 18 16V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2H5ZM12.4712 18C12.1773 17.4558 12 16.792 12 16V15H2V16C2 16.8741 2.3418 17.3239 2.7093 17.5885C3.1278 17.8898 3.66002 18 4 18H12.4712Z" fill="#2E2E2E" />
          </g>
          <defs>
            <clipPath id="clip0_11642_2529">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    }
  </svg>
)
