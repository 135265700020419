import { useCallback, useRef, useState } from 'react'
import { format } from 'date-fns'
import { CSS } from '@stitches/react'

import useNotification from 'hooks/useNotification'

import { BottomModal } from 'components/Modals'
import { Modal, useModal } from 'components/ModalKit'
import RateOrderMenu from 'components/OrderFeedback/OrderFeedbackForm'
import { useLocalStorage } from './useLocalStorage'
import { LocalStorageKeys } from 'config/localStorageKeys'

const modalStylings: CSS = {
  alignItems: 'flex-start',
  flexDirection: 'column',
  textAlign: 'left',
  paddingInline: '0',
  paddingTop: '$l',
  marginInline: '$l',

  '& > h3': {
    marginBottom: '$xxxl'
  },

  '& > p': {
    fontSize: '$body2',
    lineHeight: '$tagLabel',
    color: '$grey800',
    marginBottom: '$xxxs'
  },

  '& > div': {
    fontWeight: '$bold',
    fontSize: '$body1',
    lineHeight: '$heading2',
    color: '$grey1000',
  }
}

type RateOrderProps = {
  rated: number | null
  storeName: string
  orderId: string
  ts: string
  shouldClearRateOrderReminder?: boolean
}

const useRateOrder = ({ rated, storeName, ts, orderId, shouldClearRateOrderReminder }: RateOrderProps) => {
  const { notifySuccess } = useNotification()
  const [rating, setRating] = useState(rated || 0)
  const [isRated, setIsRated] = useState(!!rated)
  const [, setLastOrder] = useLocalStorage(LocalStorageKeys.LAST_ORDER, null)

  const { openModal, closeModal } = useModal()
  const modalRef = useRef<string | null>(null)

  const onConfirm = useCallback((value: number) => {
    setRating(value)
    setIsRated(true)
    notifySuccess('Feedback submitted')
    setLastOrder(null)

    /* istanbul ignore next */
    if (modalRef.current) {
      closeModal(modalRef.current)
      modalRef.current = null
    }
  }, [notifySuccess, setLastOrder, closeModal])

  // TO DO: Fix this test
  const closeBottomSheet = useCallback(async () => {
    // Close bottomsheet
    setRating(0)

    modalRef.current = null

    if (shouldClearRateOrderReminder) {
      setLastOrder(null)
    }
  }, [setLastOrder, shouldClearRateOrderReminder])


  const openRateOrderModal = (value: number) => {
    if (!isRated && rating >= 0 && !modalRef.current) {
      modalRef.current = openModal(
        <BottomModal onClose={closeBottomSheet}>
          <Modal.Header css={modalStylings}>
            <h3>Rate Your Order</h3>
            <p>{format(new Date(ts), 'EEEE LLL dd, hh:mm aa')}</p>
            <div>{storeName}</div>
          </Modal.Header>
          <Modal.Main>
            <RateOrderMenu orderId={orderId} onConfirm={onConfirm} defaultRating={value} />
          </Modal.Main>
        </BottomModal>
      )

      setRating(value)
      return
    }
  }

  return {
    openRateOrderModal,
    rating
  }
}

export default useRateOrder
