import { uniqueId } from 'lodash'
import { SVGAttributes, useState } from 'react'

export const RatingStarHalf = ({
  size = 18,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => {
  const [id] = useState(uniqueId('mask0_6099_63805_'))

  return (
    <svg height={size} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={id} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="15" height="16">
        <path d="M8.42724 0.590916C8.47772 0.418278 8.72228 0.418278 8.77277 0.590916L10.3807 6.08949C10.4031 6.16623 10.4735 6.21897 10.5535 6.21897H15.794C15.9638 6.21897 16.0392 6.4324 15.9071 6.53903L11.6359 9.98704C11.5793 10.0327 11.5558 10.1079 11.5762 10.1776L13.1998 15.7298C13.2493 15.899 13.0511 16.0311 12.914 15.9203L8.71307 12.5291C8.64709 12.4758 8.55291 12.4758 8.48694 12.5291L4.28605 15.9203C4.14885 16.0311 3.95073 15.899 4.00022 15.7298L5.62382 10.1776C5.64422 10.1079 5.62067 10.0327 5.56412 9.98704L1.29291 6.53903C1.16083 6.4324 1.23623 6.21897 1.40598 6.21897H6.64654C6.72649 6.21897 6.79686 6.16623 6.8193 6.08949L8.42724 0.590916Z" fill="#C4C4C4" />
      </mask>
      <g mask={`url(#${id})`}>
        <rect y="1" width="11" height="18" fill="#F1A33B" />
      </g>
      <path d="M7.25121 6.21567L8.6 1.60329L9.94879 6.21567C10.0273 6.48426 10.2736 6.66885 10.5535 6.66885H15.0297L11.3532 9.63677C11.1553 9.79655 11.0729 10.0597 11.1443 10.3038L12.526 15.0287L8.99573 12.1788L8.71307 12.529L8.99572 12.1788C8.76483 11.9924 8.43517 11.9924 8.20427 12.1788L4.67405 15.0287L6.05573 10.3038C6.12712 10.0597 6.0447 9.79655 5.84678 9.63677L2.17028 6.66885H6.64654C6.92637 6.66885 7.17267 6.48426 7.25121 6.21567L6.8193 6.08937L7.25121 6.21567Z" stroke="#F1A33B" strokeWidth="0.9" />
    </svg>
  )
}
