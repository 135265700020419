import { useStripe } from '@stripe/react-stripe-js'
import { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js'
import { useCallback, useEffect, useState } from 'react'
import useDevice from './useDevice'

interface Props {
  setPaymentRequest?: (paymentRequest: PaymentRequest) => void
}

export const useSetPaymentRequest = ({
  setPaymentRequest
}: Props) => {
  const stripe = useStripe()
  const device = useDevice()

  const [applePayAvailableWeb, setApplePayWebAvailable] = useState(false)
  const [applePayAvailableNative, setApplePayNativeAvailable] = useState(false)
  const [googlePayAvailableWeb, setGooglePayAvailableWeb] = useState(false)
  const [googlePayAvailableNative, setGooglePayAvailableNative] = useState(false)

  const onCanMakePayment = useCallback((res: CanMakePaymentResult | null) => {
    if (res?.googlePay) {
      setGooglePayAvailableWeb(true)
    }

    if (res?.applePay) {
      setApplePayWebAvailable(true)
    }
  }, [])

  useEffect(() => {
    if (device.isBrowser && stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          amount: 0,
          label: 'Add payment',
        },
      })

      pr.canMakePayment().then((res) => {
        onCanMakePayment(res)

        if (setPaymentRequest) {
          setPaymentRequest(pr)
        }
      })

      return
    }

    if (device.isIOS) {
      setApplePayNativeAvailable(true)
      return
    }

    if (device.isAndroid) {
      setGooglePayAvailableNative(true)
    }
  }, [device.isAndroid, device.isBrowser, device.isIOS, setPaymentRequest, stripe, onCanMakePayment])

  return {
    applePayAvailableWeb,
    applePayAvailableNative,
    googlePayAvailableWeb,
    googlePayAvailableNative,
  }
}
