import { styled } from 'styles/stitches.config'

const Body = styled('span', {
  lineHeight: '1.6',
  display: 'inline-block',
  fontWeight: '$normal',

  variants: {
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
  },

  defaultVariants: {
    bold: false,
  },
})

export const Body1 = styled(Body, {
  fontSize: '$body1',
})

export const Body2 = styled(Body, {
  fontSize: '$body2',
})

export const Body3 = styled(Body, {
  fontSize: '$body3',
})

export const Label = styled('label', Body1, {
  display: 'inline-block',
  fontSize: '$heading5',
  fontWeight: '$bold',
  lineHeight: '$tagLabel',
  marginBottom: '$s',
  letterSpacing: '0.05em',
  color: '$grey800',

  variants: {
    hidden: {
      true: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        borderWidth: 0,
      },
    },
  },
})
