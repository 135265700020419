import { atom, RecoilState } from 'recoil'
import type { StoreByDistance } from 'types/api/store'
import { Modifier, PromoCode } from 'types/api/cart'
import { CartData } from 'types/api/cart'
import { CompanyGiftCardPurchaseData, JoeGiftCardPurchaseData } from 'types/api/giftcard'

export const cartInitialState: CartData = {
  items: [],
  pickup: '',
  promo: {
    code: '',
    type: 'fixed',
    value: 0,
  },
  schedule: '',
  storeAddress: '',
  storeId: '',
  storeName: '',
  storePhoto: '',
  taxRate: 0,
  tipAmount: 0,
}

export const bookmarkState = atom({
  key: 'bookmarkState',
  default: null,
})

export const inboxNotificationsState = atom({
  key: 'inboxNotificationsState',
  default: 0,
})

export const selectedCardAndStoreState = atom({
  key: 'selectedCardAndStoreState',
  default: {
    cardId: null as null | string,
    storeId: null as null | string,
    companyId: null as null | string,
  },
})

export const cartState = atom({
  key: 'cartState',
  default: cartInitialState,
})

export const promoCodeState: RecoilState<null | PromoCode> = atom({
  key: 'promoCodeState',
  default: null as null | PromoCode,
})

export const storeItemOptionsState = atom({
  key: 'storeItemOptionsState',
  default: {
    quantity: 1,
    all: [] as Modifier[],
  },
})

export const storeItemRemovedOptionsState = atom({
  key: 'storeItemRemovedOptionsState',
  default: [] as Modifier[],
})

export const storesState = atom({
  key: 'storesState',
  default: [] as StoreByDistance[],
})

export const giftCardPurchaseState = atom<JoeGiftCardPurchaseData | null>({
  key: 'giftCardPurchaseState',
  default: null,
})

export const rewardIndicatorState = atom({
  key: 'rewardIndicatorState',
  default: false
})

export const companyGiftCardPurchaseState = atom<null | CompanyGiftCardPurchaseData>({
  key: 'companyGiftCardPurchaseState',
  default: null
})
