import { SVGAttributes } from 'react'

export const Cross = ({
  size = 24,
  ...props
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M20.4737 6.62389C21.2776 5.81999 21.2766 4.51574 20.4759 3.71505L20.4414 3.68059C19.6389 2.87801 18.3363 2.87913 17.5326 3.68277L12.4031 8.81224C12.2497 8.96569 12.0005 8.96487 11.8478 8.81224L6.71838 3.68277C5.91447 2.87887 4.61022 2.87992 3.80953 3.68059L3.77507 3.71505C2.97249 4.51763 2.97361 5.8202 3.77726 6.62389L8.90672 11.7534C9.06018 11.9068 9.05936 12.156 8.90672 12.3086L3.77726 17.4381C2.97335 18.242 2.9744 19.5463 3.77507 20.347L3.80953 20.3814C4.61211 21.184 5.91468 21.1829 6.71838 20.3792L11.8478 15.2498C12.0013 15.0963 12.2505 15.0971 12.4031 15.2498L17.5326 20.3792C18.3365 21.1831 19.6407 21.1821 20.4414 20.3814L20.4759 20.347C21.2785 19.5444 21.2774 18.2418 20.4737 17.4381L15.3442 12.3086C15.1908 12.1552 15.1916 11.906 15.3442 11.7534L20.4737 6.62389Z"
      fill="currentColor"
    />
  </svg>
)
