import { TextareaHTMLAttributes } from 'react'
import { useFormContext, RegisterOptions } from 'react-hook-form'

/* Design System */
import { TextArea, Label, ErrorMessage } from 'design-system'
import { styled } from 'styles/stitches.config'

const Wrapper = styled('div', {
  marginBlock: '$s',
})

interface Props
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'id'> {
  label?: string
  name: string
  type?: string
  options?: RegisterOptions
}

const FormTextarea = ({ label, name, options, ...props }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const error = errors[name]?.message

  return (
    <Wrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      <TextArea id={name} {...register(name, options)} {...props} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  )
}

export default FormTextarea
