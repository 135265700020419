import { SVGAttributes } from 'react'

export const AddGiftCard = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 26 27"
    fill="none"
  >
    <path
      d="M3.97705 16.8459H6.94007V18.6978H3.97705V16.8459Z"
      fill="currentColor"
    />
    <path
      d="M22.2505 5.92133H20.0755C20.3018 5.44433 20.4046 4.9181 20.3744 4.39099C20.3442 3.86389 20.182 3.35283 19.9028 2.90477C19.6235 2.45672 19.2361 2.08606 18.7761 1.82685C18.3161 1.56763 17.7984 1.42819 17.2705 1.42133C15.7148 1.38333 14.1992 1.91701 13.0105 2.92133C12.6728 3.21622 12.3753 3.5541 12.1255 3.92633C11.8757 3.5541 11.5781 3.21622 11.2405 2.92133C10.0518 1.91701 8.53618 1.38333 6.98049 1.42133C6.45257 1.42819 5.93483 1.56763 5.47488 1.82685C5.01493 2.08606 4.6275 2.45672 4.34822 2.90477C4.06893 3.35283 3.90675 3.86389 3.87656 4.39099C3.84638 4.9181 3.94917 5.44433 4.17549 5.92133H2.00049C1.50321 5.92133 1.02629 6.11888 0.674663 6.47051C0.323032 6.82214 0.125488 7.29905 0.125488 7.79633L0.125488 20.5463C0.125488 21.0436 0.323032 21.5205 0.674663 21.8722C1.02629 22.2238 1.50321 22.4213 2.00049 22.4213H22.2505C22.7478 22.4213 23.2247 22.2238 23.5763 21.8722C23.9279 21.5205 24.1255 21.0436 24.1255 20.5463V7.79633C24.1255 7.29905 23.9279 6.82214 23.5763 6.47051C23.2247 6.11888 22.7478 5.92133 22.2505 5.92133ZM14.2555 4.33133C15.0966 3.63347 16.163 3.26557 17.2555 3.29633C17.5936 3.29633 17.9179 3.43066 18.157 3.66977C18.3962 3.90888 18.5305 4.23318 18.5305 4.57133C18.5305 4.90948 18.3962 5.23378 18.157 5.47289C17.9179 5.712 17.5936 5.84633 17.2555 5.84633H13.2355C13.4345 5.25953 13.7867 4.73652 14.2555 4.33133ZM6.98049 3.29633C8.073 3.26557 9.13936 3.63347 9.98049 4.33133C10.4471 4.73237 10.7991 5.24995 11.0005 5.83133H6.98049C6.64234 5.83133 6.31804 5.697 6.07893 5.45789C5.83982 5.21878 5.70549 4.89448 5.70549 4.55633C5.70549 4.21818 5.83982 3.89388 6.07893 3.65477C6.31804 3.41566 6.64234 3.28133 6.98049 3.28133V3.29633ZM2.00049 7.79633H22.2505V9.67133H2.00049V7.79633ZM2.00049 20.5463V13.4213H22.2505V20.5463H2.00049Z"
      fill="currentColor"
    />
    <circle cx="20.5" cy="20.531" r="5.5" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 23.781C20.2239 23.781 20 23.5571 20 23.281L20 17.781C20 17.5049 20.2239 17.281 20.5 17.281C20.7761 17.281 21 17.5049 21 17.781L21 23.281C21 23.5571 20.7761 23.781 20.5 23.781Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 20.531C17.25 20.2549 17.4739 20.031 17.75 20.031H23.25C23.5261 20.031 23.75 20.2549 23.75 20.531C23.75 20.8071 23.5261 21.031 23.25 21.031H17.75C17.4739 21.031 17.25 20.8071 17.25 20.531Z"
      fill="white"
    />
  </svg>
)
