import { createContext } from 'react'

export type SmartBannerContextValues = {
  openModal: () => void
  closeModal: () => void
  journeyStep: string | null | undefined
}

export const SmartBannerContext = createContext<SmartBannerContextValues>({
  openModal: () => null,
  closeModal: () => null,
  journeyStep: undefined,
})
