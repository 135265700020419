import { styled } from 'styles/stitches.config'

export const Button = styled('button', {
  border: '2px solid transparent',
  cursor: 'pointer',
  display: 'inline-block',
  lineHeight: 'unset',
  outline: 'none',
  textAlign: 'center',
  textDecoration: 'none !important',
  verticalAlign: 'middle',
  position: 'relative',

  // ensures text is properly aligned when using the prop
  // `as="a"` to style an anchor tag using the Button component.
  '&::before': {
    content: '',
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
    width: 0,
  },

  '&:disabled': {
    cursor: 'default',
  },

  variants: {
    bold: {
      true: {
        fontWeight: '$extraBold',
      },
    },
    cta: {
      true: {
        width: '100%',
      },
    },
    size: {
      // height: 52,
      large: {
        borderRadius: 26,
        fontSize: '$heading4',
        lineHeight: '$heading4',
        minWidth: 128,
        padding: '10px $xxl',
      },
      small: {
        // height: 40,
        borderRadius: 20,
        fontSize: '$heading5',
        lineHeight: '$heading5',
        minWidth: 64,
        padding: '9px $m',
      },
    },
    variant: {
      primary: {
        backgroundColor: '$black',
        color: '$white',

        '&:disabled': {
          backgroundColor: '$grey200',
          color: '$grey600',
        },
      },
      secondary: {
        backgroundColor: 'transparent',
        borderColor: '$black',
        color: '$black',

        '&:disabled': {
          borderColor: '$grey600',
          color: '$grey600',
        },
      },
    },
    color: {
      white: {
        backgroundColor: '$white',
        borderColor: '$white',
        color: '$red500',
      },
    },
  },

  defaultVariants: {
    size: 'small',
    variant: 'primary',
  },
})

export const IconButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  borderRadius: '50%',
  boxShadow: '$storeCard',

  variants: {
    size: {
      small: {
        height: 32,
        width: 32,
        '& svg': {
          height: 16,
          width: 16,
        },
      },
      medium: {
        height: 40,
        width: 40,
        '& svg': {
          height: 20,
          width: 20,
        },
      },
      large: {
        height: 48,
        width: 48,
        '& svg': {
          height: 24,
          width: 24,
        },
      },
    },

    variant: {
      dark: {
        backgroundColor: '$grey1000',
        color: '$white',
      },

      light: {
        backgroundColor: '$white',
        color: '$grey1000',
      },
    },

    noShadow: {
      true: {
        boxShadow: 'none',
      },
    },
  },

  defaultVariants: {
    size: 'medium',
    variant: 'light',
  },
})

export const PillButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$s',
  gap: '$xxs',
  backgroundColor: '$white',
  boxShadow: '$generalDrop',
  color: '$grey800',
  borderRadius: '$xxxv',
  height: '46px',
  flexGrow: 1,
  flexDirection: 'row',
  alignContent: 'center',
  position: 'relative',
})
