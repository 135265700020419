import { useCallback, useEffect, useMemo, useState } from 'react'
import { ACHFailedContext } from './Context'
import ACHFailedBottomSheet from './'

type Props = {
  children?: React.ReactNode
}

export const ACHFailedBottomSheetProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    // istanbul ignore if
    if (typeof window === 'undefined') {
      return
    }

    const failedPaymentEventListener = () => {
      openModal()
    }

    window.addEventListener('failed_payments', failedPaymentEventListener)

    return () => {
      window.removeEventListener('failed_payments', failedPaymentEventListener)
    }
  })

  const value = useMemo(() => ({ openModal, closeModal }), [openModal, closeModal])

  return (
    <ACHFailedContext.Provider value={value}>
      {children}
      {<ACHFailedBottomSheet open={open} />}
    </ACHFailedContext.Provider>
  )
}
