import { Card } from './parts/Card'
import { CloseButton } from './parts/CloseButton'
import { Content } from './parts/Content'
import { Footer } from './parts/Footer'
import { Header } from './parts/Header'
import { Main } from './parts/Main'
import { Overlay } from './parts/Overlay'
import { Root, Props } from './parts/Root'

export const Modal = {
  Card,
  CloseButton,
  Content,
  Footer,
  Header,
  Main,
  Overlay,
  Root,
}

export type { Props }
