import { SVGAttributes } from 'react'

export const Trash = ({
  size = 17, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 2,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
    <path d="M13.167 5.01855C12.9902 5.01855 12.8206 5.08879 12.6956 5.21382C12.5706 5.33884 12.5003 5.50841 12.5003 5.68522V13.1459C12.4812 13.483 12.3296 13.7989 12.0786 14.0248C11.8277 14.2507 11.4976 14.3683 11.1603 14.3519H5.84032C5.50305 14.3683 5.17298 14.2507 4.92199 14.0248C4.67101 13.7989 4.51945 13.483 4.50032 13.1459V5.68522C4.50032 5.50841 4.43009 5.33884 4.30506 5.21382C4.18004 5.08879 4.01047 5.01855 3.83366 5.01855C3.65685 5.01855 3.48728 5.08879 3.36225 5.21382C3.23723 5.33884 3.16699 5.50841 3.16699 5.68522V13.1459C3.18602 13.8367 3.47805 14.4918 3.97911 14.9678C4.48018 15.4437 5.14944 15.7017 5.84032 15.6852H11.1603C11.8512 15.7017 12.5205 15.4437 13.0215 14.9678C13.5226 14.4918 13.8146 13.8367 13.8336 13.1459V5.68522C13.8336 5.50841 13.7634 5.33884 13.6384 5.21382C13.5134 5.08879 13.3438 5.01855 13.167 5.01855Z" fill="currentColor" />
    <path d="M13.8333 3.01855H11.1667V1.68522C11.1667 1.50841 11.0964 1.33884 10.9714 1.21382C10.8464 1.08879 10.6768 1.01855 10.5 1.01855H6.5C6.32319 1.01855 6.15362 1.08879 6.02859 1.21382C5.90357 1.33884 5.83333 1.50841 5.83333 1.68522V3.01855H3.16667C2.98986 3.01855 2.82029 3.08879 2.69526 3.21382C2.57024 3.33884 2.5 3.50841 2.5 3.68522C2.5 3.86203 2.57024 4.0316 2.69526 4.15662C2.82029 4.28165 2.98986 4.35189 3.16667 4.35189H13.8333C14.0101 4.35189 14.1797 4.28165 14.3047 4.15662C14.4298 4.0316 14.5 3.86203 14.5 3.68522C14.5 3.50841 14.4298 3.33884 14.3047 3.21382C14.1797 3.08879 14.0101 3.01855 13.8333 3.01855V3.01855ZM7.16666 3.01855V2.35189H9.83333V3.01855H7.16666Z" fill="currentColor" />
    <path d="M7.83333 11.6854V7.01872C7.83333 6.84191 7.76309 6.67234 7.63807 6.54731C7.51304 6.42229 7.34348 6.35205 7.16667 6.35205C6.98985 6.35205 6.82029 6.42229 6.69526 6.54731C6.57024 6.67234 6.5 6.84191 6.5 7.01872V11.6854C6.5 11.8622 6.57024 12.0318 6.69526 12.1568C6.82029 12.2818 6.98985 12.3521 7.16667 12.3521C7.34348 12.3521 7.51304 12.2818 7.63807 12.1568C7.76309 12.0318 7.83333 11.8622 7.83333 11.6854Z" fill="currentColor" />
    <path d="M10.5003 11.6854V7.01872C10.5003 6.84191 10.4301 6.67234 10.3051 6.54731C10.18 6.42229 10.0105 6.35205 9.83366 6.35205C9.65685 6.35205 9.48728 6.42229 9.36226 6.54731C9.23723 6.67234 9.16699 6.84191 9.16699 7.01872V11.6854C9.16699 11.8622 9.23723 12.0318 9.36226 12.1568C9.48728 12.2818 9.65685 12.3521 9.83366 12.3521C10.0105 12.3521 10.18 12.2818 10.3051 12.1568C10.4301 12.0318 10.5003 11.8622 10.5003 11.6854Z" fill="currentColor" />
  </svg>

)
