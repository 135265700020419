import { useMemo } from 'react'

import { CreateOrderRequest } from 'types/api/order'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { getLocalStorage } from 'utils/browserStorage'

// the native app should tell us via URL query which device it's running on
const useDevice = () => {
  const device = (function getDeviceFromLocalStorage() {
    /* istanbul ignore next */
    if (typeof window === 'undefined') {
      return ''
    }

    const deviceFromLocalStorage = getLocalStorage(LocalStorageKeys.DEVICE)

    if (deviceFromLocalStorage) {
      try {
        return JSON.parse(deviceFromLocalStorage)
      } catch (error) {
        return deviceFromLocalStorage
      }
    }

    if (window.innerWidth < 768) {
      return 'mobile-web'
    }

    return 'desktop'
  })()

  const deviceVersion = (function getDeviceVersionFromLocalStorage() {
    /* istanbul ignore else */
    if (typeof window !== 'undefined') {
      const deviceVersionFromLocalStorage = getLocalStorage(LocalStorageKeys.DEVICE_VERSION)

      if (deviceVersionFromLocalStorage) {
        try {
          return JSON.parse(deviceVersionFromLocalStorage)
        } catch (error) {
          return deviceVersionFromLocalStorage
        }
      }
    }

    return '3.1.3'
  })()

  const isIOS = device === 'ios'

  const isAndroid = device === 'android'

  const isNativeApp = isAndroid || isIOS

  const isBrowser = !isNativeApp

  const isMobileWeb = device === 'mobile-web'

  const createOrderSource: CreateOrderRequest['source'] = useMemo(() => {
    if (isNativeApp) return 'app'

    return device as 'mobile-web' | 'desktop'
  }, [device, isNativeApp])

  const isMd = window.innerWidth <= 768

  return { isIOS, isAndroid, isNativeApp, isBrowser, createOrderSource, deviceVersion, isMobileWeb, isMd }
}

export default useDevice
