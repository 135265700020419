import hash from 'object-hash'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import { LocalStorageKeys } from 'config/localStorageKeys'

import { useLocalStorage } from 'hooks/useLocalStorage'

export const useIdempotencyKey = () => {
  const [idempotencyObject, setIdempotencyObject] = useLocalStorage<{ [key: string]: string }>(LocalStorageKeys.IDEMPOTENCY_OBJECT, {})

  const setIdempotencyKey = useCallback((data: Record<string, unknown>) => {
    const dataHash = hash(data)
    const currentIdempotencyKey = idempotencyObject[dataHash]

    if (currentIdempotencyKey) {
      return currentIdempotencyKey
    }

    const newKey = uuid()
    const newIdempotencyObject = { [dataHash]: newKey }

    setIdempotencyObject(newIdempotencyObject)
    return newKey
  }, [idempotencyObject, setIdempotencyObject])

  const clearIdempotencyKey = useCallback(() => {
    setIdempotencyObject({})
  }, [setIdempotencyObject])

  return {
    clearIdempotencyKey,
    setIdempotencyKey,
  }
}
