import { useState } from 'react'
import { animated, CSS, useTransition } from '@react-spring/web'
import { Modal, useModal } from 'components/ModalKit'

type Props = {
  children?: React.ReactNode
  modalId?: string
  transitionStyles?: CSS.Properties
  onClose?: () => void | Promise<void>
  disableClose?: boolean
}

export const BottomModal = ({
  children,
  modalId,
  transitionStyles,
  onClose,
  disableClose = false,
}: Props) => {
  // provide some useless defaults to stop TypeScript complaining;
  // values will always be provided by React.cloneElement, though TS doesn't understand that.
  /* istanbul ignore next */
  const id = modalId || ''
  /* istanbul ignore next */
  const styles = transitionStyles || {}

  const { closeModal } = useModal()
  const [open, setOpen] = useState(true)

  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%) translateY(12px)',
    },
    enter: {
      transform: 'translateY(0%) translateY(0px)',
    },
    leave: {
      transform: 'translateY(100%) translateY(12px)',
    },
  })

  const handleClose = () => {
    if (disableClose) {
      return
    }

    closeModal(modalId)
    setOpen(false)

    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal.Root modalId={id} transitionStyles={styles}>
      <Modal.Overlay handleClose={handleClose} />
      <Modal.Content hAlign="center" vAlign="bottom">
        {transitions(
          (styles, item) =>
            item && (
              <animated.div style={styles}>
                <Modal.Card>
                  {children}
                  {!disableClose && (
                    <Modal.CloseButton handleClose={handleClose} />
                  )}
                </Modal.Card>
              </animated.div>
            )
        )}
      </Modal.Content>
    </Modal.Root>
  )
}
