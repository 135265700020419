import { createPortal } from 'react-dom'
import { usePortal } from './usePortal'

type Props = {
  attachment?: (root: Element, element: Element) => void
  children: React.ReactNode
  element?: string
  id?: string
}

export const Portal = ({
  attachment,
  children,
  element,
  id,
}: Props) => {
  const target = usePortal({ attachment, element, id })
  return target ? createPortal(children, target) : null
}
