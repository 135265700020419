import { styled } from 'styles/stitches.config'

export const Aura = styled('span', {
  backgroundColor: '$white',
  borderRadius: '50%',
  display: 'inline-block',
  fontSize: 0,
  padding: '$xxs',

  variants: {
    variant: {
      contained: {
        background: '$grey200',
        color: '$grey1000',
        borderRadius: '$xxxs',
      }
    }
  }
})
