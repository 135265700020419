import { createStitches } from '@stitches/react'

export const { styled, getCssText, theme, css } = createStitches({
  prefix: 'joe',
  theme: {
    borderStyles: {},
    borderWidths: {},
    colors: {
      bismark: '#486d85',

      blue100: '#EBF5FD', // info light
      blue200: '#CDE7FD',
      blue300: '#9CD0FB', // info mid
      blue400: '#6CB8FA',
      blue500: '#3DA1F8',
      blue600: '#198BF7',
      blue700: '#086FCF', // info dark
      blue800: '#065399',
      blue900: '#043764',
      blue1000: '#011B31',

      green100: '#EAF6ED', // success light
      green200: '#D8F2DE',
      green300: '#BEE9C8',
      green400: '#A0DEAD', // success mid
      green500: '#72CD81',
      green600: '#5AB064',
      green700: '#499053',
      green800: '#499053', // success dark
      green900: '#234829',
      green1000: '#152C18',

      grey50: '#FAFAFA',
      grey100: '#F5F5F5',
      grey150: '#EEEEEE',
      grey200: '#E7E7E7', // disabled light
      grey300: '#D0D0D0',
      grey400: '#B9B9B9',
      grey500: '#A2A2A2',
      grey600: '#8B8B8B', // disabled dark
      grey700: '#737373',
      grey800: '#5C5C5C', // lighter text
      grey900: '#454545',
      grey1000: '#2E2E2E', // standard text

      red100: '#FDF3F3',
      red200: '#FAD4D4', // error light
      red300: '#F3807F', // error mid
      red400: '#F05656',
      red500: '#E21410', // primary red
      red600: '#CE171C', // error dark
      red700: '#AB1316',
      red800: '#800D10',
      red900: '#550809',
      red1000: '#2B0304',

      yellow100: '#FFF7E8', // warning light
      yellow200: '#FEEFCC',
      yellow300: '#FEDE9A',
      yellow400: '#FFCC55', // warning mid
      yellow500: '#FEB833',
      yellow600: '#E6A126',
      yellow700: '#B8801C', // warning dark
      yellow800: '#90630C',
      yellow900: '#654300',
      yellow1000: '#2E2002',

      white: '#ffffff',
      black: '#171717',
    },
    fonts: {
      monospace:
        'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
      sansSerif:
        'Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      system:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    fontSizes: {
      heading1: '1.75rem', // 28px
      heading2: '1.5rem', // 24px
      heading3: '1.25rem', // 20px
      heading4: '1.125rem', // 18px
      heading5: '0.875rem', // 14px

      body1: '1rem', // 16px
      body2: '0.875rem', // 14px
      body3: '0.75rem', // 12px

      tagLabel: '0.625rem', // 10px
    },
    fontWeights: {
      thin: 100,
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
    letterSpacings: {},
    lineHeights: {
      heading1: '2.25rem', // 36px, 128.571429%
      heading2: '2.125rem', // 34px, 141.666667%
      heading3: '1.875rem', // 30px, 150%
      heading4: '1.75rem', // 28px, 155.555556%
      heading5: '1.25rem', // 20px, 142.857143%

      body1: '1.5rem', // 24px, 150%
      body2: '1.25rem', // 20px, 142.857143%
      body3: '1.125rem', // 18px, 150%

      tagLabel: '1rem', // 16px, 160%
    },
    radii: {
      xt: '2px',
      t: '3px',
      xxxs: '4px',
      xxs: '6px',
      xs: '8px',
      s: '12px',
      m: '16px',
      l: '18px',
      xl: '20px',
      xxl: '24px',
      xxxl: '28px',
      v: '32px',
      xv: '36px',
      xxv: '42px',
      xxxv: '44px',
      full: '9999px',
    },
    shadows: {
      elevationLow:
        '0px 0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px 0.2px 0.3px -0.2px hsl(0deg 0% 0% / 0.08),0px 0.4px 0.6px -0.5px hsl(0deg 0% 0% / 0.13)',
      elevationLowInverted:
        '0px -0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px -0.2px 0.3px -0.2px hsl(0deg 0% 0% / 0.08), 0px -0.4px 0.6px -0.5px hsl(0deg 0% 0% / 0.13)',
      elevationMedium:
        '0px 0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px 0.4px 0.6px -0.2px hsl(0deg 0% 0% / 0.06), 0px 0.9px 1.3px -0.3px hsl(0deg 0% 0% / 0.11), 0.1px 1.9px 2.7px -0.5px hsl(0deg 0% 0% / 0.15)',
      elevationMediumInverted:
        '0px -0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px -0.4px 0.6px -0.2px hsl(0deg 0% 0% / 0.06), 0px -0.9px 1.3px -0.3px hsl(0deg 0% 0% / 0.11), 0.1px -1.9px 2.7px -0.5px hsl(0deg 0% 0% / 0.15)',
      elevationHigh:
        '0px 0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px 0.9px 1.3px -0.1px hsl(0deg 0% 0% / 0.04), 0.1px 1.6px 2.3px -0.1px hsl(0deg 0% 0% / 0.06), 0.1px 2.4px 3.4px -0.2px hsl(0deg 0% 0% / 0.08), 0.1px 3.4px 4.8px -0.3px hsl(0deg 0% 0% / 0.1), 0.2px 4.9px 7px -0.4px hsl(0deg 0% 0% / 0.12), 0.3px 6.9px 9.8px -0.4px hsl(0deg 0% 0% / 0.14), 0.4px 9.6px 13.7px -0.5px hsl(0deg 0% 0% / 0.16)',
      elevationHighInverted:
        '0px -0.1px 0.1px hsl(0deg 0% 0% / 0.02), 0px -0.9px 1.3px -0.1px hsl(0deg 0% 0% / 0.04), 0.1px -1.6px 2.3px -0.1px hsl(0deg 0% 0% / 0.06), 0.1px -2.4px 3.4px -0.2px hsl(0deg 0% 0% / 0.08), 0.1px -3.4px 4.8px -0.3px hsl(0deg 0% 0% / 0.1), 0.2px -4.9px 7px -0.4px hsl(0deg 0% 0% / 0.12), 0.3px -6.9px 9.8px -0.4px hsl(0deg 0% 0% / 0.14), 0.4px -9.6px 13.7px -0.5px hsl(0deg 0% 0% / 0.16)',

      feed: '0px 0px 10px rgba(0, 0, 0, 0.05)',
      storeCard: '-2px 4px 20px rgba(0, 0, 0, 0.1)',
      generalDrop: '0px 2px 16px 0px rgba(46, 46, 46, 0.08)',
      top: '0px -8px 8px 0px rgba(0, 0, 0, 0.04)',
      bottom: '0px 8px 8px 0px rgba(0, 0, 0, 0.04)',
    },
    sizes: {
      applicationWidth: '768px',
      buttonLargeHeight: '52px',
      buttonSmallHeight: '40px',
      navHeight: '72px',
      headerHeight: '56px',
      tallHeaderHeight: '72px',
    },
    space: {
      xt: '2px',
      t: '3px',
      xxxs: '4px',
      xxs: '6px',
      xs: '8px',
      s: '12px',
      m: '16px',
      l: '18px',
      xl: '20px',
      xxl: '24px',
      xxxl: '28px',
      v: '32px',
      xv: '36px',
      xxv: '42px',
      xxxv: '44px',

      headerHeight: '56px',
      tallHeaderHeight: '72px',
      navHeight: 'calc(var(--joe-sizes-navHeight) + 28px)',
    },
    transitions: {
      opacityQuick: 'opacity .25s ease-in',
    },
    zIndices: {
      0: 0,
    },
  },
})
