import React from 'react'
import Image from 'next/image'
import { Body2 } from 'design-system'
import { ImageWrapper, Page } from './Common'

export const ExperienceLoader = () => {
  return (
    <Page aria-label="loading-screen">
      <ImageWrapper>
        <Image
          alt=""
          src="/images/joe_loading_animation.gif"
          width={136}
          height={173}
        />
      </ImageWrapper>
      <Body2>Personalizing your coffee ordering experience.....</Body2>
    </Page>
  )
}
