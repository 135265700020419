export enum LocalStorageKeys {
  CART_DATA = 'cartData',
  DEVICE = 'source-device',
  CHECKOUT_DATA = 'checkoutData',
  IDEMPOTENCY_OBJECT = 'idempotencyObject',
  LOCATION_SERVICES = 'locationServices',
  MOCK_DATA = 'mockData',
  PREFERRED_PM = 'preferredPaymentMethod',
  USER_DATA = 'userData',
  VEHICLE_INFO = 'vehicleInfo',
  LAST_ORDER = 'lastOrder',
  REWARD_INDICATOR = 'rewardIndicator',
  REQUEST_REVIEWED = 'reviewRequested',
  LAST_NOTIFY_OFFER = 'lastNotifyOffer',
  LAST_REQUEST_PUSH_NOTIFICATION = 'lastRequestPushNotification',
  PUSH_NOTIFICATION_REQUEST_ACCEPTED = 'pushNotificationRequestAccepted',
  DEVICE_VERSION = 'deviceVersion',
  LAST_SHOW_SMART_BANNER = 'lastShowSmartBanner',
  SHOWED_PIN_GUIDE = 'showedPinGuide',
  SHOWED_USUAL_ITEM_GUIDE = 'showedUsualItemGuide',
}

export enum LocalStorageTestKeys {
  ERROR = 'error',
  TEST = 'test',
}

export enum SessionStorageKeys {
  SHOW_AUTO_RELOAD_BANNER = 'showAutoReloadBanner',
  SHOW_SMART_BANNER = 'showSmartBanner',
}

export enum SessionStorageTestKeys {
  ERROR = 'error',
  TEST = 'test',
}

export const keysToClearOnError = [
  LocalStorageKeys.CART_DATA,
  LocalStorageKeys.CHECKOUT_DATA,
]

export const keysToClearOnLogout = [
  LocalStorageKeys.CART_DATA,
  LocalStorageKeys.CHECKOUT_DATA,
  LocalStorageKeys.IDEMPOTENCY_OBJECT,
  LocalStorageKeys.USER_DATA,
  LocalStorageKeys.LAST_ORDER,
  LocalStorageKeys.REWARD_INDICATOR,
  LocalStorageKeys.LAST_NOTIFY_OFFER,
  LocalStorageKeys.SHOWED_PIN_GUIDE,
]

export const keysToClearOnNuke = [
  LocalStorageKeys.CART_DATA,
  LocalStorageKeys.CHECKOUT_DATA,
  LocalStorageKeys.IDEMPOTENCY_OBJECT,
  LocalStorageKeys.MOCK_DATA,
  LocalStorageKeys.PREFERRED_PM,
  LocalStorageKeys.USER_DATA,
  LocalStorageKeys.VEHICLE_INFO,
  LocalStorageKeys.LAST_ORDER,
  LocalStorageKeys.REWARD_INDICATOR,
  LocalStorageKeys.LAST_NOTIFY_OFFER,
]
