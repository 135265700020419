import { SVGAttributes } from 'react'

export const Logo = ({
  size = 20,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3118 18.3332C14.9142 18.3332 18.6452 14.6022 18.6452 9.99984C18.6452 5.39746 14.9142 1.6665 10.3118 1.6665C5.70948 1.6665 1.97852 5.39746 1.97852 9.99984C1.97852 14.6022 5.70948 18.3332 10.3118 18.3332Z" fill="#2E2E2E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8102 10.3543C15.0553 10.5573 15.2982 10.7631 15.5418 10.9679C15.3908 11.1877 15.1421 11.4461 14.7388 11.6469C14.1836 11.9232 13.557 11.896 13.0591 11.7013C12.7869 11.5949 12.5537 11.439 12.391 11.2551C11.9312 10.7346 11.6109 9.79916 12.2217 8.87612C12.8284 7.95968 13.811 7.88791 14.4682 8.10486C14.982 8.27561 15.3737 8.63278 15.5886 9.08151C15.7612 9.44198 15.411 9.58551 15.411 9.58551L13.1172 10.6174C13.2275 10.7403 13.3661 10.8369 13.5222 10.8979C14.0226 11.0934 14.5504 10.808 14.8102 10.3543ZM12.8516 9.87422C12.8516 9.87422 12.7628 9.25226 13.3985 8.95613C13.6367 8.84477 13.8491 8.85467 14.0209 8.88602C14.3446 8.94458 14.5321 9.13101 14.5321 9.13101L12.8516 9.87422Z" fill="white"/>
    <path d="M9.44237 8.00917C8.25395 8.00917 7.39251 8.82333 7.39251 9.94351C7.39251 11.0645 8.25395 11.8778 9.44237 11.8778C10.6308 11.8778 11.4922 11.0645 11.4922 9.94351C11.4914 8.82333 10.63 8.00917 9.44237 8.00917ZM9.44237 10.9004C8.89463 10.9004 8.51122 10.5069 8.51122 9.94269C8.51122 9.38012 8.89463 8.98583 9.44237 8.98583C9.9818 8.98583 10.3735 9.38837 10.3735 9.94269C10.3735 10.5069 9.9901 10.9004 9.44237 10.9004Z" fill="white"/>
    <path d="M7.02569 6.94755C7.02569 7.30745 6.73216 7.59921 6.37007 7.59921C6.00798 7.59921 5.71445 7.30745 5.71445 6.94755C5.71445 6.58765 6.00798 6.2959 6.37007 6.2959C6.73216 6.2959 7.02569 6.58765 7.02569 6.94755Z" fill="white"/>
    <path d="M5.72026 13.7033C5.72026 13.7033 6.93607 12.8826 6.9427 11.2542C6.94934 9.62593 6.9427 8.10238 6.9427 8.10238H5.78997C5.78997 8.10238 5.79246 10.8402 5.79246 11.3268C5.79246 11.8127 5.67295 12.2787 4.98828 13.0929L5.72026 13.7033Z" fill="white"/>
  </svg>
)
