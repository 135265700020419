import { SVGAttributes } from 'react'

export const GooglePay = ({ size = 20 }: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 4.33333H3C2.35567 4.33333 1.83333 4.85567 1.83333 5.5V14.5C1.83333 15.1443 2.35567 15.6667 3 15.6667H17C17.6443 15.6667 18.1667 15.1443 18.1667 14.5V5.5C18.1667 4.85567 17.6443 4.33333 17 4.33333ZM3 2.5C1.34315 2.5 0 3.84315 0 5.5V14.5C0 16.1569 1.34315 17.5 3 17.5H17C18.6569 17.5 20 16.1569 20 14.5V5.5C20 3.84315 18.6569 2.5 17 2.5H3Z" fill="currentColor" />
    <path d="M14.1231 10.0112C14.1235 9.73283 14.1 9.45498 14.053 9.18066H10.2151V10.7537H12.4133C12.3683 11.005 12.2731 11.2445 12.1334 11.4578C11.9937 11.6711 11.8124 11.8538 11.6005 11.9949V13.0161H12.9124C13.6806 12.3053 14.1231 11.2541 14.1231 10.0112Z" fill="currentColor" />
    <path d="M10.215 14.0029C11.3133 14.0029 12.238 13.641 12.9123 13.017L11.6004 11.9959C11.2353 12.2443 10.765 12.3862 10.215 12.3862C9.15351 12.3862 8.25252 11.668 7.93034 10.7002H6.57886V11.7525C6.91759 12.429 7.43699 12.9976 8.07908 13.395C8.72117 13.7924 9.46067 14.0029 10.215 14.0029V14.0029Z" fill="currentColor" />
    <path d="M7.93034 10.6997C7.76001 10.1926 7.76001 9.64341 7.93034 9.13629V8.08398H6.57886C6.29391 8.65305 6.14551 9.28112 6.14551 9.918C6.14551 10.5549 6.29391 11.183 6.57886 11.752L7.93034 10.6997Z" fill="#2E2E2E" />
    <path d="M10.215 7.45003C10.7954 7.44051 11.3562 7.66059 11.7762 8.06269L12.9378 6.89693C12.2012 6.20262 11.2255 5.82143 10.215 5.83328C9.46067 5.83331 8.72116 6.04377 8.07908 6.44115C7.43699 6.83854 6.91759 7.40721 6.57886 8.08368L7.93034 9.13599C8.25252 8.16821 9.15351 7.45003 10.215 7.45003Z" fill="currentColor" />
  </svg>

)
