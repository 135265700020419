import React from 'react'

type Props = {
  size?: number
}

export const LoadingSvg = ({ size = 24 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    data-testid="LoadingSvg"
  >
    <path
      d="M24.0911 15.1058C23.5183 17.2435 22.3656 19.1811 20.7603 20.7045C19.155 22.2279 17.1596 23.2776 14.9949 23.7378C12.8302 24.1979 10.5804 24.0504 8.49432 23.3117C6.40819 22.573 4.56698 21.2717 3.17425 19.5518C1.78152 17.832 0.891525 15.7605 0.602662 13.5663C0.313798 11.3722 0.637318 9.1409 1.53745 7.11916C2.43759 5.09742 3.87928 3.36398 5.70312 2.11049C7.52697 0.856991 9.66193 0.132269 11.872 0.0164457L12.0478 3.37184C10.4566 3.45523 8.91942 3.97703 7.60625 4.87955C6.29308 5.78207 5.25506 7.03014 4.60697 8.4858C3.95887 9.94145 3.72593 11.548 3.93392 13.1277C4.1419 14.7075 4.78269 16.199 5.78546 17.4373C6.78822 18.6756 8.1139 19.6125 9.61591 20.1444C11.1179 20.6763 12.7378 20.7825 14.2964 20.4512C15.8549 20.1199 17.2916 19.3641 18.4474 18.2672C19.6032 17.1704 20.4332 15.7753 20.8456 14.2362L24.0911 15.1058Z"
      fill="currentColor"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12.5 12"
        to="360 12.5 12"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)
