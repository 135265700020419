import { Modal, ModalProps, useModal } from 'components/ModalKit'

export const StandardModal = ({ children, modalId, transitionStyles }: Partial<ModalProps>) => {
  const { closeModal } = useModal()

  const handleClose = () => {
    closeModal(modalId)
  }

  return (
    <Modal.Root modalId={modalId as string} transitionStyles={transitionStyles}>
      <Modal.Overlay handleClose={handleClose} />
      <Modal.Content hAlign="center" vAlign="center">
        <Modal.Card>
          {children}
          <Modal.CloseButton handleClose={handleClose} />
        </Modal.Card>
      </Modal.Content>
    </Modal.Root>
  )
}
