import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalContext } from './ModalContext'
import { Portal } from 'components/Portal'
import { useTransition } from '@react-spring/web'
import { v4 as uuidv4 } from 'uuid'

export const bodyOverflowEffect: (modalsExist: boolean) => void = (modalsExist: boolean) => {
  if (modalsExist && document.body.style.overflow !== 'hidden') {
    const originalOverflow = window.getComputedStyle(document.body).overflow

    document.body.style.overflow = 'hidden'
    return () => document.body.style.overflow = originalOverflow
  }
}

type Props = {
  children?: React.ReactNode
}

type Modal = {
  modal: React.ReactElement
  modalId: string
}

export const ModalProvider = ({ children }: Props) => {
  const [modals, setModals] = useState<Modal[]>([])
  const modalsExist = useMemo(() => modals.length > 0, [modals])

  useEffect(() => bodyOverflowEffect(modalsExist), [modalsExist])

  const transitions = useTransition(modals, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const closeModal = useCallback((modalId?: string) => {
    if (modalId) {
      setModals((prevModals) => prevModals.filter((modal) => modal.modalId !== modalId))
      return
    }

    setModals((prevModals) => prevModals.splice(-1))
  }, [])

  const closeAllModals = useCallback(() => {
    setModals([])
  }, [])

  const isTopModal = useCallback((modalId: string) => {
    const id = modals[modals.length - 1].modalId
    return id === modalId
  }, [modals])

  const openModal = useCallback((nextModal: React.ReactElement, id?: string) => {
    const modalId = `${id || uuidv4()}`

    setModals((prevModals) => {
      return [...prevModals, { modal: nextModal, modalId }]
    })

    return modalId
  }, [])

  const value = useMemo(() => ({
    closeModal, isTopModal, openModal, closeAllModals,
  }), [closeAllModals, closeModal, isTopModal, openModal])

  return (
    <ModalContext.Provider
      value={value}
    >
      {children}

      {transitions((transitionStyles, { modal, modalId }) => {
        const clonedModal = React.cloneElement(modal, {
          modalId,
          transitionStyles,
        })

        return (
          <Portal id="modals" key={modalId}>
            {clonedModal}
          </Portal>
        )
      })}
    </ModalContext.Provider>
  )
}
