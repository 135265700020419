import Big from 'big.js'
import JoeLoader from 'components/JoeLoader'
import { CtaPage } from 'components/Layout'
import PaymentMethodWidget from 'components/pages/cart/CheckoutSheet/PaymentMethodWidget'
import { Button, H2 } from 'design-system'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { styled } from 'styles/stitches.config'
import { formatMoney } from 'utils/money'
import FailedPaymentDetail from './FailedPaymentDetail'
import useNotification from 'hooks/useNotification'
import { useIdempotencyKey } from 'hooks/useIdempotencyKey'
import useEventErrorHandler from 'hooks/useEventErrorHandler'
import useProfile from 'hooks/useProfile'
import { useNativePay } from 'hooks/useNativePay'
import { ACHFailedContext } from './Context'
import { isEmpty } from 'lodash'
import { api } from 'config/apiClient'
import { useFetchFailedPaymentFormData } from './hooks/useFetchFailedPaymentFormData'
import { UserPaymentMethod } from 'types/user'

const Heading = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '$s',
  padding: '0 $xxl',
})

const WarningDescription = styled('span', {
  textAlign: 'center',
  fontSize: '$body1',
  lineHeight: '$body1',
})

const FailedPaymentsList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 $s',
})

const FailedPaymentContainer = styled('div', {
  padding: '$m 0',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$xxs',
  '& + &': {
    borderTop: '1px solid $grey200',
  },
})

const FailedPaymentName = styled('span', {
  fontSize: '$body1',
  lineHeight: '$body1',
  fontWeight: '$bold',
  color: '$grey1000',
})

const FailedPaymentDetails = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'cetner',
  justifyContent: 'space-between',
  fontSize: '$body2',
  lineHeight: '$body2',
})

const FailedPaymentAmount = styled('span', {
  fontWeight: '$bold',
  color: '$grey1000',
})

const FailedPaymentDescription = styled('span', {
  color: '$grey800',
})

interface FormData {
  paymentMethod: UserPaymentMethod
}

interface Props {
  open: boolean
}

const FailedPaymentUpdateForm = ({ open }: Props) => {
  const methods = useForm<FormData>()
  const { closeModal } = useContext(ACHFailedContext)

  const { failedPayments, paymentMethods, total, isFetching } =
    useFetchFailedPaymentFormData(open)

  const [loading, setLoading] = useState(false)
  const submittingRequest = useRef(false)
  const { notifySuccess, notifyError } = useNotification()
  const { setIdempotencyKey, clearIdempotencyKey } = useIdempotencyKey()
  const { handleError } = useEventErrorHandler()
  const [profile] = useProfile()

  const paymentMethod = methods.watch('paymentMethod')

  const handlePayingFailedPayment = useCallback(
    async (paymentMethodId: string, paymentMethodType: string) => {
      // istanbul ignore if
      if (submittingRequest.current) {
        return
      }

      submittingRequest.current = true

      try {
        const paymentParams = {
          paymentMethodId,
          paymentMethodType,
          amount: '100',
        }

        const idempotencyKey = setIdempotencyKey(paymentParams)

        const res = await api('updateFailedPayments', {
          userId: profile?.id as string,
          paymentIntentIds: failedPayments.map(
            ({ paymentIntentId }) => paymentIntentId
          ),
          paymentMethodId,
          paymentMethodType,
          idempotencyKey,
        })

        //  istanbul ignore else
        if (res.success) {
          notifySuccess('Thank you for updating the payments')
          clearIdempotencyKey()

          closeModal()
          setLoading(false)
          return
        }

        // istanbul ignore else
        if (!isEmpty(res.errors)) {
          submittingRequest.current = false
          notifyError(res.errors.join(', '))
        }
      } catch (error) {
        submittingRequest.current = false
        handleError(error)
      }

      setLoading(false)
    },
    [
      setIdempotencyKey,
      profile?.id,
      failedPayments,
      notifySuccess,
      clearIdempotencyKey,
      closeModal,
      notifyError,
      handleError,
    ]
  )

  const onCancelPayment = useCallback(() => {
    submittingRequest.current = false
    setLoading(false)
  }, [setLoading])

  const onReceiveNativePayConfirm = useCallback(() => {
    setLoading(true)
  }, [setLoading])

  const { checkIfPaymentIsAppleOrGoogle, setPaymentRequest } = useNativePay({
    onCancel: onCancelPayment,
    paymentMethod,
    onFinishPayment: handlePayingFailedPayment,
    onReceiveNativePayConfirm: onReceiveNativePayConfirm,
  })

  const onSubmit = useCallback(
    async (formData: FormData) => {
      setLoading(true)

      const checkIfPaymentIsAppleOrGoogleResult = checkIfPaymentIsAppleOrGoogle(
        formData.paymentMethod.paymentId,
        total.toString()
      )

      if (checkIfPaymentIsAppleOrGoogleResult) {
        return
      }

      await handlePayingFailedPayment(
        formData.paymentMethod.paymentId,
        formData.paymentMethod.type
      )
    },
    [checkIfPaymentIsAppleOrGoogle, handlePayingFailedPayment, total]
  )

  const onAddingNewPaymentMethod = useCallback(() => {
    closeModal()
  }, [closeModal])

  const totalPenalty = useMemo(
    () =>
      failedPayments.reduce(
        (
          penaltyFee,
          { insufficientFundsPenaltyFee: { currencyAmount: penaltyFeeAmount } }
        ) => penaltyFee + parseInt(penaltyFeeAmount),
        0
      ),
    [failedPayments]
  )

  const CtaComponent = useCallback(
    () => (
      <>
        <PaymentMethodWidget
          paymentMethods={paymentMethods}
          setPaymentRequest={setPaymentRequest}
          onAddingNewPaymentMethod={onAddingNewPaymentMethod}
          onlyCreditCard
        />
        <Button
          data-testid="failed-payment-update-form-submit-button"
          cta
          size="large"
          bold
          disabled={isFetching || loading}
          form="failed-payment-update-form"
          type="submit"
        >
          Pay
        </Button>
      </>
    ),
    [
      isFetching,
      loading,
      onAddingNewPaymentMethod,
      paymentMethods,
      setPaymentRequest,
    ]
  )

  return (
    <FormProvider {...methods}>
      <form
        id="failed-payment-update-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <CtaPage
          ctaComponent={CtaComponent}
          overrideMainCSS={{ rowGap: '$xxl' }}
          disableNav
        >
          <Heading>
            <img
              src="/images/isolation.svg"
              alt="isolation"
              style={{ height: '60px' }}
            />
            <H2>We have a problem</H2>
            <WarningDescription>
              We were unable to capture funds for the below transactions. Please
              submit payment to continue.
            </WarningDescription>
          </Heading>
          <FailedPaymentsList>
            {isFetching && <JoeLoader />}
            {failedPayments.map((props) => (
              <FailedPaymentDetail key={props.paymentIntentId} {...props} />
            ))}
            {!isFetching && (
              <FailedPaymentContainer>
                <FailedPaymentName>Insufficient funds</FailedPaymentName>
                <FailedPaymentDetails>
                  <FailedPaymentAmount>
                    {formatMoney(Big(totalPenalty).div(100).toNumber())}
                  </FailedPaymentAmount>
                  <FailedPaymentDescription>
                    Total penalty fee
                  </FailedPaymentDescription>
                </FailedPaymentDetails>
              </FailedPaymentContainer>
            )}
            {!!total && (
              <FailedPaymentContainer css={{ padding: '$xxl 0' }}>
                <FailedPaymentDetails
                  css={{
                    fontWeight: '$bold',
                    fontSize: '$body1',
                    lineHeight: '$body1',
                  }}
                >
                  <FailedPaymentAmount>
                    {formatMoney(Big(total).div(100).toNumber())}
                  </FailedPaymentAmount>
                  <FailedPaymentDescription>Total</FailedPaymentDescription>
                </FailedPaymentDetails>
              </FailedPaymentContainer>
            )}
          </FailedPaymentsList>
        </CtaPage>
      </form>
    </FormProvider>
  )
}

export default FailedPaymentUpdateForm
