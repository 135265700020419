import { styled } from 'styles/stitches.config'

const OverlayElement = styled('div', {
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  userSelect: 'none',
})

export const Overlay = ({ handleClose }: { handleClose: () => void }) => (
  <OverlayElement aria-label="Modal Overlay" onClick={handleClose} role="button" data-testid="portal-overlay" />
)
