import { isEqual } from 'lodash'
import { useEffect, useRef } from 'react'
import { RecoilState, useRecoilState } from 'recoil'

import { useLocalStorage } from './useLocalStorage'

export const useMirrorRecoilStateToLocalStorage = <StateType>(
  {
    recoilState,
    localStorageKey,
    defaultValue
  }: {
    recoilState: RecoilState<StateType>
    localStorageKey: string
    defaultValue: StateType | null
  }) => {
  const [state, setState] = useRecoilState(recoilState)
  const [stored, setStored] = useLocalStorage<StateType | null>(localStorageKey, defaultValue)
  const initRef = useRef(false)

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true

      if (stored) {
        setState(stored)
      }
    }
  }, [state, stored, setState])

  useEffect(() => {
    if (!isEqual(state, stored)) {
      setStored(state)
    }
  }, [state, setStored, stored])
}
