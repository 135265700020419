import React, { HTMLAttributes } from 'react'

export const SuccessCheck = ({
  size = 50,
  ...props
}: HTMLAttributes<SVGSVGElement> & { size?: number }) => {
  return (
    <svg
      aria-label="success-check"
      width={size}
      height={size}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5616_65621)">
        <path
          d="M21.437 33.125C20.9546 33.0881 20.5059 32.8638 20.187 32.5L12.687 25C12.5199 24.6501 12.4653 24.257 12.5309 23.8749C12.5965 23.4927 12.7789 23.1403 13.0531 22.8661C13.3273 22.592 13.6797 22.4095 14.0618 22.344C14.444 22.2784 14.8371 22.3329 15.187 22.5L21.362 28.675L42.687 7.50002C43.0368 7.33291 43.4299 7.27839 43.8121 7.34396C44.1942 7.40953 44.5467 7.59196 44.8209 7.86613C45.095 8.14031 45.2775 8.49274 45.343 8.87489C45.4086 9.25705 45.3541 9.65013 45.187 10L22.687 32.5C22.368 32.8638 21.9193 33.0881 21.437 33.125Z"
          fill="#218242"
        />
        <path
          d="M25.1878 47.5C21.1664 47.4935 17.2203 46.4095 13.7601 44.3606C10.2998 42.3117 7.45195 39.3729 5.51277 35.85C4.03873 33.225 3.12771 30.3216 2.83777 27.325C2.38022 22.9302 3.2268 18.498 5.27188 14.5812C7.31697 10.6644 10.47 7.43643 14.3378 5.3C16.9628 3.82596 19.8662 2.91494 22.8628 2.625C25.8481 2.30387 28.8676 2.59306 31.7378 3.475C31.9939 3.52618 32.2365 3.63031 32.4501 3.78073C32.6637 3.93114 32.8434 4.12452 32.9779 4.34846C33.1124 4.5724 33.1985 4.82196 33.2309 5.08115C33.2633 5.34035 33.2412 5.60345 33.166 5.85359C33.0908 6.10374 32.9641 6.3354 32.7941 6.53375C32.6242 6.73209 32.4146 6.89273 32.179 7.00537C31.9433 7.11801 31.6867 7.18016 31.4256 7.18785C31.1645 7.19554 30.9047 7.14858 30.6628 7.05C28.2343 6.3198 25.6844 6.08182 23.1628 6.35C20.6703 6.60523 18.2552 7.36207 16.0628 8.575C13.9506 9.74026 12.0831 11.3021 10.5628 13.175C8.99742 15.0829 7.82851 17.284 7.12449 19.6493C6.42047 22.0146 6.19551 24.4966 6.46277 26.95C6.718 29.4425 7.47484 31.8576 8.68777 34.05C9.85303 36.1622 11.4149 38.0296 13.2878 39.55C15.1957 41.1154 17.3967 42.2843 19.7621 42.9883C22.1274 43.6923 24.6094 43.9173 27.0628 43.65C29.5553 43.3948 31.9704 42.6379 34.1628 41.425C36.2749 40.2598 38.1424 38.6979 39.6628 36.825C41.2281 34.9171 42.397 32.716 43.1011 30.3507C43.8051 27.9854 44.03 25.5034 43.7628 23.05C43.713 22.5295 43.8721 22.0106 44.205 21.6074C44.5379 21.2042 45.0173 20.9497 45.5378 20.9C46.0583 20.8503 46.5772 21.0093 46.9804 21.3422C47.3836 21.6751 47.638 22.1545 47.6878 22.675C48.143 27.0724 47.2927 31.5065 45.243 35.4236C43.1933 39.3406 40.0351 42.5671 36.1628 44.7C33.5098 46.2344 30.5616 47.1887 27.5128 47.5H25.1878Z"
          fill="#218242"
        />
      </g>
      <defs>
        <clipPath id="clip0_5616_65621">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
