import { SVGAttributes } from 'react'

export const AutoLoad = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1212 1.00002L12.1255 1C15.0429 1.00001 17.8408 2.15893 19.9037 4.22183C21.9666 6.28473 23.1255 9.08262 23.1255 12C23.1255 12.6328 22.6125 13.1458 21.9796 13.1458C21.3468 13.1458 20.8338 12.6328 20.8338 12C20.8338 9.69041 19.9163 7.47541 18.2832 5.84228C16.6506 4.20966 14.4365 3.29224 12.1276 3.29167C9.67241 3.30146 7.3158 4.25852 5.549 5.96315L3.08155 8.4306C2.63407 8.87808 1.90857 8.87808 1.46109 8.4306C1.01362 7.98313 1.01362 7.25763 1.46109 6.81015L3.93559 4.33566L3.94941 4.32207C6.14265 2.2018 9.07065 1.01149 12.1212 1.00002Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27132 1C2.90415 1 3.41715 1.51301 3.41715 2.14583V6.47453H7.74586C8.37868 6.47453 8.89169 6.98754 8.89169 7.62037C8.89169 8.25319 8.37868 8.7662 7.74586 8.7662H2.27132C1.6385 8.7662 1.12549 8.25319 1.12549 7.62037V2.14583C1.12549 1.51301 1.6385 1 2.27132 1ZM2.27132 10.8542C2.90415 10.8542 3.41715 11.3672 3.41715 12C3.41715 14.3096 4.33464 16.5246 5.96776 18.1577C7.60032 19.7903 9.81435 20.7077 12.1231 20.7083C14.5784 20.6986 16.9351 19.7415 18.702 18.0368L21.1694 15.5694C21.6169 15.1219 22.3424 15.1219 22.7899 15.5694C23.2373 16.0169 23.2373 16.7424 22.7899 17.1898L20.3154 19.6643L20.3016 19.6779C18.1083 21.7982 15.1803 22.9885 12.1298 23L12.1255 23C9.2081 23 6.41021 21.8411 4.34731 19.7782C2.28441 17.7153 1.12549 14.9174 1.12549 12C1.12549 11.3672 1.6385 10.8542 2.27132 10.8542Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3594 16.3797C15.3594 15.7469 15.8724 15.2339 16.5052 15.2339H21.9797C22.6126 15.2339 23.1256 15.7469 23.1256 16.3797V21.8543C23.1256 22.4871 22.6126 23.0001 21.9797 23.0001C21.3469 23.0001 20.8339 22.4871 20.8339 21.8543V17.5256H16.5052C15.8724 17.5256 15.3594 17.0125 15.3594 16.3797Z"
        fill="#2E2E2E"
      />
    </svg>
  )
}
