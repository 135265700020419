import { Body2 } from 'design-system'
import Image from 'next/image'
import { ImageWrapper, Page } from './Common'

export const OrderLoader = () => (
  <Page>
    <ImageWrapper>
      <Image alt="" src="/images/beans-brewing.gif" width={136} height={173} />
    </ImageWrapper>
    <Body2>Your beans are brewing</Body2>
  </Page>
)
