import { createContext, ReactElement } from 'react'

export type Values = {
  closeModal: (modalId?: string) => void
  isTopModal: (modalId: string) => boolean
  openModal: (modal: ReactElement, id?: string) => string
  closeAllModals: () => void
}

export const ModalContext = createContext<Values>({
  closeModal: () => null,
  isTopModal: () => false,
  openModal: () => '',
  closeAllModals: () => null,
})
