import React, { FC, SVGAttributes } from 'react'
import { CSS } from '@stitches/react'
import { styled } from 'styles/stitches.config'
import { Aura, ChevronRight } from 'components/DesignSystemIcons'
import Tag from 'components/Tag'

const Card = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$s 0',
  color: '$grey600',
})

const Details = styled('div', {
  alignItems: 'center',
  color: '$grey1000',
  display: 'flex',
  gap: '$m',
})

const Title = styled('h3', {
  fontSize: '$body1',
  fontWeight: '$semiBold',
  lineHeight: '1.6',
})

const Description = styled('div', {
  color: '$grey600',
  fontSize: '$body2',
  lineHeight: '1.6',
  fontWeight: '$normal',
})

const DescriptionContainer = styled('div', {
  display: 'flex',
  gap: '$xxs',
  alignItems: 'center',
  marginTop: '$xt',
})

const IconAura = styled(Aura, {
  backgroundColor: '$grey100',
  borderRadius: '$xs',
  height: 44,
  width: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type ItemCardProps = {
  title: React.ReactNode
  description: React.ReactNode
  icon: FC<
    SVGAttributes<SVGSVGElement> & {
      size?: number | undefined
    }
  >
  hideNextIcon?: boolean
  overrideIcon?: React.ReactNode
  css?: CSS
  tag?: string
  callToAction?: React.ReactNode
  onClick?: () => void
}

const ItemCard = ({
  description,
  icon: Icon,
  title,
  hideNextIcon = false,
  overrideIcon,
  css,
  tag,
  callToAction,
  onClick,
}: ItemCardProps) => {
  const icon = overrideIcon ?? (
    <IconAura>
      <Icon size={20} />
    </IconAura>
  )

  return (
    <Card css={css} onClick={onClick}>
      <Details>
        {icon}
        <div>
          <Title>{title}</Title>
          <DescriptionContainer>
            <Description>{description}</Description>
            {tag && <Tag variant="error" size="small" label={tag} />}
          </DescriptionContainer>
        </div>
      </Details>
      {callToAction}
      {!hideNextIcon && <ChevronRight size={16} />}
    </Card>
  )
}

export default ItemCard
