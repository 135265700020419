import { LocalStorageKeys } from 'config/localStorageKeys'
import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import { useLocalStorage } from './useLocalStorage'
import useRateOrder from 'hooks/useRateOrder'
import { differenceInHours } from 'date-fns'
import { useRouter } from 'next/router'
import { api } from 'config/apiClient'
import useProfile from './useProfile'

type LastOrder = {
  orderId: string
  storeName: string
  ts: string
  isCancelledOrRejected?: boolean
}

const useRateOrderReminder = () => {
  const router = useRouter()
  const [lastOrder, setLastOrder] = useLocalStorage(LocalStorageKeys.LAST_ORDER, {} as LastOrder)
  const { openRateOrderModal } = useRateOrder({ ...lastOrder, rated: null, shouldClearRateOrderReminder: true })
  const openRateOrderModalRef = useRef(false)
  const [profile] = useProfile()

  const setRateOrderReminder = (order: LastOrder) => {
    setLastOrder(order)
  }

  useEffect(() => {
    const onLandingPage = router.pathname.match(/^\/lp/)

    const canShowRateOrderModal =
      !onLandingPage &&
      !openRateOrderModalRef.current &&
      !!profile &&
      !isEmpty(lastOrder) &&
      !!lastOrder.orderId &&
      differenceInHours(new Date(), new Date(lastOrder.ts)) >= 1

    if (!canShowRateOrderModal) {
      return
    }

    openRateOrderModalRef.current = true

    api('getOrderStatus', {
      orderId: lastOrder.orderId
    }).then(({ data: { status }, success }) => {
      if (success) {
        const isRejectedOrCancelledOrder = status === 'Cancelled' || status === 'Rejected'

        if (!isRejectedOrCancelledOrder) {
          return openRateOrderModal(0)
        }

        setLastOrder({} as LastOrder)
      }
    })
  }, [lastOrder, openRateOrderModal, router.pathname, setLastOrder, profile])

  return {
    setRateOrderReminder
  }
}

export default useRateOrderReminder
