import { bookmarkState } from 'config/states'
import { useRecoilState, SetterOrUpdater } from 'recoil'

type Bookmark = {
  name: string
  url: string
} | null

const useBookmark = () => {
  const [bookmark, setBookmark] = useRecoilState(bookmarkState)

  return [bookmark, setBookmark] as [Bookmark, SetterOrUpdater<Bookmark>]
}

export default useBookmark
