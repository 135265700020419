import { isFriday, isWednesday, isMonday, isToday } from 'date-fns'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { rewardIndicatorState } from 'config/states'
import { useLocalStorage } from './useLocalStorage'

export const useCheckIfHaveOfferToday = () => {
  const [lastNotifyOffer, setLastNotifyOffer] = useLocalStorage<string | null>(LocalStorageKeys.LAST_NOTIFY_OFFER, null)
  const [, setRewardIndicator] = useRecoilState(rewardIndicatorState)

  useEffect(() => {
    const now = new Date()

    const haveOfferToday =
      isMonday(now) ||
      isWednesday(now) ||
      isFriday(now)

    const shownOfferNotificationToday = !!lastNotifyOffer ? isToday(new Date(lastNotifyOffer)) : false

    if (!shownOfferNotificationToday && haveOfferToday) {
      setRewardIndicator(true)
      setLastNotifyOffer(now.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
