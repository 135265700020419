import { SVGAttributes } from 'react'

export const Chat = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 0,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 17" fill="none" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
    <path d="M12 1.1665H4.00002C2.16202 1.1665 0.666687 2.66184 0.666687 4.49984V9.83318C0.666687 11.4425 1.81402 12.7892 3.33336 13.0992V15.1665C3.33336 15.4125 3.46869 15.6385 3.68536 15.7545C3.78402 15.8072 3.89202 15.8332 4.00002 15.8332C4.12936 15.8332 4.25802 15.7952 4.37002 15.7212L8.20203 13.1665H12C13.838 13.1665 15.3334 11.6712 15.3334 9.83318V4.49984C15.3334 2.66184 13.838 1.1665 12 1.1665ZM7.63003 11.9452L4.66669 13.9205V12.4998C4.66669 12.1318 4.36802 11.8332 4.00002 11.8332C2.89736 11.8332 2.00002 10.9358 2.00002 9.83318V4.49984C2.00002 3.39717 2.89736 2.49984 4.00002 2.49984H12C13.1027 2.49984 14 3.39717 14 4.49984V9.83318C14 10.9358 13.1027 11.8332 12 11.8332H8.00003C7.95336 11.8325 7.88669 11.8372 7.81069 11.8598C7.73069 11.8832 7.67003 11.9178 7.63003 11.9452Z" fill="currentColor" />
    <path d="M12 5.1665H4.00004C3.63204 5.1665 3.33337 5.46517 3.33337 5.83317C3.33337 6.20117 3.63204 6.49983 4.00004 6.49983H12C12.368 6.49983 12.6667 6.20117 12.6667 5.83317C12.6667 5.46517 12.368 5.1665 12 5.1665Z" fill="currentColor" />
    <path d="M10.6667 7.83301H5.33335C4.96535 7.83301 4.66669 8.13167 4.66669 8.49967C4.66669 8.86767 4.96535 9.16634 5.33335 9.16634H10.6667C11.0347 9.16634 11.3334 8.86767 11.3334 8.49967C11.3334 8.13167 11.0347 7.83301 10.6667 7.83301Z" fill="currentColor" />
  </svg>
)
