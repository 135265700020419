import { loadStripe } from '@stripe/stripe-js'
import { UserPaymentMethod } from 'types/user'

export const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
)

export const removeJoeBucks = (paymentMethods: UserPaymentMethod[]) =>
  paymentMethods.filter(({ type }) => type !== 'joebucks')

export const removeBankAccount = (paymentMethods: UserPaymentMethod[]) =>
  paymentMethods.filter(({ type }) => type !== 'bank')
