import { LocalStorageKeys } from 'config/localStorageKeys'
import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from './useLocalStorage'

export type ChannelPayload = {
  data: Record<string, unknown>
  event: 'geo-request' | 'payment' | 'ping' | 'share'
}

const useChannel = () => {
  const [eventPayload, setEventPayload] = useState('')
  const [shared, setShared] = useState(false)
  const [nativeGeolocation, setNativeGeolocation] = useState<GeolocationPosition | null>(null)
  const [, setRequestReviewed] = useLocalStorage(LocalStorageKeys.REQUEST_REVIEWED, 'false')

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== 'undefined' && window.WebViewChannel) {
      const channelListener = (e: CustomEvent) => {
        setEventPayload(e.detail)
      }

      const geoRequestListener = (e: CustomEvent) => {
        setNativeGeolocation(e.detail)
      }

      const shareListener = (e: CustomEvent) => {
        /**
         * We cannot tell whether share was performed or canceled;
         * response is always:
         *
         * { success: true }
         */
        setShared(e.detail.success)
      }

      const reviewRequestListener = () => {
        setRequestReviewed('true')
      }

      window.addEventListener('geo-request', geoRequestListener as EventListener)
      window.addEventListener('ping', channelListener as EventListener)
      window.addEventListener('share', shareListener as EventListener)
      window.addEventListener('review-request', reviewRequestListener as EventListener)

      return () => {
        window.removeEventListener('geo-request', geoRequestListener as EventListener)
        window.removeEventListener('ping', channelListener as EventListener)
        window.removeEventListener('share', shareListener as EventListener)
        window.removeEventListener('review-request', reviewRequestListener as EventListener)
      }
    }
  })

  const sendPayload = useCallback((payload: unknown) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.WebViewChannel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.WebViewChannel.postMessage(payload)
    }
  }, [])

  const transmitGeoRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {
      },
      event: 'geo-request',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitReviewRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {
      },
      event: 'review-request',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitPayment = useCallback((data: { amount: number }, onReceivePaymentMethodId: (paymentMethodId: string) => void) => {
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== 'undefined' && window.WebViewChannel) {
      const paymentListener = (e: CustomEvent) => {
        onReceivePaymentMethodId(e.detail.paymentMethodId)
      }

      window.addEventListener('payment', paymentListener as EventListener, {
        once: true
      })
    }

    const payload = JSON.stringify({
      detail: {
        amount: data.amount,
      },
      event: 'payment',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitPing = useCallback(() => {
    const payload = JSON.stringify({
      detail: {
        foo: 'bar',
      },
      event: 'ping',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitShare = useCallback((data: Record<string, unknown>) => {
    const payload = JSON.stringify({
      detail: {
        ...data,
      },
      event: 'share',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitUser = useCallback((data: { phone: string, userId: string }) => {
    const payload = JSON.stringify({
      detail: {
        ...data,
      },
      event: 'user',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitPushNotificationRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {},
      event: 'push-notification-request'
    })

    sendPayload(payload)
  }, [sendPayload])

  return {
    eventPayload,
    nativeGeolocation,
    shared,
    transmitGeoRequest,
    transmitPayment,
    transmitPing,
    transmitShare,
    transmitUser,
    transmitReviewRequest,
    transmitPushNotificationRequest,
  }
}

export default useChannel
