import { SVGAttributes } from 'react'

export const Plus = ({
  size = 24,
  ...props
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M20.4172 10.2284H14.2884C14.0893 10.2284 13.9279 10.067 13.9279 9.86792V3.73911C13.9279 2.74364 13.1207 1.93652 12.1253 1.93652C11.1298 1.93652 10.3227 2.74364 10.3227 3.73911V9.86792C10.3227 10.067 10.1613 10.2284 9.96216 10.2284H3.83335C2.83788 10.2284 2.03076 11.0356 2.03076 12.031C2.03076 13.0265 2.83788 13.8336 3.83335 13.8336H9.96216C10.1613 13.8336 10.3227 13.995 10.3227 14.1941V20.323C10.3227 21.3184 11.1298 22.1255 12.1253 22.1255C13.1207 22.1255 13.9279 21.3184 13.9279 20.323V14.1941C13.9279 13.995 14.0893 13.8336 14.2884 13.8336H20.4172C21.4127 13.8336 22.2198 13.0265 22.2198 12.031C22.2198 11.0356 21.4127 10.2284 20.4172 10.2284Z"
      fill="currentColor"
    />
  </svg>
)
