import { SVGAttributes } from 'react'

export const AddFunds = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11263_98752)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6162 0.366117C12.1043 -0.122039 12.8958 -0.122039 13.3839 0.366117L24.1339 11.1161C24.4914 11.4736 24.5984 12.0113 24.4049 12.4784C24.2114 12.9454 23.7556 13.25 23.25 13.25H18.3572V16.6071C18.3572 17.2975 17.7975 17.8571 17.1072 17.8571H7.89289C7.20254 17.8571 6.64289 17.2975 6.64289 16.6071V13.25H1.75004C1.24446 13.25 0.788663 12.9454 0.595187 12.4784C0.401711 12.0113 0.508656 11.4736 0.866153 11.1161L11.6162 0.366117ZM4.7678 10.75H7.89289C8.58325 10.75 9.14289 11.3096 9.14289 12V15.3571H15.8572V12C15.8572 11.3096 16.4168 10.75 17.1072 10.75H20.2323L12.5 3.01777L4.7678 10.75ZM6.64289 22.75C6.64289 22.0596 7.20254 21.5 7.89289 21.5H17.1072C17.7975 21.5 18.3572 22.0596 18.3572 22.75C18.3572 23.4404 17.7975 24 17.1072 24H7.89289C7.20254 24 6.64289 23.4404 6.64289 22.75Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_11263_98752">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
