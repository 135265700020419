import { animated, useTransition } from '@react-spring/web'
import { Modal } from 'components/ModalKit'
import { Button } from 'design-system/buttons'
import { styled } from 'styles/stitches.config'
import * as Icon from 'components/DesignSystemIcons'
import Router from 'next/router'

const ModalMain = styled(Modal.Main, {
  textAlign: 'center'
})

interface Props {
  open: boolean
}

const BlockModal = ({ open }: Props) => {
  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  })

  const onClickReload = () => {
    Router.reload()
  }

  return (
    <Modal.Content hAlign="center" vAlign="center" css={{ height: '100%', margin: 'auto' }}>
      {transitions((styles, item) => item && (
        <animated.div style={styles} data-testid="block-modal">
          <Modal.Card>
            <ModalMain>
              Please enable local storage to use the app!
            </ModalMain>
            <Modal.Footer>
              <Button
                cta
                size="large"
                bold
                onClick={onClickReload}
                data-testid="reload-button"
              >
                <Icon.Inliner css={{ marginRight: '$xs' }}>
                  <Icon.Reload />
                </Icon.Inliner>
                Reload
              </Button>
            </Modal.Footer>
          </Modal.Card>
        </animated.div>
      ))}
    </Modal.Content>
  )
}

export default BlockModal
