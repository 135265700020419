import { SVGAttributes } from 'react'

export const ItemPoint = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 2,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg viewBox="0 0 20 20" width={size} height={size} fill="none" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3118 17.5C13.9937 17.5 16.9785 14.5152 16.9785 10.8333C16.9785 7.15143 13.9937 4.16667 10.3118 4.16667C6.62995 4.16667 3.64518 7.15143 3.64518 10.8333C3.64518 14.5152 6.62995 17.5 10.3118 17.5ZM10.3118 19.1667C14.9142 19.1667 18.6452 15.4357 18.6452 10.8333C18.6452 6.23096 14.9142 2.5 10.3118 2.5C5.70948 2.5 1.97852 6.23096 1.97852 10.8333C1.97852 15.4357 5.70948 19.1667 10.3118 19.1667Z" fill="currentColor" />
    <g clipPath="url(#clip0_4066_14310)">
      <path d="M7.93122 15.7141H11.5027C12.4885 15.7141 13.2884 14.9143 13.2884 13.9284H13.8836C15.1968 13.9284 16.2646 12.8607 16.2646 11.5475C16.2646 10.2342 15.1968 9.1665 13.8836 9.1665H6.59194C6.34454 9.1665 6.14551 9.36554 6.14551 9.61293V13.9284C6.14551 14.9143 6.94536 15.7141 7.93122 15.7141ZM13.8836 10.357C14.5402 10.357 15.0741 10.8908 15.0741 11.5475C15.0741 12.2041 14.5402 12.7379 13.8836 12.7379H13.2884V10.357H13.8836Z" fill="currentColor" />
      <rect x="6.97949" y="5.8335" width="0.833333" height="2.5" rx="0.416667" fill="currentColor" />
      <rect x="9.47949" y="5.8335" width="0.833333" height="2.5" rx="0.416667" fill="currentColor" />
      <rect x="11.9795" y="5.8335" width="0.833333" height="2.5" rx="0.416667" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_4066_14310">
        <rect width="11.6667" height="11.6667" fill="white" transform="translate(5.3125 5)" />
      </clipPath>
    </defs>
  </svg>
)
