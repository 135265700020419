import { SVGAttributes } from 'react'

export const Bank = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 2,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1749_18744)">
      <path d="M1.82537 24H23.4254C23.7436 24 24.0489 23.8736 24.2739 23.6485C24.4989 23.4235 24.6254 23.1183 24.6254 22.8V18C24.6254 17.6818 24.4989 17.3765 24.2739 17.1515C24.0489 16.9264 23.7436 16.8 23.4254 16.8H22.2254V10.8H23.4254C23.7436 10.8 24.0489 10.6736 24.2739 10.4485C24.4989 10.2235 24.6254 9.91828 24.6254 9.60002V6.00002C24.6254 5.76784 24.5581 5.54064 24.4316 5.34596C24.305 5.15128 24.1248 4.99749 23.9126 4.90322L13.1126 0.10323C12.9592 0.0350869 12.7932 -0.00012207 12.6254 -0.00012207C12.4575 -0.00012207 12.2916 0.0350869 12.1382 0.10323L1.33817 4.90322C1.12598 4.99749 0.945704 5.15128 0.819184 5.34596C0.692663 5.54064 0.625337 5.76784 0.625366 6.00002V9.60002C0.625366 9.91828 0.751794 10.2235 0.976838 10.4485C1.20188 10.6736 1.50711 10.8 1.82537 10.8H3.02537V16.8H1.82537C1.50711 16.8 1.20188 16.9264 0.976838 17.1515C0.751794 17.3765 0.625366 17.6818 0.625366 18V22.8C0.625366 23.1183 0.751794 23.4235 0.976838 23.6485C1.20188 23.8736 1.50711 24 1.82537 24ZM3.02537 6.78002L12.6254 2.51283L22.2254 6.78002V8.40002H3.02537V6.78002ZM19.8254 10.8V16.8H16.625V10.8H19.8254ZM14.225 10.8V16.8H11.0258V10.8H14.225ZM8.62577 10.8V16.8H5.42537V10.8H8.62577ZM3.02537 19.2H22.2254V21.6H3.02537V19.2Z" fill="currentColor" />
      <path d="M13.6254 6H11.6254C11.3602 6 11.1058 6.10536 10.9183 6.29289C10.7307 6.48043 10.6254 6.73478 10.6254 7C10.6254 7.26521 10.7307 7.51957 10.9183 7.7071C11.1058 7.89464 11.3602 8 11.6254 8H13.6254C13.8906 8 14.1449 7.89464 14.3325 7.7071C14.52 7.51957 14.6254 7.26521 14.6254 7C14.6254 6.73478 14.52 6.48043 14.3325 6.29289C14.1449 6.10536 13.8906 6 13.6254 6Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1749_18744">
        <rect width="24" height="24" fill="white" transform="translate(0.625366)" />
      </clipPath>
    </defs>
  </svg>
)
