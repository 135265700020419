import { SVGAttributes } from 'react'

export const Check = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M20.4021 4.75899C19.914 4.774 19.4508 4.97887 19.1109 5.33042L9.54339 14.9204L4.94852 11.3569C4.41658 10.9469 3.70726 10.8522 3.08682 11.1081C2.46658 11.364 2.02936 11.9319 1.93933 12.5984C1.84949 13.2648 2.12069 13.9285 2.65109 14.3405L8.5541 18.9126C8.91552 19.1914 9.36582 19.3291 9.82086 19.2996C10.276 19.2704 10.705 19.076 11.0277 18.7529L21.7605 7.99494C22.1256 7.64093 22.3323 7.15389 22.3334 6.64476C22.3343 6.13558 22.1295 5.64782 21.7657 5.29252C21.4018 4.9372 20.9098 4.74473 20.402 4.75918L20.4021 4.75899Z"
      fill="currentColor"
    />
  </svg>
)
