import { SVGAttributes } from 'react'

export const Menu = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.3667 0H3.7667V24H1.3667V0ZM9.7667 4.8H18.1667V7.2H9.7667V4.8ZM9.7667 9.6H18.1667V12H9.7667V9.6Z" fill="currentColor" />
    <path d="M20.5667 0H4.96667V24H20.5667C21.8903 24 22.9667 22.9236 22.9667 21.6V2.4C22.9667 1.0764 21.8903 0 20.5667 0ZM20.5667 21.6H7.36667V2.4H20.5667V21.6Z" fill="currentColor" />
  </svg>
)
