import { SVGAttributes } from 'react'

export const Orders = ({
  size = 24
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clipPath="url(#clip0_8298_66533)">
      <path
        d="M9.45882 20.031C6.85882 20.031 4.65304 19.1252 2.84149 17.3137C1.02993 15.5021 0.1246 13.2968 0.125489 10.6977V0.0310059H19.4588C20.7477 0.0310059 21.8477 0.486562 22.7588 1.39767C23.6699 2.30878 24.1255 3.40878 24.1255 4.69767C24.1255 5.98656 23.6699 7.08656 22.7588 7.99767C21.8477 8.90878 20.7477 9.36434 19.4588 9.36434H18.7922V10.6977C18.7922 13.2977 17.8864 15.5035 16.0748 17.315C14.2633 19.1266 12.0579 20.0319 9.45882 20.031ZM2.79216 6.69767H16.1255V2.69767H2.79216V6.69767ZM9.45882 17.3643C11.3033 17.3643 12.8757 16.7141 14.1762 15.4137C15.4766 14.1132 16.1264 12.5412 16.1255 10.6977V9.36434H2.79216V10.6977C2.79216 12.5421 3.44238 14.1146 4.74282 15.415C6.04327 16.7155 7.61527 17.3652 9.45882 17.3643ZM18.7922 6.69767H19.4588C20.0144 6.69767 20.4868 6.50301 20.8762 6.11367C21.2655 5.72434 21.4597 5.25234 21.4588 4.69767C21.4588 4.14212 21.2642 3.66967 20.8748 3.28034C20.4855 2.89101 20.0135 2.69678 19.4588 2.69767H18.7922V6.69767ZM0.125489 24.031V21.3643H21.4588V24.031H0.125489Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8298_66533">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.125488 0.0310059)"
        />
      </clipPath>
    </defs>
  </svg>
)
