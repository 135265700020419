import { RecoilRoot } from 'recoil'
import { ModalProvider } from 'components/ModalKit'
import { SmartBannerProvider } from 'components/SmartBanner/Provider'
import LocalStorageChecker from 'components/LocalStorageChecker'
import { ACHFailedBottomSheetProvider } from 'components/ACHFailedBottomSheet/Provider'
import { LoaderProvider } from 'contexts'

const GlobalProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <RecoilRoot>
      <LocalStorageChecker>
        <SmartBannerProvider>
          <ModalProvider>
            <ACHFailedBottomSheetProvider>
              <LoaderProvider>{children}</LoaderProvider>
            </ACHFailedBottomSheetProvider>
          </ModalProvider>
        </SmartBannerProvider>
      </LocalStorageChecker>
    </RecoilRoot>
  )
}

export default GlobalProviders
