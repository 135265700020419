import { SVGAttributes } from 'react'

export const Person = ({
  size = 25, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 0,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 16 20" fill="none" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0331 7.46546C10.6428 6.77154 11 5.85749 11 5.00001C11 3.34315 9.65692 2 8.00001 2C6.34311 2 5.00001 3.34315 5.00001 5.00001C5.00001 5.85749 5.35724 6.77154 5.96693 7.46546C6.57221 8.15431 7.30581 8.50001 8.00001 8.50001C8.69421 8.50001 9.42782 8.15431 10.0331 7.46546V7.46546ZM8.00001 10.5C10.7614 10.5 13 7.76143 13 5.00001C13 2.23858 10.7614 0 8.00001 0C5.23859 0 3 2.23858 3 5.00001C3 7.76143 5.23859 10.5 8.00001 10.5Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.00001 14C3.79087 14 2 15.7909 2 18V20H0V18C0 14.6863 2.6863 12 6.00001 12H10C13.3137 12 16 14.6863 16 18V20H14V18C14 15.7909 12.2091 14 10 14H6.00001Z" fill="currentColor" />
  </svg>
)
