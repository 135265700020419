import { styled } from 'styles/stitches.config'

export const Header = styled('header', {
  alignItems: 'center',
  backgroundColor: '$grey50',
  color: '$grey1000',
  display: 'flex',
  fontSize: '$heading',
  fontWeight: 'bold',
  margin: '0 auto',
  maxWidth: '100%',
  position: 'relative',
  top: 0,
  width: '$applicationWidth',
  zIndex: 100,

  variants: {
    border: {
      true: {
        borderBottom: '1px solid $grey150',
      },
    },
    noShadow: {
      true: {
        boxShadow: 'none',
      },
    },
  },
})

export const Main = styled('main', {
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
})

export const Section = styled(
  'section',
  {
    backgroundColor: '$grey50',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingBottom: '$navHeight',
    position: 'relative',
    transform: 'none',
    width: '100%',
    zIndex: '$0',

    variants: {
      disableNav: {
        true: {
          paddingBottom: 0,
        },
      },
      header: {
        standard: {
          [`& > ${Header}`]: {
            height: '$headerHeight',
            position: 'fixed',
          },
          [`& > ${Main}`]: {
            marginTop: '$headerHeight',
          },
        },
        tall: {
          [`& > ${Header}`]: {
            height: '$tallHeaderHeight',
            position: 'fixed',
          },
          [`& > ${Main}`]: {
            marginTop: '$tallHeaderHeight',
          },
        },
      },
    },
  },
  { minHeight: '100dvh' }
)
