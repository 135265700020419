import { StandardPage } from 'components/Layout'
import { useEffect, useState } from 'react'
import { canUseLocalStorage } from 'utils/browserStorage'
import BlockModal from './Modal'

type Props = {
  children?: React.ReactNode
}

const LocalStorageChecker = ({ children }: Props) => {
  const [isShowingBlockModal, setIsShowingBlockModal] = useState(false)
  const [isShowingContent, setIsShowingContent] = useState(false)

  useEffect(() => {
    const canUse = canUseLocalStorage()

    setIsShowingBlockModal(!canUse)
    setIsShowingContent(canUse)
  }, [])

  if (isShowingBlockModal) {
    return (
      <StandardPage
        disableNav
        overrideMainCSS={{ padding: '$s', height: '100%', justifyContent: 'center' }}
      >
        <BlockModal open={isShowingBlockModal} />
      </StandardPage>

    )
  }

  if (isShowingContent) {
    return (
      <>
        {children}
      </>
    )
  }

  return null
}

export default LocalStorageChecker
