import { Body2 } from 'design-system'
import { styled } from 'styles/stitches.config'

export const Page = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: '999',
  color: '$grey800',
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '$grey50',

  [`& ${Body2}`]: {
    maxWidth: '12.5rem',
  },
})

export const ImageWrapper = styled('div', {
  width: '136px',
})
