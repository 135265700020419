import { useContext, useEffect } from 'react'
import { SmartBannerContext } from 'components/SmartBanner/Context'
import { LocalStorageKeys, SessionStorageKeys } from 'config/localStorageKeys'
import { useRouter } from 'next/router'
import { getLocalStorage } from 'utils/browserStorage'
import { useSessionStorage } from './useSessionStorage'

function getDeviceFromLocalStorage() {
  const deviceFromLocalStorage = getLocalStorage(LocalStorageKeys.DEVICE)

  if (deviceFromLocalStorage) {
    try {
      return JSON.parse(deviceFromLocalStorage)
    } catch (error) {
      return deviceFromLocalStorage
    }
  }
}

export const useShowSmartBanner = () => {
  const { openModal } = useContext(SmartBannerContext)
  const router = useRouter()

  const [showedBanner, setShowedBanner] = useSessionStorage(
    SessionStorageKeys.SHOW_SMART_BANNER,
    'false'
  )

  useEffect(() => {
    // not showing on launch page
    if (router.pathname === '/') {
      return
    }

    // not showing on permission page
    if (router.pathname === '/explore/location-permissions') {
      return
    }

    // not showing on landing pages
    if (router.pathname.match('/lp')) {
      return
    }

    const device = getDeviceFromLocalStorage()
    const isWeb = device === 'mobile-web' || device === 'desktop'

    if (!isWeb) {
      return
    }

    if (showedBanner === 'true') {
      return
    }

    setShowedBanner('true')
    openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])
}
