import { animated, useTransition } from '@react-spring/web'
import { styled } from 'styles/stitches.config'
import { Button, H4 } from 'design-system'
import JoeLogo from 'components/JoeLogo'
import * as Icon from 'components/DesignSystemIcons'
import { Modal } from 'components/ModalKit'
import { useContext } from 'react'
import { SmartBannerContext } from './Context'
import useExternalLink from 'hooks/useExternalLink'
import useDevice from 'hooks/useDevice'

const Content = styled(Modal.Content, {
  bottom: 0,
  width: '100%',
  margin: 'auto',
  position: 'fixed',
})

const Container = styled('div', {
  boxShadow: '0px -8px 8px rgba(0, 0, 0, 0.04)',
  padding: '2.5rem $m $xxl',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Rating = styled('div', {
  color: '#F1A33B'
})

const Description = styled('div', {
  fontSize: '$body1',
  lineHeight: '$body1',
  color: '$grey1000',
  marginBottom: '$xxl',
  textAlign: 'center',

  [`& ${H4}`]: {
    color: 'red',
    display: 'inline-block'
  }
})

type Props = {
  open: boolean
}

const SmartBannerBottomModal = ({ open }: Props) => {
  const externalProtocol = useExternalLink()

  const { closeModal, journeyStep } = useContext(SmartBannerContext)

  const { isMobileWeb } = useDevice()

  const handleDownload = () => {
    window.location.href = `${externalProtocol}https://get.joe.coffee/`
    closeModal()
  }

  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  })

  return (
    <Content>
      {transitions((styles, item) => item && (
        <animated.div style={styles} data-testid="smart-banner-modal">
          <Modal.Card>
            <Container>
              <JoeLogo size={52} css={{ marginBottom: '$xs' }} />
              <H4 css={{ marginBottom: '$xxs' }}>Joe Coffee Order Ahead</H4>
              <Rating css={{ marginBottom: '$s' }}>
                <Icon.RatingStar />
                <Icon.RatingStar />
                <Icon.RatingStar />
                <Icon.RatingStar />
                <Icon.RatingStarHalf />
              </Rating>
              <Description>
                {journeyStep === 'app' ? <>Earn <H4>2x the points</H4> on every purchase when you order on the app.</> : <>Enjoy <H4>$3 off</H4> when you order through the app.</>}
              </Description>
              {isMobileWeb && <Button
                size="large"
                cta
                bold
                onClick={handleDownload}
                data-testid="install-button"
              >Install</Button>}
            </Container>
            <Modal.CloseButton handleClose={closeModal} />
          </Modal.Card>
        </animated.div>
      ))}
    </Content>
  )
}

export default SmartBannerBottomModal
