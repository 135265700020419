import { SVGAttributes } from 'react'

export const Info = ({
  size = 24,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_9434_57954)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1255 2.4C6.82355 2.4 2.52549 6.69807 2.52549 12C2.52549 17.3019 6.82355 21.6 12.1255 21.6C17.4274 21.6 21.7255 17.3019 21.7255 12C21.7255 6.69807 17.4274 2.4 12.1255 2.4ZM0.125488 12C0.125488 5.37258 5.49807 0 12.1255 0C18.7529 0 24.1255 5.37258 24.1255 12C24.1255 18.6274 18.7529 24 12.1255 24C5.49807 24 0.125488 18.6274 0.125488 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9253 7.67998C10.9253 7.01724 11.4626 6.47998 12.1253 6.47998H12.1361C12.7988 6.47998 13.3361 7.01724 13.3361 7.67998C13.3361 8.34272 12.7988 8.87998 12.1361 8.87998H12.1253C11.4626 8.87998 10.9253 8.34272 10.9253 7.67998ZM12.1253 10.8C12.788 10.8 13.3253 11.3372 13.3253 12V16.32C13.3253 16.9827 12.788 17.52 12.1253 17.52C11.4626 17.52 10.9253 16.9827 10.9253 16.32V12C10.9253 11.3372 11.4626 10.8 12.1253 10.8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9434_57954">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.125488)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
