import { styled } from 'styles/stitches.config'

export const Button = styled('button', {
  display: 'inline-block',
  fontSize: 0,

  ['&:disabled']: {
    borderColor: '$grey600',
    color: '$grey600',
  },
})
