import React, { useMemo } from 'react'
import { CSS } from '@stitches/react'
import { styled } from 'styles/stitches.config'
import {
  ApplePay,
  Bank,
  CreditCard,
  GooglePay,
  Logo,
} from 'components/DesignSystemIcons'
import ItemCard from 'components/ItemCard'
import { UserPaymentMethod } from 'types/user'

interface PaymentMethodProps extends UserPaymentMethod {
  onClickPaymentMethod?: () => void
  hideNextIcon?: boolean
  css?: CSS
  isDefault?: boolean
}

const Img = styled('img', {
  borderRadius: '$xt',
  marginRight: '$s',
  width: '36px',
})

const CompanyGiftCardIcon = () => {
  return (
    <Img
      src="/images/gift-card-small-icon.png"
      alt="company-gift-card"
      css={{ width: 44, margin: 0 }}
      data-testid="company-gift-card-icon"
    />
  )
}

export const PaymentMethod = ({
  name,
  type,
  displayName,
  paymentId,
  onClickPaymentMethod,
  css,
  hideNextIcon = false,
  isDefault = false,
}: PaymentMethodProps) => {
  const icon = useMemo(() => {
    const Icons = {
      apple: ApplePay,
      bank: Bank,
      google: GooglePay,
      joebucks: Logo,
      card: CreditCard,
    }

    return Icons[type] || Icons.card
  }, [type])

  const overrideIcon = useMemo(() => {
    if (type === 'joebucks' && paymentId !== 'joebucks') {
      return <CompanyGiftCardIcon />
    }
  }, [type, paymentId])

  return (
    <ItemCard
      css={css}
      title={name}
      description={displayName}
      icon={icon}
      overrideIcon={overrideIcon}
      onClick={onClickPaymentMethod}
      hideNextIcon={hideNextIcon}
      tag={isDefault ? 'Default' : undefined}
    />
  )
}
