import { SVGAttributes } from 'react'

export const Heart = ({
  size = 20, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 2, stroke = 'currentColor'
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 21 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4460_28747)">
      <path d="M3.0052 10.7677C1.11228 8.24378 1.74325 4.45793 4.89812 3.19599C8.053 1.93404 9.94592 4.45793 10.5769 5.71988C11.2079 4.45793 13.7318 1.93404 16.8866 3.19599C20.0415 4.45793 20.0415 8.24378 18.1486 10.7677C16.2557 13.2916 10.5769 18.3394 10.5769 18.3394C10.5769 18.3394 4.89812 13.2916 3.0052 10.7677Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
    <defs>
      <clipPath id="clip0_4460_28747">
        <rect width="20" height="20" fill="currentColor" transform="translate(0.794922 0.352051)" />
      </clipPath>
    </defs>
  </svg>
)
