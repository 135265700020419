import { UserPaymentMethod } from 'types/user'

export const APPLE_PAY_WEB: UserPaymentMethod = {
  displayName: 'Apple Pay',
  name: 'Apple Pay',
  paymentId: 'apple_pay_web',
  type: 'apple',
}

export const APPLE_PAY_NATIVE: UserPaymentMethod = {
  displayName: 'Apple Pay',
  name: 'Apple Pay',
  paymentId: 'apple_pay_native',
  type: 'apple',
}

export const GOOGLE_PAY_WEB: UserPaymentMethod = {
  displayName: 'Google Pay',
  name: 'Google Pay',
  paymentId: 'google_pay_web',
  type: 'google',
}

export const GOOGLE_PAY_NATIVE: UserPaymentMethod = {
  displayName: 'Google Pay',
  name: 'Google Pay',
  paymentId: 'google_pay_native',
  type: 'google',
}

export const THIRD_PARTY_PAYMENT_METHODS = [
  APPLE_PAY_NATIVE,
  GOOGLE_PAY_NATIVE,
  GOOGLE_PAY_WEB,
  APPLE_PAY_WEB,
]
