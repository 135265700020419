/**
 * Returns the plural version of the English word
 * @param count - The number of object
 * @param singularToPluralPair
 * @returns
 */
export const pluralize = (count: number, singularToPluralPair: string) => {
  const pair = singularToPluralPair.split('_')

  if (count === 1 || pair.length !== 2) {
    return `${count} ${pair[0]}`
  }

  return `${count} ${pair[1]}`
}

/**
 * Get date in ISO string
 * @param date A specific date to be formatted, otherwise the function uses Date.now()
 * @returns ISO date string
 */
export const formatDateTimeToISOString = (date?: Date) => {
  if (date) {
    return new Date(date).toISOString()
  }

  return new Date(Date.now()).toISOString()
}

export const base64UrlSafeEncode = (str: string) =>
  Buffer.from(str, 'utf8').toString('base64').replace('+/=', '._-')

export const base64UrlSafeDecode = (str: string) =>
  Buffer.from(str.replace('._-', '+/='), 'base64').toString('utf8')

export const removeUuids = (str: string) =>
  str.replaceAll(
    /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/gi,
    'uuid'
  )
