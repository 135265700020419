import { api } from 'config/apiClient'
import { useContext, useEffect, useState } from 'react'
import { FailedPaymentTransaction } from 'types/api/failedPayment'
import { removeBankAccount } from 'utils/stripe'
import { normalizeExternalPaymentMethod } from 'utils/paymentMethod'
import { ExternalPaymentMethod } from 'types/api'
import { UserPaymentMethod } from 'types/user'
import { ACHFailedContext } from '../Context'

export const useFetchFailedPaymentFormData = (open: boolean) => {
  const [failedPayments, setFailedPayments] = useState<
    FailedPaymentTransaction[]
  >([])

  const [paymentMethods, setPaymentMethods] = useState<UserPaymentMethod[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [total, setTotal] = useState(0)

  const { closeModal } = useContext(ACHFailedContext)

  useEffect(() => {
    if (!open) {
      setFailedPayments([])
      return
    }

    setIsFetching(true)

    Promise.all([
      api('getFailedPayments', undefined),
      api('getUserPaymentMethods', { type: 'external' }),
    ])
      .then(
        ([
          { success: getFailedPaymentSuccess, data: failedPaymentData },
          { success: getUserPaymentMethodSuccess, data: paymentMethodData },
        ]) => {
          // istanbul ignore if
          if (!getFailedPaymentSuccess) {
            return
          }

          // istanbul ignore if
          if (!getUserPaymentMethodSuccess) {
            return
          }

          const { list, totalOwed } = failedPaymentData

          if (!list.length) {
            closeModal()
            return
          }

          setFailedPayments(list)

          setPaymentMethods(
            removeBankAccount(
              (paymentMethodData.paymentMethods as ExternalPaymentMethod[]).map(
                normalizeExternalPaymentMethod
              )
            )
          )

          setTotal(parseInt(totalOwed))
        }
      )
      .finally(() => {
        setIsFetching(false)
      })
  }, [open, closeModal])

  return {
    failedPayments,
    paymentMethods,
    total,
    isFetching,
  }
}
