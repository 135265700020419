import JoeLogo from 'components/JoeLogo'
import { LoadingSvg } from 'components/LoadingSvg'
import { styled } from 'styles/stitches.config'
import type * as Stitches from '@stitches/react'
import { Spinner } from 'design-system/spans'

const Wrapper = styled('div', {
  alignItems: 'center',
  boxSizing: 'content-box',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '$s 0',
  position: 'relative',

  variants: {
    noSpace: {
      true: {
        padding: 0,

        [`& ${Spinner}`]: {
          top: 0,
        },
      },
    },
  },
})

type Props = {
  size?: number // measured in px
  css?: Stitches.CSS
}

const JoeLoader = ({
  size = 48,
  noSpace,
  css,
}: Props & Stitches.VariantProps<typeof Wrapper>) => {
  return (
    <Wrapper
      css={{
        height: size,
        width: size,
        ...css,
      }}
      data-testid="joe-loader-component"
      noSpace={noSpace}
    >
      <JoeLogo size={size * 0.6} />
      <Spinner>
        <LoadingSvg size={size} />
      </Spinner>
    </Wrapper>
  )
}

export default JoeLoader
