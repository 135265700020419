import { SVGAttributes } from 'react'

export const Shield = ({
  size = 24
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6865 0.0795886C11.9695 -0.0265295 12.2813 -0.0265295 12.5643 0.0795886L21.1643 3.30459C21.6522 3.48754 21.9754 3.95394 21.9754 4.475V12C21.9754 15.783 19.4732 18.7879 17.2485 20.7345C16.1099 21.7308 14.9772 22.5089 14.1316 23.0373C13.7076 23.3024 13.3524 23.5067 13.1003 23.6463C12.9742 23.7161 12.8737 23.7698 12.803 23.8069C12.7677 23.8254 12.7398 23.8399 12.7198 23.8501L12.6959 23.8622L12.6886 23.8659L12.6861 23.8672L12.6852 23.8677C12.6848 23.8679 12.6844 23.868 12.1254 22.75C11.5664 23.868 11.566 23.8679 11.5656 23.8677L11.5647 23.8672L11.5622 23.8659L11.5548 23.8622L11.531 23.8501C11.511 23.8399 11.4831 23.8254 11.4478 23.8069C11.3771 23.7698 11.2766 23.7161 11.1505 23.6463C10.8984 23.5067 10.5432 23.3024 10.1191 23.0373C9.27357 22.5089 8.14086 21.7308 7.00226 20.7345C4.77757 18.7879 2.27539 15.783 2.27539 12V4.475C2.27539 3.95394 2.59861 3.48754 3.08649 3.30459L11.6865 0.0795886ZM12.1254 22.75L11.5664 23.868C11.9183 24.044 12.3325 24.044 12.6844 23.868L12.1254 22.75ZM12.1254 21.326C12.3149 21.2173 12.5455 21.0806 12.8066 20.9173C13.5736 20.438 14.5909 19.738 15.6023 18.853C17.6776 17.0371 19.4754 14.667 19.4754 12V5.34125L12.1254 2.585L4.77539 5.34125V12C4.77539 14.667 6.57321 17.0371 8.64852 18.853C9.65992 19.738 10.6772 20.438 11.4441 20.9173C11.7053 21.0806 11.9359 21.2173 12.1254 21.326ZM12.1254 5.375C12.8157 5.375 13.3754 5.93464 13.3754 6.625V9.675H16.4254C17.1157 9.675 17.6754 10.2346 17.6754 10.925C17.6754 11.6154 17.1157 12.175 16.4254 12.175H13.3754V15.225C13.3754 15.9154 12.8157 16.475 12.1254 16.475C11.435 16.475 10.8754 15.9154 10.8754 15.225V12.175H7.82539C7.13503 12.175 6.57539 11.6154 6.57539 10.925C6.57539 10.2346 7.13503 9.675 7.82539 9.675H10.8754V6.625C10.8754 5.93464 11.435 5.375 12.1254 5.375Z"
      fill="#2E2E2E"
    />
  </svg>
)
