import { SVGAttributes } from 'react'

export const ChevronLeft = ({
  size = 20,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.586 19.7315C18.1842 20.3323 18.1842 21.3057 17.586 21.9065C16.9876 22.5069 16.0178 22.5069 15.4193 21.9065L6.66454 13.1184C6.06638 12.5177 6.06638 11.5443 6.66454 10.9435L15.4193 2.15541C16.0178 1.55497 16.9875 1.55497 17.586 2.15541C18.1842 2.75615 18.1842 3.72957 17.586 4.33037L9.91038 12.0352L17.586 19.7315Z"
      fill="currentColor"
    />
  </svg>
)
