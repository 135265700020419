import useDevice from './useDevice'
import { useMemo } from 'react'

const useExternalLink = () => {
  const device = useDevice()

  const externalProtocol = useMemo(() => {
    return device.isNativeApp ? 'external:' : ''
  }, [device.isNativeApp])

  return externalProtocol
}

export default useExternalLink
