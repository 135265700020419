import { SVGAttributes } from 'react'

export const GiftCard = ({
  size = 24,
  strokeLinecap = 'round',
  strokeLinejoin = 'round',
  strokeWidth = 0,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    stroke="currentColor"
    strokeWidth={strokeWidth}
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
  >
    <g clipPath="url(#clip0_5428_12269)">
      <path
        d="M3.70996 14.1604H6.17915V15.7036H3.70996V14.1604Z"
        fill="currentColor"
      />
      <path
        d="M18.9375 5.05651H17.125C17.3136 4.65901 17.3993 4.22048 17.3741 3.78123C17.349 3.34198 17.2138 2.91609 16.9811 2.54272C16.7483 2.16934 16.4255 1.86045 16.0422 1.64444C15.6589 1.42843 15.2274 1.31223 14.7875 1.30651C13.4911 1.27484 12.2281 1.71957 11.2375 2.55651C10.9561 2.80226 10.7082 3.08382 10.5 3.39401C10.2918 3.08382 10.0439 2.80226 9.7625 2.55651C8.77194 1.71957 7.50891 1.27484 6.2125 1.30651C5.77256 1.31223 5.34112 1.42843 4.95783 1.64444C4.57453 1.86045 4.25168 2.16934 4.01894 2.54272C3.7862 2.91609 3.65105 3.34198 3.6259 3.78123C3.60074 4.22048 3.6864 4.65901 3.875 5.05651H2.0625C1.6481 5.05651 1.25067 5.22113 0.957646 5.51416C0.66462 5.80719 0.5 6.20461 0.5 6.61901L0.5 17.244C0.5 17.6584 0.66462 18.0558 0.957646 18.3489C1.25067 18.6419 1.6481 18.8065 2.0625 18.8065H18.9375C19.3519 18.8065 19.7493 18.6419 20.0424 18.3489C20.3354 18.0558 20.5 17.6584 20.5 17.244V6.61901C20.5 6.20461 20.3354 5.80719 20.0424 5.51416C19.7493 5.22113 19.3519 5.05651 18.9375 5.05651ZM12.275 3.73151C12.9759 3.14996 13.8646 2.84338 14.775 2.86901C15.0568 2.86901 15.327 2.98096 15.5263 3.18021C15.7256 3.37947 15.8375 3.64972 15.8375 3.93151C15.8375 4.21331 15.7256 4.48356 15.5263 4.68281C15.327 4.88207 15.0568 4.99401 14.775 4.99401H11.425C11.5909 4.50501 11.8843 4.06917 12.275 3.73151ZM6.2125 2.86901C7.12292 2.84338 8.01156 3.14996 8.7125 3.73151C9.10135 4.06571 9.39465 4.49703 9.5625 4.98151H6.2125C5.93071 4.98151 5.66046 4.86957 5.4612 4.67031C5.26194 4.47106 5.15 4.20081 5.15 3.91901C5.15 3.63722 5.26194 3.36697 5.4612 3.16771C5.66046 2.96846 5.93071 2.85651 6.2125 2.85651V2.86901ZM2.0625 6.61901H18.9375V8.18151H2.0625V6.61901ZM2.0625 17.244V11.3065H18.9375V17.244H2.0625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5428_12269">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.5 0.147949)"
        />
      </clipPath>
    </defs>
  </svg>
)
