import { base64UrlSafeEncode, removeUuids } from './stringUtils'
import { capitalize, forEach, intersection } from 'lodash'
import HoneyBadger from '@honeybadger-io/js'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { Notice } from '@honeybadger-io/core/build/src/types'

HoneyBadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  environment: process.env.NEXT_PUBLIC_ENV,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,

  breadcrumbsEnabled: true,

  enableUncaught: true,
  enableUnhandledRejection: true,

  projectRoot: 'webpack://_N_E/./',
  // reportData: true, // uncomment to enable development errors
})

let user_data: string | null = null

try {
  if (typeof window !== 'undefined') {
    user_data = localStorage.getItem(LocalStorageKeys.USER_DATA)
  }
} catch (error) {
  user_data = '(sorry, user disabled local storage.)'
}

if (!user_data) {
  user_data = '(sorry, no window or data available.)'
}

// Set Global context
const globalContext = {
  api_url: process.env.NEXT_PUBLIC_JOE_API_URL,
  isServerSide: typeof window !== 'undefined' ? 'false' : 'true',
  url: typeof window !== 'undefined' ? window.location.href : '(sorry, no window.)',
  user_data,
}

export const constructTagsWith = (error: Error) => {
  const tags = [error?.name]

  if (typeof window !== 'undefined') {
    const pathNames = window.location.pathname.split('/').map((path) => {
      switch (path) {
        case 'filtered':
          return 'Search'
        case 'my-joe':
          return 'MyJoe'
        case 'wallet':
          return 'Payments'
        case 'add-credit-card':
          return 'Payments'
        case 'add-payment':
          return 'Payments'
        case 'link-bank-account':
          return 'Payments'
        default:
          return capitalize(path)
      }
    })

    const allTags = ['Explore', 'Map', 'Search', 'MyJoe', 'Payments', 'Menu', 'Cart', 'Checkout', 'Company', 'Account']

    tags.push(...intersection(allTags, pathNames))
  }

  return tags
}

const generateErrorFingerprint = (errorMessage: string) => {
  const msg = errorMessage || 'No error message'
  const fingerprint = base64UrlSafeEncode(removeUuids(msg))

  return fingerprint
}

HoneyBadger.setContext({
  ...globalContext,
})


HoneyBadger.beforeNotify((notice) => {
  if (notice) {
    notice.fingerprint = notice.fingerprint || generateErrorFingerprint(notice?.message)
  }

  return shouldReportError(notice)
})

const shouldReportError = (notice?: Notice) => {
  let reportError = true

  // do not generate honeybadger issues for the following error messages:
  const phrasesToIgnore = [
    '401',
    'globalThis is not defined',
    'Network Error',
    'timeout exceeded',
  ]

  forEach(phrasesToIgnore, (phrase) => {
    if (notice?.message) {
      if (notice?.message?.indexOf(phrase) >= 0) {
        reportError = false
      }
    }
  })

  if (
    notice?.message?.includes('Request failed with status code 500')
    && notice?.name?.includes('AxiosError')
    && process.env.NODE_ENV === 'production'
  ) {
    reportError = false
  }

  return reportError
}

export const logError = (
  error: Error,
  context?: Record<string, unknown>,
  tags = [''],
) => {
  const fingerprint = generateErrorFingerprint(error?.message)

  return HoneyBadger.notify(error, {
    fingerprint,
    context: { ...globalContext, ...context, fingerprint },
    tags: [...constructTagsWith(error), ...tags],
  })
}

const handleBrowserUnhandledRejection = () =>
  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault()
    notifyUnhandledPromise(event.reason, event.promise)
  })

const handleServerUnhandledRejection = () =>
  process.addListener('unhandledRejection', (reason, promise) => notifyUnhandledPromise(reason, promise))

if (typeof window !== 'undefined') {
  handleBrowserUnhandledRejection()
} else {
  handleServerUnhandledRejection()
}

const notifyUnhandledPromise = (originalError: unknown, promise: Promise<unknown>) => {
  const isError = originalError instanceof Error
  const rejectedPromiseMessage = isError ? originalError.message : originalError
  const error = new Error(`Unhandled Promise Rejection: ${rejectedPromiseMessage}`)

  if (isError) {
    error.stack = originalError.stack
  }

  logError(error, {
    promise,
  })
}

