import { SVGAttributes } from 'react'

export const Car = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 0,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 24" fill="none" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
    <path d="M17.5 11.81H2.54171C2.08147 11.81 1.70837 12.2577 1.70837 12.81V16.74C1.70837 17.2923 2.08147 17.74 2.54171 17.74H17.5C17.9603 17.74 18.3334 17.2923 18.3334 16.74V12.81C18.3334 12.2577 17.9603 11.81 17.5 11.81Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M16.0083 11.81H4.03331C3.95011 11.8154 3.86684 11.801 3.78828 11.7677C3.70971 11.7344 3.63739 11.6829 3.57546 11.616C3.51353 11.5491 3.46321 11.4682 3.42738 11.378C3.39154 11.2877 3.3709 11.1898 3.36664 11.09L4.48331 4.67998C4.50018 4.48548 4.57889 4.30627 4.70305 4.17963C4.82722 4.05298 4.98729 3.98864 5.14997 3.99998H14.8166C14.8998 3.99319 14.9834 4.0062 15.0624 4.03824C15.1414 4.07028 15.2143 4.12074 15.2771 4.18671C15.3398 4.25268 15.391 4.33286 15.4278 4.42266C15.4647 4.51246 15.4863 4.6101 15.4916 4.70998L16.6666 11.09C16.658 11.2898 16.584 11.4775 16.4608 11.6123C16.3375 11.7471 16.1749 11.8181 16.0083 11.81Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M3.09998 18H5.18331V19.49C5.18331 19.6226 5.13941 19.7498 5.06127 19.8436C4.98313 19.9373 4.87715 19.99 4.76664 19.99H3.51664C3.40614 19.99 3.30015 19.9373 3.22201 19.8436C3.14387 19.7498 3.09998 19.6226 3.09998 19.49V18Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M14.7666 18H16.8583V19.49C16.8583 19.6226 16.8144 19.7498 16.7362 19.8436C16.6581 19.9373 16.5521 19.99 16.4416 19.99H15.1833C15.0728 19.99 14.9668 19.9373 14.8886 19.8436C14.8105 19.7498 14.7666 19.6226 14.7666 19.49V18Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M4.14174 15.82C4.58356 15.82 4.94174 15.3902 4.94174 14.86C4.94174 14.3298 4.58356 13.9 4.14174 13.9C3.69991 13.9 3.34174 14.3298 3.34174 14.86C3.34174 15.3902 3.69991 15.82 4.14174 15.82Z" fill="currentColor" />
    <path d="M15.8084 15.82C16.2502 15.82 16.6084 15.3902 16.6084 14.86C16.6084 14.3298 16.2502 13.9 15.8084 13.9C15.3665 13.9 15.0084 14.3298 15.0084 14.86C15.0084 15.3902 15.3665 15.82 15.8084 15.82Z" fill="currentColor" />
  </svg>
)
