import { styled } from 'styles/stitches.config'

export const TextArea = styled('textarea', {
  border: '1px solid $grey400',
  borderRadius: '$xxxs',
  color: '$grey700',
  height: '120px',
  padding: '$s',
  resize: 'none',
  width: '100%',

  '&:disabled': {
    backgroundColor: '$grey200',
    color: '$grey700',

    '&::placeholder': {
      color: '$grey600',
    },
  },

  '&:focus': {
    borderColor: '$blue700',
  },

  '&::placeholder': {
    color: '$grey600'
  },

  variants: {
    error: {
      true: {
        borderColor: '$red600',
      },
    },
  },
})
