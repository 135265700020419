import { styled } from 'styles/stitches.config'
import type * as Stitches from '@stitches/react'

const BackgroundPath = styled('path', {
  fill: '$red500',
  fillRule: 'evenodd',
})

const ForegroundPath = styled('path', {
  fill: '$white',
  fillRule: 'evenodd',
})

const Container = styled('div', {
  display: 'inline-block',
  fontSize: 0,
  lineHeight: 1,

  variants: {
    inverted: {
      true: {
        [`& ${BackgroundPath}`]: {
          fill: '$white'
        },
        [`& ${ForegroundPath}`]: {
          fill: '$red500'
        }
      }
    }
  }
})

type Props = {
  css?: Stitches.CSS
  size?: number // measured in px
  inverted?: boolean
}

export default function JoeLogo({ css, size = 48, inverted }: Props) {
  return (
    <Container
      aria-label="joe-logo"
      css={css}
      inverted={inverted}
    >
      <svg
        data-name="Joe Logo"
        height={size}
        viewBox="0 0 1080 1080"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <BackgroundPath
          d="M538.74759,1080c297.54283,0,538.74759-241.20476,538.74759-538.74759S836.29042,2.50482,538.74759,2.50482,0,243.7096,0,541.25241,241.20479,1080,538.74759,1080Z"
        />
        <ForegroundPath
          d="M829.52912,564.18359c15.84665,13.126,31.55115,26.43157,47.30354,39.67279-9.7648,14.205-25.84642,30.91512-51.91731,43.89294-35.894,17.864-76.40191,16.10411-108.59357,3.51832-17.59759-6.878-32.675-16.95706-43.18957-28.84993-29.72394-33.64928-50.43429-94.12365-10.94555-153.799C701.406,409.37149,764.93177,404.73228,807.424,418.75766c33.21229,11.03834,58.53641,34.12968,72.43163,63.14124,11.15957,23.30379-11.48132,32.58227-11.48132,32.58227L720.078,581.19454a68.97181,68.97181,0,0,0,26.18164,18.13187C778.613,611.966,812.73516,593.5139,829.52912,564.18359ZM702.90849,533.14726s-5.74068-40.21,35.35679-59.355c15.39919-7.19976,29.13427-6.55926,40.24-4.533,20.92438,3.7862,33.05067,15.83916,33.05067,15.83916Z"
        />
        <ForegroundPath
          d="M482.50234,412.57106c-76.82989,0-132.52144,52.63564-132.52144,125.05531,0,72.472,55.69155,125.05374,132.52144,125.05374,76.83138,0,132.52293-52.58176,132.52293-125.05374C614.97138,465.2067,559.27989,412.57106,482.50234,412.57106Zm0,186.91548c-35.41067,0-60.19754-25.43785-60.19754-61.91407,0-36.37,24.78687-61.86019,60.19754-61.86019,34.87491,0,60.199,26.0245,60.199,61.86019C542.70138,574.04869,517.9145,599.48654,482.50234,599.48654Z"
        />
        <ForegroundPath
          d="M326.26553,343.93759a42.386,42.386,0,1,1-42.386-42.12856A42.25791,42.25791,0,0,1,326.26553,343.93759Z"
        />
        <ForegroundPath
          d="M241.87075,780.69578s78.60026-53.06215,79.02976-158.332,0-203.76627,0-203.76627H246.37677s.16161,176.99652.16161,208.4594c0,31.41048-7.72654,61.54145-51.99064,114.17556Z"
        />
      </svg>
    </Container>
  )
}
