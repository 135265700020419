import { useLocalStorage } from 'hooks/useLocalStorage'
import { LocalStorageKeys } from 'config/localStorageKeys'
import type { ConsumerVerifyResponse } from 'types/api'
import { useCallback } from 'react'
import { pick } from 'lodash'

export type Profile = Pick<ConsumerVerifyResponse,
  'id' |
  'email' |
  'firstName' |
  'lastName' |
  'phone' |
  'shareCode' |
  'points'
>

const useProfile = () => {
  const [profile, setProfileLocalStorage] = useLocalStorage(LocalStorageKeys.USER_DATA, null as Profile | null)

  const setProfile = useCallback((verifyData: Profile) => {    
    // sanitize
    setProfileLocalStorage(pick(verifyData, [
      'id',
      'email',
      'firstName',
      'lastName',
      'phone',
      'shareCode',
      'points'
    ]))
  }, [setProfileLocalStorage])

  return [profile, setProfile] as const
}

export default useProfile
