import React from 'react'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  Controller,
} from 'react-hook-form'

/* Components */
import { Button } from 'design-system'
import * as Icon from 'components/DesignSystemIcons'
import FormTextarea from 'components/Form/FormTextarea'

/* Hooks */
import useEventErrorHandler from 'hooks/useEventErrorHandler'
import useNotification from 'hooks/useNotification'

/* Configs */
import { styled } from 'styles/stitches.config'
import { api } from 'config/apiClient'

const Wrapper = styled('div', {
  color: '$grey1000',
})

const Question = styled('div', {
  marginTop: '$xxl'
})

const FormLabel = styled('p', {
  marginBottom: '$s',
})

const FormFields = styled('div', {
  display: 'flex',

  '& button:first-child': {
    marginRight: '$xs',
  },
})

const StarWrapper = styled('div', {
  marginRight: '$xxs',
})

type OrderFeedbackFormProps = {
  orderId: string
  defaultRating: number
  onConfirm?: (value: number) => void
}

type Inputs = {
  feedbackOrderReady: number
  feedbackOrderCorrect: number
  rating: number
  message?: string
}

const OrderFeedbackForm = ({ orderId, defaultRating, onConfirm }: OrderFeedbackFormProps) => {
  const { handleError } = useEventErrorHandler()
  const { notifyError } = useNotification()

  const methods = useForm({
    defaultValues: {
      feedbackOrderReady: 0,
      feedbackOrderCorrect: 0,
      rating: defaultRating,
      message: '',
    },
  })


  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    // Sanitize the data
    formData.message === '' && delete formData.message

    if (formData.rating <= 0) {
      return notifyError('Please select a rating')
    }

    try {
      const response = await api('orderFeedback', {
        orderId,
        ...formData,
      })

      if (response.success) {
        onConfirm && onConfirm(formData.rating)
      } else {
        notifyError('Failed to submit feedback. Please try again')
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleChange = (onChange: (...event: unknown[]) => void, value: unknown) => async () => {
    // Change value
    onChange(value)
  }

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Controller
            name="rating"
            control={methods.control}
            render={({ field }) => (
              <Question>
                <FormLabel>
                  How would you rate your order with this shop?
                </FormLabel>
                <FormFields>
                  {[1, 2, 3, 4, 5].map((value) => {
                    return (
                      <StarWrapper
                        onClick={handleChange(field.onChange, value)}
                        key={`star-rating-${value}`}
                      >
                        <Icon.Inliner css={{ color: field.value >= value ? '$yellow600' : '$grey200' }}>
                          <Icon.Star aria-selected={value <= field.value} size={38} />
                        </Icon.Inliner>
                      </StarWrapper>
                    )
                  })}
                </FormFields>
              </Question>
            )}
          />

          <Controller
            name="feedbackOrderReady"
            control={methods.control}
            render={({ field }) => (
              <Question>
                <FormLabel>Was your order ready when you arrived?</FormLabel>
                <FormFields>
                  <Button
                    cta
                    onClick={handleChange(field.onChange, 1)}
                    type="button"
                    variant={field.value === 1 ? 'primary' : 'secondary'}
                  >
                    Yes
                  </Button>
                  <Button
                    cta
                    onClick={handleChange(field.onChange, -1)}
                    type="button"
                    variant={field.value === -1 ? 'primary' : 'secondary'}
                  >
                    No
                  </Button>
                </FormFields>
              </Question>
            )}
          />

          <Controller
            name="feedbackOrderCorrect"
            control={methods.control}
            render={({ field }) => (
              <Question>
                <FormLabel>Were you able to skip the line?</FormLabel>
                <FormFields>
                  <Button
                    cta
                    onClick={handleChange(field.onChange, 1)}
                    type="button"
                    variant={field.value === 1 ? 'primary' : 'secondary'}
                  >
                    Yes
                  </Button>
                  <Button
                    cta
                    onClick={handleChange(field.onChange, -1)}
                    type="button"
                    variant={field.value === -1 ? 'primary' : 'secondary'}
                  >
                    No
                  </Button>
                </FormFields>
              </Question>
            )}
          />

          <Question>
            <FormLabel>{'Anything else you\'d like to tell the shop?'}</FormLabel>
            <FormTextarea
              name="message"
              placeholder="Provide additional feedback here..."
            />
          </Question>

          <Button cta size="large" type="submit" css={{ marginTop: '$s' }}>
            Submit feedback
          </Button>
        </form>
      </FormProvider>
    </Wrapper>
  )
}

export default OrderFeedbackForm
