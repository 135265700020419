import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import { GlobalLoader } from 'components/pages/loading'
import { MemoryRouterEventHandlers } from 'next-router-mock/dist/useMemoryRouter'
import { debounce } from 'lodash'

type LoaderContextType = {
  showLoader: (loaderComponent: React.FC) => void
  hideLoader: () => void
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined)

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [LoaderComponent, setLoaderComponent] = useState<React.FC | null>(null)
  const router = useRouter()

  const showLoader = useCallback((loaderComponent: React.FC) => {
    setLoaderComponent(() => loaderComponent)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedShowLoader = useCallback(debounce(showLoader, 500), [
    showLoader,
  ])

  const hideLoader = useCallback(() => {
    setLoaderComponent(null)
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      if (LoaderComponent) {
        return
      }

      debouncedShowLoader(GlobalLoader)
    }

    const handleRouteComplete: MemoryRouterEventHandlers['onRouteChangeComplete'] =
      (url, { shallow }) => {
        debouncedShowLoader.cancel()

        if (!shallow) {
          hideLoader()
        }
      }

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteComplete)
    router.events.on('routeChangeError', handleRouteComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteComplete)
      router.events.off('routeChangeError', handleRouteComplete)
    }
  }, [router, debouncedShowLoader, hideLoader, LoaderComponent])

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {LoaderComponent && <LoaderComponent />}
    </LoaderContext.Provider>
  )
}

export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext)

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider')
  }

  return context
}
