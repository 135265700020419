/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { backOff } from 'exponential-backoff'
import { merge } from 'lodash'
import { v4 as uuid } from 'uuid'
import https from 'https'

export type RequestOptions = {
  cookie?: string
  headers?: { [key: string]: string }
  useNextApi?: boolean
} & AxiosRequestConfig

// NEVER EVER RE-USE AN AXIOS INSTANCES AS LONG AS WE ARE USING COOKIES
// NEXTJS WILL DO BAD BAD THINGS (SESSIONS/COOKIES WILL BE SHARED AMONGST CUSTOMERS!!!)
const createAxiosInstance = (cookie?: string) => {
  const instance = axios.create({
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    validateStatus: (status: number) => {
      /**
       * we're returning true for status 401,
       * so that the request won't throw error
       * and the return data will have `success: false` and `message: 'Unauthorized'`
       * then we can handle it without using try catch
       */
      if (status === 401) {
        return true
      }

      // istanbul ignore if
      if (status === 403) {
        return true
      }

      // default behavior
      return status >= 200 && status < 300
    }
  })

  instance.defaults.withCredentials = true

  if (cookie) {
    instance.defaults.headers.get.Cookie = cookie
    instance.defaults.headers.post.Cookie = cookie
  }

  return instance
}


/**
 * HTTP error status codes that need to retry when API calls fail to get a response
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 * @property {0} - Unknown error
 * @property {502} - Server is a gateway and gets an invalid response
 * @property {503} - Server is unavailable (Probably maintenance or overloaded)
 * @property {504} - Server is a gateway and the response took too long
 */
const ERRORS_FOR_RETRY = [0, 502, 503, 504]

export const axiosBaseUrl = process.env.NEXT_PUBLIC_JOE_API_URL
const handleRetry = (e: any, attemptNumber: number) => {
  if (ERRORS_FOR_RETRY.includes(e?.response?.status)) {
    return true
  }

  return false
}

export const get = async <ResponseType>(endpoint: string, options: RequestOptions) => {
  const mergedOptions = merge(options, { headers: { 'x-correlation-id': uuid() } })
  const httpClient = createAxiosInstance(options.cookie)

  return await backOff(() =>
    httpClient.get<never, AxiosResponse<ResponseType>>(`${axiosBaseUrl}${endpoint}`, mergedOptions), {
    retry: handleRetry,
  }
  )
}

export const post = async <RequestBodyType, ResponseType = RequestBodyType>(endpoint: string, data: any, options?: RequestOptions) => {
  const url = options?.useNextApi ? endpoint : `${axiosBaseUrl}${endpoint}`
  const mergedOptions = merge(options, { headers: { 'x-correlation-id': uuid() } })
  const httpClient = createAxiosInstance(options?.cookie)

  const res = await backOff(() =>
    httpClient.post<RequestBodyType, AxiosResponse<ResponseType>>(
      url,
      data,
      mergedOptions
    ), { retry: handleRetry, numOfAttempts: 5 }
  )

  if (res.status === 403 && (res.data as any)?.message === 'failed_ach_payments') {
    // istanbul ignore else
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('failed_payments'))
    }


    return {
      ...res,
      data: {
        success: false,
        errors: ['You have failed payment']
      }
    } as AxiosResponse<ResponseType, any>
  }

  return res
}

export const put = async <RequestBodyType, ResponseType = RequestBodyType>(endpoint: string, data: any) => {
  return await backOff(() =>
    axios.put<RequestBodyType, AxiosResponse<ResponseType>>(`${axiosBaseUrl}${endpoint}`, data, { headers: { 'x-correlation-id': uuid() } }), {
    retry: handleRetry,
  }
  )
}
