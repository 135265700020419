import { styled } from 'styles/stitches.config'

export const Header = styled('header', {
  alignItems: 'center',
  display: 'flex',
  fontSize: '$heading2',
  lineHeight: '$heading2',
  fontWeight: '$extraBold',
  maxWidth: '100%',
  minHeight: 48,
  overflow: 'hidden',
  padding: '$m',
  paddingRight: '$xv',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
