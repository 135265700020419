import React from 'react'
import { styled } from 'styles/stitches.config'
import { useMirrorCartToLocalStorage } from 'hooks/useMirrorCartToLocalStorage'
import useRateOrderReminder from 'hooks/useRateOrderReminder'
import { useMirrorRecoilStateToLocalStorage } from 'hooks/useMirrorRecoilStateToLocalStorage'
import { rewardIndicatorState } from 'config/states'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { useCheckIfHaveOfferToday } from 'hooks/useCheckIfHaveOfferToday'
import { useShowSmartBanner } from 'hooks/useShowSmartBanner'
import { useSaveToBookMark } from 'hooks/useSaveToBookmark'

const Wrapper = styled('div', {
  backgroundColor: '$white',
  boxShadow: '$elevationMedium',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '$sansSerif',
  height: 'auto',
  margin: '0 auto',
  maxWidth: '100%',
  minHeight: '100vh',
  overflowX: 'clip', // Hidden overflow breaks sticky positioning
  paddingTop: 'env(safe-area-inset-top, 0)',
  paddingRight: 'env(safe-area-inset-right, 0)',
  paddingBottom: 'env(safe-area-inset-bottom, 0)',
  paddingLeft: 'env(safe-area-inset-left, 0)',
  width: '$applicationWidth',
})

type Props = {
  children?: React.ReactNode
}

const ApplicationWrapper = ({ children }: Props) => {
  useMirrorCartToLocalStorage()

  useMirrorRecoilStateToLocalStorage<boolean>({
    recoilState: rewardIndicatorState,
    localStorageKey: LocalStorageKeys.REWARD_INDICATOR,
    defaultValue: false,
  })

  useRateOrderReminder()

  useCheckIfHaveOfferToday()
  useShowSmartBanner()
  useSaveToBookMark()

  return (
    <Wrapper data-testid="application-wrapper-component">{children}</Wrapper>
  )
}

export default ApplicationWrapper
