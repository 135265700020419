import { styled } from 'styles/stitches.config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

/**
 * Notification component using React Toastify
 * Check the link below to see how to style it
 * @see https://fkhadra.github.io/react-toastify/how-to-style#override-css-variables
 */
const Notification = styled(ToastContainer, {
  '& .Toastify__toast': {
    fontFamily: '$sansSerif',
  },
  '& .Toastify__toast--success': {
    backgroundColor: '$green100',
  },
  '& .Toastify__toast--error': {
    backgroundColor: '$red200',
  },
})

export default Notification
