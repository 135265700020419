import type * as Stitches from '@stitches/react'
import { useMemo, useState } from 'react'
import type { RegisterOptions } from 'react-hook-form'
import { useFormContext, useWatch } from 'react-hook-form'
import { animated, useTransition } from '@react-spring/web'
import { findIndex, uniqueId } from 'lodash'

import { Modal, useModal } from 'components/ModalKit'
import type { ModalProps } from 'components/ModalKit'

import { styled } from 'styles/stitches.config'
import { Button } from 'design-system'

const Options = styled('ul', {
})

const Option = styled('li', {
  borderBottom: '1px solid $grey100',
  cursor: 'pointer',

  '&:last-of-type': {
    borderBottom: 'none',
  },

  variants: {
    selected: {
      true: {
        backgroundColor: '$red200',
      },
    },
  },
})

const OptionLabel = styled('div', {
  lineHeight: '$heading4',
  padding: '$xxs $s',
})

const PrefixSection = styled('div', {
  borderBottom: '1px solid $grey200',
})

const SuffixSection = styled(PrefixSection, {
  borderTop: '1px solid $grey200',
  borderBottom: 'none',
})

type SelectOption<ValueType = string> = {
  name: string | React.ReactNode
  value: ValueType
  disable?: boolean
  key?: string
}

type SelectModalProps<ValueType = string> = {
  label?: string | React.ReactNode
  name: string
  options: SelectOption<ValueType>[]
  prefix?: string | React.ReactNode
  setValue: (name: string, selected: ValueType) => void
  submitLabel?: string
  suffix?: string | React.ReactNode
  value: ValueType
} & Partial<ModalProps>

function SelectModal<OptionValueType = string>({
  label, modalId, name, options, prefix, setValue, submitLabel, suffix, transitionStyles, value,
}: SelectModalProps<OptionValueType>) {
  const { closeModal } = useModal()
  const [open, setOpen] = useState(true)
  const [selected, setSelected] = useState<OptionValueType>(value)

  const prefixCss = typeof prefix === 'string' ? { padding: '$s' } : {}
  const suffixCss = typeof suffix === 'string' ? { padding: '$s' } : {}

  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  })

  const handleClose = () => {
    closeModal(modalId)
    setOpen(false)
  }

  const handleSelect = () => {
    setValue(name, selected)
    handleClose()
  }

  const handleOptionOnClick = (option: SelectOption<OptionValueType>) => () => {
    !option.disable && setSelected(option.value)
  }

  return (
    <Modal.Root
      data-testid="form-select-modal"
      modalId={modalId as string}
      transitionStyles={transitionStyles}
    >
      <Modal.Overlay handleClose={handleClose} />
      <Modal.Content hAlign="center" vAlign="bottom">
        {transitions((styles, item) => item && (
          <animated.div style={styles}>
            <Modal.Card>
              <Modal.Header css={{ fontSize: '$body1', fontWeight: '$bold' }}>
                {label}
              </Modal.Header>
              <Modal.Main css={{
                margin: 0,
              }}>
                {prefix && (
                  <PrefixSection css={prefixCss}>{prefix}</PrefixSection>
                )}
                <Options>
                  {options.map((option) => (
                    <Option
                      data-testid={typeof option.value === 'string' ? option.value : option.key ?? uniqueId()}
                      key={typeof option.value === 'string' ? option.value : option.key ?? uniqueId()}
                      onClick={handleOptionOnClick(option)}
                      selected={option.value === selected}
                    >
                      {typeof option.name === 'string' ? (
                        <OptionLabel>{option.name}</OptionLabel>
                      ) : option.name}
                    </Option>
                  ))}
                </Options>
                {suffix && (
                  <SuffixSection css={suffixCss}>{suffix}</SuffixSection>
                )}
              </Modal.Main>
              <Modal.Footer>
                <Button bold cta size="large" onClick={handleSelect} type="button">{submitLabel || 'Okay'}</Button>
              </Modal.Footer>
              <Modal.CloseButton handleClose={handleClose} />
            </Modal.Card>
          </animated.div>
        ))}
      </Modal.Content>
    </Modal.Root>
  )
}

const CurrentOption = styled('button', {
  alignItems: 'center',
  backgroundColor: '$black',
  color: '$white',
  borderRadius: '$xxv',
  display: 'flex',
  fontSize: '$heading5',
  fontWeight: '$bold',
  justifyContent: 'space-between',
  lineHeight: 1,
  padding: '$xs $m $xs $l',
  width: '100%',
})

const IconPrefix = styled('span', {
  fontSize: 0,
  lineHeight: 0,
  marginRight: '$s',
})

const IconSuffix = styled('span', {
  fontSize: 0,
  lineHeight: 0,
  marginLeft: '$s',
})

type FormSelectModalProps<ValueType = string> = {
  css?: Stitches.CSS
  formOptions?: RegisterOptions
  iconPrefix?: React.ReactNode
  iconSuffix?: React.ReactNode
  label?: string | React.ReactNode
  name: string
  options: SelectOption<ValueType>[]
  prefix?: string | React.ReactNode
  submitLabel?: string
  suffix?: string | React.ReactNode
  placeholder?: string | React.ReactNode
  returnModalId?: (modalId: string) => void
}

function FormSelectModal<OptionValueType = string>({
  css, label, formOptions, iconPrefix, iconSuffix, name, options, prefix, submitLabel, suffix, placeholder = 'Not selected', returnModalId
}: FormSelectModalProps<OptionValueType>) {
  const { register, setValue } = useFormContext()
  const { openModal } = useModal()
  const value = useWatch({ name })

  const nameSelected = useMemo(() => {
    const index = findIndex(options, ['value', value])

    if (index < 0) return placeholder

    return options[index].name
  }, [options, placeholder, value])

  const handleOpenModal = () => {
    const modalId = openModal(
      <SelectModal
        label={label}
        name={name}
        options={options}
        prefix={prefix}
        setValue={setValue}
        submitLabel={submitLabel}
        suffix={suffix}
        value={value}
      />
    )

    returnModalId && returnModalId(modalId)
  }

  return (
    <div>
      <CurrentOption
        css={css}
        data-testid="form-select-modal-current"
        onClick={handleOpenModal}
        type="button"
      >
        {iconPrefix && (
          <IconPrefix>
            {iconPrefix}
          </IconPrefix>
        )}
        <span>{nameSelected}</span>
        {iconSuffix && (
          <IconSuffix>
            {iconSuffix}
          </IconSuffix>
        )}
      </CurrentOption>
      <input
        id={name}
        type="hidden"
        {...register(name, formOptions)}
      />
    </div>
  )
}

export default FormSelectModal
