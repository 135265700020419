import { SVGAttributes } from 'react'

export const Inbox = ({
  size = 25,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0.125488 9.03101C0.125488 8.47872 0.573204 8.03101 1.12549 8.03101H7.12549C7.45984 8.03101 7.77207 8.19811 7.95754 8.47631L9.66067 11.031H12.5903L14.2934 8.47631C14.4789 8.19811 14.7911 8.03101 15.1255 8.03101H21.1255C21.6778 8.03101 22.1255 8.47872 22.1255 9.03101C22.1255 9.58329 21.6778 10.031 21.1255 10.031H15.6607L13.9575 12.5857C13.7721 12.8639 13.4598 13.031 13.1255 13.031H9.12549C8.79114 13.031 8.4789 12.8639 8.29344 12.5857L6.5903 10.031H1.12549C0.573204 10.031 0.125488 9.58329 0.125488 9.03101Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.36549 0.0310059L15.8855 0.031006C16.4436 0.0313023 16.9911 0.187288 17.4655 0.481425C17.9394 0.775304 18.322 1.19545 18.5704 1.69468C18.5706 1.69512 18.5708 1.69557 18.571 1.69601L22.0197 8.58327C22.0893 8.72226 22.1255 8.87556 22.1255 9.03101V15.031C22.1255 15.8267 21.8094 16.5897 21.2468 17.1523C20.6842 17.7149 19.9211 18.031 19.1255 18.031H3.12549C2.32984 18.031 1.56678 17.7149 1.00417 17.1523C0.441559 16.5897 0.125488 15.8267 0.125488 15.031V9.03101C0.125488 8.87556 0.161725 8.72226 0.231321 8.58327L3.67996 1.69601C3.6802 1.69552 3.68044 1.69503 3.68068 1.69454C3.92907 1.19537 4.31166 0.775275 4.78552 0.481425C5.25985 0.187289 5.80736 0.031302 6.36549 0.0310059ZM6.36583 2.03101C6.17985 2.03114 5.9976 2.08313 5.83954 2.18115C5.68143 2.27919 5.55381 2.4194 5.47102 2.58601L5.46966 2.58874L2.12549 9.26738V15.031C2.12549 15.2962 2.23084 15.5506 2.41838 15.7381C2.60592 15.9256 2.86027 16.031 3.12549 16.031H19.1255C19.3907 16.031 19.6451 15.9256 19.8326 15.7381C20.0201 15.5506 20.1255 15.2962 20.1255 15.031V9.26738L16.7813 2.58874L16.78 2.58601C16.6972 2.4194 16.5695 2.27919 16.4114 2.18115C16.2534 2.08313 16.0711 2.03114 15.8851 2.03101H6.36583Z" fill="currentColor"/>
  </svg>
)
