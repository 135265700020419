import type { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import App from 'next/app'

import ApplicationWrapper from 'components/ApplicationWrapper'
import { default as DefaultMetadata } from 'components/Metadata'
import GlobalProviders from 'components/GlobalProviders'
import Notification from 'components/Notification'

import { globalStyles } from 'styles/globalStyles'
import { useReloadOnNewBuild } from 'hooks/useReloadOnNewBuild'
import { getBuildId } from 'utils/build'

function MyApp({ Component, pageProps }: AppProps) {
  globalStyles()
  useReloadOnNewBuild(pageProps.buildId)

  /* istanbul ignore next */
  const appUrl = typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : ''
  /* istanbul ignore next */
  const currentUrl = typeof window !== 'undefined' ? `${window.location}` : ''

  return (
    <GlobalProviders>
      <ApplicationWrapper>
        <Head>
          <link href="/icons/favicon-2020.ico" rel="icon" sizes="any" />
          <link href="/icons/favicon-2020.svg" rel="icon" type="image/svg+xml" />
          <link href="/images/sign-in-4.jpeg" rel="preload" as="image" />
          <link href="/images/sign-in-1.jpeg" rel="preload" as="image" />
          <link href="/images/beans-brewing.gif" rel="preload" as="image" />
          <link href="/images/joe_loading_animation.gif" rel="preload" as="image" />
          <meta charSet="utf-8" />
          <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
          <meta name="apple-itunes-app" content={`app-id=1437558382, app-argument=${currentUrl}`} />
        </Head>
        <DefaultMetadata
          description="Empowering indie coffee to beat national chains at their own game."
          imgHeight={0}
          imgSrc=""
          imgWidth={0}
          pageTitle="Order Ahead"
          siteTitle="Joe Coffee"
          url={appUrl}
        />
        <Component {...pageProps} />
      </ApplicationWrapper>
      <Notification enableMultiContainer containerId="global-toast" closeButton={false} hideProgressBar />
    </GlobalProviders>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {

  const appInitialProps = await App.getInitialProps(appContext)
  const buildId = await getBuildId()

  appInitialProps.pageProps.buildId = buildId

  return appInitialProps
}

export default MyApp

