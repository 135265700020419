import { useState } from 'react'
import { getSessionStorage, setSessionStorage } from 'utils/browserStorage'

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      let item: string | null = null

      /* istanbul ignore next */
      if (typeof window !== 'undefined') {
        item = getSessionStorage(key)
      }

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      /* istanbul ignore next */
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    /* istanbul ignore next */
    const valueToStore = value instanceof Function ? value(storedValue) : value

    setStoredValue(valueToStore)

    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      setSessionStorage(key, JSON.stringify(valueToStore))
    }
  }

  return [storedValue, setValue] as const
}
