import React from 'react'

import dynamic from 'next/dynamic'

const Nav = dynamic(() => import('components/Nav'), {
  ssr: false,
})

import { Header, Main, Section } from './styles'
import type { StandardLayoutProps } from './types'

export const StandardPage = ({
  children,
  disableNav,
  headerComponent,
  headerVariant,
  mainAriaLabel,
  overrideMainCSS,
  noShadow,
  borderHeader,
  ...props
}: StandardLayoutProps) => {
  return (
    <Section
      data-testid="standard-page-component"
      disableNav={disableNav}
      header={headerVariant}
      {...props}
    >
      {headerComponent && (
        <Header noShadow={noShadow} border={borderHeader}>
          {headerComponent()}
        </Header>
      )}

      <Main aria-label={mainAriaLabel} css={overrideMainCSS}>
        {children}
      </Main>

      {!disableNav && <Nav />}
    </Section>
  )
}
