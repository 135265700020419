import { useEffect, useRef } from 'react'
import { cartState } from 'config/states'
import { useRecoilState } from 'recoil'
import { useLocalStorage } from './useLocalStorage'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { CartData } from 'types/api/cart'
import { isEqual } from 'lodash'

export const useMirrorCartToLocalStorage = () => {
  const [cart, setCart] = useRecoilState(cartState)
  const [storedCart, setStoredCart] = useLocalStorage<null | CartData>(LocalStorageKeys.CART_DATA, null)
  const initCartRef = useRef(false)

  useEffect(() => {
    if (!initCartRef.current) {
      initCartRef.current = true

      if (storedCart) {
        setCart(storedCart)
      }
    }
  }, [cart, storedCart, setCart])

  useEffect(() => {
    if (!isEqual(cart, storedCart)) {
      setStoredCart(cart)
    }
  }, [cart, setStoredCart, storedCart])
}
