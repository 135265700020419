import { styled } from 'styles/stitches.config'

export const Card = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$s',
  display: 'inline-block',
  fontSize: '$body1',
  maxWidth: '100%',
  position: 'relative',
  textAlign: 'left',
  width: '$applicationWidth',
  fontFamily: '$sansSerif'
})
