import { animated, useTransition } from '@react-spring/web'
import { styled } from 'styles/stitches.config'
import { Modal } from 'components/ModalKit'
import FailedPaymentUpdateForm from './FailedPaymentUpdateForm'

const Content = styled(Modal.Content, {
  bottom: 0,
  width: '100%',
  margin: 'auto',
  position: 'fixed',
  padding: 0
})

const Card = styled(Modal.Card, {
  height: '100vh',
  borderRadius: 0,
  paddingTop: '$s',
  overflow: 'auto'
})

interface Props {
  open: boolean
}

const ACHFailedBottomSheet = ({ open }: Props) => {
  const transitions = useTransition(open, {
    from: {
      transform: 'translateY(100%)',
      height: '100%'
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
      height: '100%'
    },
  })

  return (
    <Content>
      {transitions((styles, item) => item && (
        <animated.div style={styles} data-testid="ach-failed-modal">
          <Card>
            <FailedPaymentUpdateForm open={open} />
          </Card>
        </animated.div>
      ))
      }
    </Content >
  )
}

export default ACHFailedBottomSheet
