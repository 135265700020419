import { styled } from 'styles/stitches.config'
import * as Icon from 'components/DesignSystemIcons'
import { CSS } from '@stitches/react'

const Button = styled('button', {
  background: '$white',
  borderRadius: '50%',
  color: '$grey1000',
  position: 'absolute',
  right: '$m',
  textAlign: 'center',
  top: '$m',
})

export type Props = {
  handleClose: () => void
  css?: CSS
}

export const CloseButton = ({ handleClose, css }: Props) => (
  <Button
    aria-label="Close Button"
    css={css}
    onClick={handleClose}
    type="button"
  >
    <Icon.Aura css={{ backgroundColor: '$grey200' }}>
      <Icon.Cross size={20} />
    </Icon.Aura>
  </Button>
)
