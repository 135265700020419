import { globalCss, keyframes } from '@stitches/react'

const slideDown = keyframes({
  '0%': {
    transform: 'translateY(-100px)'
  },
  '100%': {
    transform: 'translateY(0)'
  }
})

const scaleOut = keyframes({
  '0%': {
    transform: 'scale(1)',
    opacity: 1,
  },
  '100%': {
    transform: 'scale(0)',
    opacity: 1,
  }
})

export const globalStyles = globalCss({
  '@font-face': [
    {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      src: 'local("Mulish"), url("/webfonts/Mulish-VariableFont_wght.ttf") format("woff2")',
    },
    {
      fontFamily: 'Mulish',
      fontStyle: 'italic',
      src: 'local("Mulish"), url("/webfonts/Mulish-Italic-VariableFont_wght.ttf") format("woff2")',
    },
  ],

  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    '-webkit-tap-highlight-color': 'transparent',
  },

  'html': {
    backgroundColor: '$white',
    fontDisplay: 'optimal',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: 16,
    lineHeight: 1,
    height: '100%',
    textRendering: 'optimizelegibility',
    touchAction: 'manipulation',
  },

  'body': {
    minHeight: '100%',
  },

  'img': {
    display: 'block',
    height: 'auto',
    margin: 0,
    maxWidth: '100%',
  },

  'a': {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  'button': {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-block',
  },

  'h1, h2, h3, h4, h5, h6': {
    lineHeight: 1,
    margin: 0,
  },

  'hr': {
    borderTop: '2px solid $grey600',
    clear: 'both',
    margin: '1em 0',
  },

  'pre': {
    fontFamily: '$monospace',
  },

  'ol, ul': {
    listStyle: 'none',
    padding: 0,
  },

  '.slide-down': {
    animation: `${slideDown} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
  },

  '.scale-out': {
    animation: `${scaleOut} 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`
  }
})
