import { SVGAttributes } from 'react'

export const Rewards = ({ size = 24 }: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3506_13958)">
      <path d="M5.74498 10.7618C5.69859 10.7618 5.65152 10.7537 5.60592 10.7365C5.54252 10.7127 4.05281 10.1402 3.57183 8.86519C3.09046 7.59001 3.83106 6.17615 3.86271 6.11651C3.95618 5.94135 4.16526 5.86173 4.3511 5.93245C4.4145 5.95619 5.90422 6.52855 6.3852 7.80374C6.86657 9.07873 6.12596 10.4928 6.09431 10.5522C6.0235 10.6845 5.8875 10.7618 5.74498 10.7618ZM4.31204 8.58589C4.54466 9.20226 5.1562 9.61974 5.5445 9.83209C5.69632 9.41758 5.8791 8.70358 5.64499 8.08305C5.41048 7.46202 4.80083 7.04662 4.41292 6.83575C4.261 7.25007 4.07753 7.96476 4.31204 8.58589V8.58589Z" fill="currentColor" />
      <path d="M6.26987 8.12909C6.18293 8.12909 6.09599 8.1007 6.02339 8.04294C5.97088 8.001 4.72951 6.9981 4.66384 5.63677C4.59846 4.27543 5.73785 3.158 5.78612 3.11102C5.92943 2.97304 6.15197 2.96246 6.30765 3.08609C6.36017 3.12803 7.60192 4.13113 7.6672 5.49246C7.73248 6.8536 6.59319 7.97113 6.54482 8.01801C6.46837 8.0916 6.36917 8.12909 6.26987 8.12909V8.12909ZM5.45429 5.59888C5.48594 6.2568 5.93913 6.84213 6.24326 7.16337C6.51525 6.81582 6.90889 6.19271 6.87685 5.53024C6.8452 4.86748 6.39271 4.28443 6.08867 3.96457C5.81668 4.31212 5.42215 4.93572 5.45429 5.59888V5.59888Z" fill="currentColor" />
      <path d="M4.63832 13.1133C4.08 13.1133 3.46382 13.0162 2.90056 12.7133C2.90056 12.7133 2.90056 12.7133 2.90056 12.7131C1.36634 11.8876 0.891194 9.89247 0.871412 9.80791C0.827004 9.61435 0.932833 9.41773 1.11868 9.3484C1.19978 9.31823 3.12695 8.6159 4.66107 9.44097C6.19529 10.2666 6.67044 12.2618 6.69022 12.3463C6.73462 12.5399 6.6288 12.7365 6.44295 12.8058C6.39152 12.8249 5.60067 13.1133 4.63832 13.1133V13.1133ZM3.27521 12.0163C4.14953 12.4867 5.25292 12.3083 5.81006 12.1691C5.61956 11.6271 5.15985 10.6081 4.28632 10.1377C3.412 9.66766 2.309 9.84579 1.75147 9.98505C1.94196 10.5271 2.40168 11.5461 3.27521 12.0165V12.0163Z" fill="currentColor" />
      <path d="M3.7887 16.0861C2.32134 16.0861 1.04912 15.1266 0.988494 15.0802C0.830838 14.9594 0.787221 14.7406 0.886522 14.5685C0.929843 14.4933 1.96716 12.7241 3.67555 12.3829C5.38711 12.0413 7.02172 13.2765 7.09085 13.3292C7.24851 13.4499 7.29213 13.669 7.19283 13.8409C7.14951 13.9161 6.1114 15.6853 4.40379 16.0265C4.19669 16.068 3.99077 16.0861 3.7887 16.0861V16.0861ZM4.32605 15.6387H4.32991H4.32605ZM1.77885 14.6557C2.2707 14.9516 3.27558 15.4445 4.24841 15.2509C5.22125 15.0566 5.95878 14.2161 6.29951 13.7534C5.80726 13.4573 4.80278 12.9656 3.83074 13.1588C2.85217 13.3542 2.1177 14.1936 1.77885 14.6557Z" fill="currentColor" />
      <path d="M5.09903 19.9013C4.95226 19.9013 4.8127 19.8192 4.74436 19.6811C4.70608 19.6034 3.8098 17.7584 4.47355 16.1481C5.13731 14.5374 7.07328 13.8599 7.15557 13.8319C7.34329 13.7676 7.55001 13.8528 7.63813 14.0307C7.67641 14.1085 8.57308 15.9535 7.90893 17.5641C7.24518 19.1746 5.3092 19.8521 5.22692 19.8801C5.18478 19.8945 5.14146 19.9013 5.09903 19.9013V19.9013ZM7.06942 14.7254C6.54978 14.9707 5.58317 15.5321 5.20525 16.4495C4.82704 17.3669 5.11713 18.4463 5.31306 18.9864C5.83271 18.7413 6.79931 18.18 7.17723 17.2625V17.2623C7.55584 16.3452 7.26526 15.2655 7.06942 14.7254V14.7254Z" fill="currentColor" />
      <path d="M8.72906 20.3046C8.64094 20.3046 8.55251 20.2753 8.47982 20.2161C8.42997 20.1758 7.26012 19.2122 7.21067 17.9174C7.16042 16.6229 8.25224 15.572 8.29862 15.528C8.44313 15.3912 8.66645 15.3827 8.82015 15.5079C8.87 15.5483 10.0399 16.5118 10.0897 17.8065V17.8067C10.1392 19.1012 9.04773 20.152 9.00135 20.1961C8.92529 20.2682 8.82717 20.3046 8.72906 20.3046V20.3046ZM8.59227 16.3872C8.33571 16.7137 7.97797 17.2816 8.00112 17.887C8.02426 18.4889 8.42651 19.0299 8.7078 19.3368C8.96436 19.0103 9.32249 18.4424 9.29925 17.837C9.2761 17.2351 8.87356 16.6942 8.59227 16.3872Z" fill="currentColor" />
      <path d="M19.9221 10.7618C19.7796 10.7618 19.6436 10.6846 19.5729 10.5522C19.5412 10.4927 18.8013 9.07873 19.282 7.80374C19.763 6.52855 21.2528 5.95619 21.3161 5.93245C21.5015 5.86173 21.7105 5.94135 21.8045 6.11651C21.8361 6.17625 22.5768 7.59001 22.0954 8.86519C21.6148 10.1402 20.1246 10.7127 20.0613 10.7365C20.0156 10.7537 19.9685 10.7618 19.9221 10.7618V10.7618ZM21.2539 6.83565C20.8664 7.04642 20.2567 7.46192 20.0222 8.08295C19.79 8.69933 19.9731 9.41659 20.1242 9.83249C20.5121 9.62152 21.1209 9.20622 21.355 8.58579C21.5892 7.96476 21.4061 7.25027 21.2539 6.83565Z" fill="currentColor" />
      <path d="M19.3975 8.12909C19.2982 8.12909 19.1989 8.0916 19.1228 8.01801C19.0742 7.97103 17.9344 6.8536 17.9998 5.49246C18.0651 4.13113 19.3068 3.12803 19.3593 3.08609C19.515 2.96246 19.7379 2.97304 19.8806 3.11102C19.9292 3.158 21.069 4.27543 21.0036 5.63677C20.9383 6.9981 19.6966 8.001 19.6441 8.04294C19.5714 8.1007 19.4844 8.12909 19.3975 8.12909V8.12909ZM19.5783 3.96467C19.2743 4.28433 18.8219 4.86738 18.7901 5.53034C18.7585 6.18846 19.1537 6.81453 19.4257 7.16367C19.7297 6.84381 20.1814 6.26135 20.2131 5.59898C20.2452 4.93592 19.8507 4.31212 19.5783 3.96467ZM20.6083 5.61787H20.6122H20.6083Z" fill="currentColor" />
      <path d="M21.0288 13.1132C20.0661 13.1132 19.2755 12.8248 19.2242 12.8057C19.0379 12.7364 18.9325 12.5398 18.9769 12.3462C18.9966 12.2616 19.4722 10.2665 21.006 9.44106C22.5403 8.615 24.4669 9.31782 24.5484 9.34828C24.7343 9.41762 24.8401 9.61424 24.7957 9.8078C24.776 9.89236 24.3008 11.8875 22.7666 12.7129V12.7131C22.2033 13.016 21.5871 13.1132 21.0288 13.1132ZM22.5792 12.3646H22.5831H22.5792ZM19.8563 12.1686C20.4134 12.3082 21.5172 12.4867 22.3919 12.0162V12.0164C23.2654 11.546 23.7252 10.5269 23.9156 9.98513C23.3585 9.84568 22.2547 9.66794 21.3808 10.1377C20.5026 10.6102 20.0452 11.6275 19.8563 12.1686V12.1686Z" fill="currentColor" />
      <path d="M21.8781 16.0858C21.6761 16.0858 21.4705 16.0676 21.2634 16.0262C19.555 15.6851 18.5176 13.9158 18.4744 13.8406C18.3751 13.6685 18.4188 13.4496 18.5764 13.3289C18.6455 13.2763 20.2805 12.0421 21.9913 12.3825C23.6997 12.7237 24.7371 14.493 24.7803 14.5682C24.8796 14.7402 24.8359 14.9591 24.6783 15.0799C24.6177 15.1263 23.3451 16.0858 21.8781 16.0858V16.0858ZM19.3673 13.7523C19.7076 14.2148 20.4449 15.0561 21.4188 15.2505C22.3963 15.4465 23.3973 14.9532 23.8876 14.6567C23.5472 14.1942 22.81 13.3529 21.8361 13.1585C20.8601 12.9653 19.8583 13.4558 19.3673 13.7523V13.7523Z" fill="currentColor" />
      <path d="M20.5676 19.9009C20.5251 19.9009 20.4819 19.8942 20.4397 19.8796C20.3574 19.8516 18.4215 19.1741 17.7577 17.5635C17.094 15.9532 17.9903 14.1081 18.0285 14.0305C18.1166 13.8523 18.3233 13.767 18.5111 13.8315C18.5934 13.8595 20.5293 14.537 21.1931 16.1476C21.8572 17.7579 20.9605 19.603 20.9223 19.6808C20.8539 19.8188 20.7145 19.9009 20.5676 19.9009V19.9009ZM18.5972 14.7252C18.4014 15.2653 18.1112 16.3448 18.4894 17.2621C18.8672 18.1795 19.8339 18.7408 20.3536 18.9862C20.5494 18.4461 20.84 17.3665 20.4614 16.4493C20.0836 15.5319 19.1169 14.9703 18.5972 14.7252V14.7252Z" fill="currentColor" />
      <path d="M16.9386 20.3046C16.8405 20.3046 16.7423 20.2682 16.6662 20.1961C16.6198 20.152 15.528 19.1012 15.5778 17.8067C15.6273 16.5118 16.7971 15.5483 16.847 15.5079C17.0004 15.3827 17.224 15.3912 17.3685 15.528C17.4149 15.572 18.5071 16.6229 18.4569 17.9176C18.407 19.2122 17.2376 20.1758 17.1877 20.2161C17.1151 20.2753 17.0267 20.3046 16.9386 20.3046V20.3046ZM17.0734 16.3872C16.7925 16.6928 16.3914 17.2314 16.3683 17.8368V17.837C16.3451 18.4389 16.7044 19.0092 16.9613 19.3368C17.2422 19.0311 17.6429 18.4924 17.6664 17.887C17.6897 17.2852 17.3299 16.7149 17.0734 16.3872V16.3872ZM15.9731 17.8217H15.9769H15.9731Z" fill="currentColor" />
      <path d="M12.8175 17.4997C8.89103 17.4997 5.69629 14.3051 5.69629 10.3785C5.69629 7.9115 6.94883 5.65537 9.04671 4.34329C9.23216 4.22678 9.47636 4.28355 9.59188 4.4689C9.70779 4.65415 9.65142 4.89835 9.46627 5.01407C7.60101 6.18065 6.48753 8.18596 6.48753 10.3785C6.48753 13.8688 9.32721 16.7084 12.8175 16.7084C16.3078 16.7084 19.1474 13.8688 19.1474 10.3785C19.1474 8.18596 18.034 6.18065 16.1687 5.01407C15.9836 4.89835 15.9273 4.65415 16.0431 4.4689C16.1589 4.28365 16.4024 4.22688 16.5883 4.34329C18.6861 5.65537 19.9387 7.9114 19.9387 10.3785C19.9387 14.3052 16.7439 17.4997 12.8175 17.4997Z" fill="currentColor" />
      <path d="M12.836 11.901L14.8966 13.6012L14.1607 10.8862L16.4146 9.07104L13.6547 9.24432L12.836 6.81787L12.0172 9.24432L9.25732 9.07104L11.5112 10.8862L10.7753 13.6012L12.836 11.901Z" fill="currentColor" />
      <path d="M10.775 13.997C10.7008 13.997 10.6262 13.9762 10.5606 13.9338C10.4157 13.8406 10.3481 13.6641 10.3933 13.4979L11.0617 11.0325L9.00903 9.37914C8.87462 9.27094 8.82586 9.08845 8.88807 8.92793C8.95068 8.76721 9.11328 8.66366 9.28171 8.67622L11.7393 8.83071L12.461 6.69148C12.5151 6.53076 12.6662 6.42236 12.8358 6.42236C13.0054 6.42236 13.1564 6.53076 13.2105 6.69148L13.9322 8.83071L16.3898 8.67622C16.5625 8.66465 16.7214 8.76721 16.7835 8.92793C16.8457 9.08845 16.797 9.27103 16.6625 9.37914L14.6098 11.0325L15.2779 13.4979C15.3231 13.6642 15.2554 13.8406 15.1106 13.9338C14.9657 14.0273 14.7768 14.0159 14.6443 13.9066L12.8358 12.4141L11.0269 13.9066C10.9538 13.9666 10.8646 13.997 10.775 13.997V13.997ZM10.4747 9.54401L11.7589 10.5785C11.8813 10.677 11.934 10.8385 11.8926 10.9901L11.482 12.5051L12.5839 11.5961C12.7306 11.4755 12.9416 11.4755 13.0877 11.5961L14.1892 12.5051L13.7789 10.9901C13.7376 10.8385 13.7901 10.677 13.9127 10.5785L15.1969 9.54401L13.6792 9.63946C13.5035 9.65103 13.3369 9.54035 13.2798 9.37113L12.8359 8.05559L12.392 9.37113C12.3348 9.54055 12.1729 9.65162 11.9925 9.63946L10.4747 9.54401Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_3506_13958">
        <rect width="24" height="24" fill="white" transform="translate(0.833496)" />
      </clipPath>
    </defs>
  </svg>
)
