import Big from 'big.js'

/** Result of a multiplied by b */
export const productOf = (a: number, b: number) => {
  return Big(a).times(b).toNumber()
}

/** Result of a divided by b */
export const quotientOf = (a: number, b: number) => {
  return Big(a).div(b).toNumber()
}

/** Result of sum between a and b */
export const sumOf = (a: number, b: number) => {
  return Big(a).plus(b).toNumber()
}

export const formatMoney = (value: number, fraction = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fraction
  }).format(value)
}
