import { styled } from 'styles/stitches.config'

export const H1 = styled('h1', {
  fontSize: '$heading1',
  fontWeight: '$black',
  lineHeight: '$heading1',
})

export const H2 = styled('h2', {
  fontSize: '$heading2',
  fontWeight: '$extraBold',
  lineHeight: '$heading2',
})

export const H3 = styled('h3', {
  fontSize: '$heading3',
  fontWeight: '$bold',
  lineHeight: '$heading3',
})

export const H4 = styled('h4', {
  fontSize: '$heading4',
  fontWeight: '$extraBold',
  lineHeight: '$heading4',
})

export const H5 = styled('h5', {
  fontSize: '$heading5',
  fontWeight: '$bold',
  lineHeight: '$heading5',
})
