import { SVGAttributes } from 'react'

export const Camera = ({
  size = 24, strokeLinecap = 'round', strokeLinejoin = 'round', strokeWidth = 0,
}: SVGAttributes<SVGSVGElement> & { size?: number }) => (
  <svg viewBox="0 0 20 18" width={size} height={size} fill="none" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
    <path d="M17.3711 3.93701C16.832 3.93701 16.3945 4.37451 16.3945 4.91357C16.3945 5.45264 16.832 5.89014 17.3711 5.89014C17.7422 5.89014 18.0469 6.19482 18.0469 6.56592V15.0737C18.0469 15.4448 17.7422 15.7495 17.3711 15.7495H2.62891C2.25781 15.7495 1.95312 15.4448 1.95312 15.0737V6.56592C1.95312 6.19482 2.25781 5.89014 2.62891 5.89014C3.16797 5.89014 3.60547 5.45264 3.60547 4.91357C3.60547 4.37451 3.16797 3.93701 2.62891 3.93701C1.17969 3.93701 0 5.1167 0 6.56592V15.0737C0 16.5229 1.17969 17.7026 2.62891 17.7026H17.3711C18.8203 17.7026 20 16.5229 20 15.0737V6.56592C20 5.1167 18.8203 3.93701 17.3711 3.93701V3.93701Z" fill="currentColor" />
    <path d="M5.92184 5.8628C6.44528 5.98389 6.97262 5.65967 7.09372 5.13233L7.60934 2.93701C7.66403 2.70655 7.86715 2.54248 8.10544 2.54248H11.8984C12.1367 2.54248 12.3398 2.70264 12.3945 2.93701L12.9062 5.13624C13.0117 5.58545 13.414 5.89014 13.8554 5.89014C13.9296 5.89014 14.0039 5.88233 14.0781 5.8628C14.6015 5.74171 14.9296 5.21436 14.8086 4.69092L14.2968 2.4917C14.0312 1.37451 13.0468 0.589355 11.8945 0.589355H8.10153C6.95309 0.589355 5.96481 1.37061 5.70309 2.4917L5.19528 4.69092C5.07028 5.21827 5.39841 5.74171 5.92184 5.8628V5.8628Z" fill="currentColor" />
    <path d="M10 6.50732C7.90234 6.50732 6.19141 8.21436 6.19141 10.3159C6.19141 12.4175 7.89844 14.1245 10 14.1245C12.1016 14.1245 13.8086 12.4175 13.8086 10.3159C13.8086 8.21436 12.0977 6.50732 10 6.50732V6.50732ZM10 12.1675C8.97656 12.1675 8.14453 11.3354 8.14453 10.312C8.14453 9.28857 8.97656 8.45654 10 8.45654C11.0234 8.45654 11.8555 9.28857 11.8555 10.312C11.8555 11.3354 11.0234 12.1675 10 12.1675Z" fill="currentColor" />
  </svg>
)
